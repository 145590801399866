import React from 'react';
import LoggedInHeader from "./LoggedInHeader";
import {AppBar, styled, Toolbar} from "@mui/material";

const HeaderWrapper = styled(AppBar)`
  background-color: ${({theme}) => theme.palette.background.paper};

  ._logo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      max-height: 58px;
    }
  }

`;

export default function Header() {

    return (
        <>
            <HeaderWrapper color={"default"} elevation={0}>
                <Toolbar>
                    <LoggedInHeader/>
                </Toolbar>
            </HeaderWrapper>
            <Toolbar/>
        </>
    );
}