import {format, utcToZonedTime} from "date-fns-tz";
import {differenceInDays, differenceInHours, differenceInMinutes} from "date-fns";

/**
 * Format date to system's timezone
 * @param {string} date
 * @param {boolean} withTimeZone want to add timezone at end? Default is false
 * @returns {string} the formatted date
 */
export function formatDateTime(date, withTimeZone = false) {
    if (date) {
        //add 'Z' at the end
        if (!date.endsWith("Z") || !date.endsWith("z"))
            date = date.concat("Z");
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedDate = utcToZonedTime(new Date(date.toString()).toISOString(), timeZone);
        const formatted = format(zonedDate, "MM/dd/yyyy hh:mm aaa");
        return withTimeZone ? formatted + ` (${timeZone})` : formatted;
    } else return ""
}

/**
 * Format time to system's timezone
 * @param {string} date
 * @param {boolean} withTimeZone want to add timezone at end? Default is false
 * @returns {string|string} the formatted time
 */
export function formatTime(date, withTimeZone = false) {
    if (date) {
        //add 'Z' at the end
        if (!date.endsWith("Z") || !date.endsWith("z"))
            date = date.concat("Z");
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedDate = utcToZonedTime(new Date(date.toString()).toISOString(), timeZone);
        const formatted = format(zonedDate, "hh:mm aaa");
        return withTimeZone ? formatted + ` (${timeZone})` : formatted;
    } else return ""
}

export function formatDate(date) {
    if (date) {
        const incomingDate = new Date(date);
        /* format from date-fns reads date and time parts from the date instance in the local time zone,
         * need to make  date "looking like" the midnight
         * in  local time zone and not in UTC, which will passed to the Date constructor.*/
        const localDateTime = new Date(incomingDate.valueOf() + incomingDate.getTimezoneOffset()  * 60 * 1000);
        return format(localDateTime, "dd MMM, yyyy");
    } else return ""
}

export function daysDifferenceFromToday(date) {
    if (date) {
        try {
            return differenceInDays(new Date(date), new Date())
        } catch (e) {
            return "";
        }
    } else return ""
}

export function hoursDifferenceFromNow(date) {
    if (date) {
        try {
            return differenceInHours(new Date(date), new Date())
        } catch (e) {
            return "";
        }
    } else return ""
}

/**
 * Calculates the time difference b/w two dates and returns in hours/minutes
 * @param first the first date
 * @param second the second date. Second is later to first.
 * @returns {string}
 */
export function timeDifference(first, second) {
    if (first && second) {
        try {
            const dateFirst = new Date(first);
            const dateSecond = new Date(second);
            //17:20:10 17:21:00 results into 0 minutes, but since we want only minutes or hours difference, don't need to compare seconds.
            dateFirst.setSeconds(0, 0);
            dateSecond.setSeconds(0, 0);
            const inHours = differenceInHours(dateSecond, dateFirst);
            const inMinutes = differenceInMinutes(dateSecond, dateFirst);
            const hourSuffix = inHours > 1 ? " hrs" : " hr";
            if (inHours < 0 || inMinutes < 0)
                return "0 min";
            if (inHours > 0)
                return inHours + hourSuffix + " " + (inMinutes - (inHours * 60)) + " mins"
            return inMinutes + " mins";
        } catch (e) {
            return "";
        }
    }
    return "";
}