import React from 'react';
import {func, string} from 'prop-types';
import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import RetryIcon from '@mui/icons-material/Replay';

function ErrorCard({description, onReloadClick, status, title}) {

    return (
        <Card sx={{maxWidth: 400}}>
            <CardContent>
                <Box textAlign={"center"}>
                    <Typography component={"h2"} variant={"h6"}>{title}</Typography>
                </Box>
                <Box marginY={4}>
                    <Typography variant={"body1"}>
                        {description}
                    </Typography>
                </Box>
                <Box textAlign={"center"}>
                    {status !== 7004 &&
                    <Button onClick={onReloadClick} startIcon={<RetryIcon/>} color={"primary"}
                            variant={"outlined"}>
                        Retry
                    </Button>
                    }
                </Box>
            </CardContent>
        </Card>
    );
}

ErrorCard.propTypes = {
    title: string.isRequired,
    description: string.isRequired,
    onReloadClick: func.isRequired,
};

export default ErrorCard;