import { PERSONAL_CONTACTS } from "./ActionTypes";

export const setLoading = (isLoading) => ({
    type: PERSONAL_CONTACTS.SET_LOADING,
    payload: {
        isLoading
    }
})

/**
 * @param {object} error
 */
export const setError = (error) => ({
    type: PERSONAL_CONTACTS.SET_PAGE_ERROR,
    payload: {
        error
    }
})


export const setCacheExpiryTime = (cacheExpiryTime) => ({
    type: PERSONAL_CONTACTS.SET_CACHE_EXPIRY_TIME,
    payload: {
        cacheExpiryTime
    }
})
export const setData = (pcdata) => ({
    type: PERSONAL_CONTACTS.SET_DATA,
    payload: {
        pcdata
    }
})