import React, {useState} from 'react';
import {Alert, Box, Button, CircularProgress, Grid, Stack} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from "../../../components/Input";
import {Formik} from "formik";
import axios from "axios";
import {API_URN_FORGOT_USERNAME, API_URN_PATIENT} from "../../../../constants/apiURNs";
import {postOrPutErrorExtractor} from "../../../../utils/httpErrorHelpers";
import {format} from "date-fns";
import DatePickerCustom from "../../../components/DatePickerCustom";
import * as yup from "yup";

export default function ForgotUsernameDialog({isOpen, onClose}) {
    const [isRequestSent, setRequestSent] = useState(false);

    return (
        <Dialog open={isOpen} scroll={"paper"} fullWidth maxWidth={"xs"}>
            <Formik
                initialValues={{
                    email: "",
                    dob: null,
                }}
                validationSchema={yup.object().shape({
                    email: yup.string().required("Required").typeError("Required"),
                    dob: yup.date().required("Required").default(null).max(new Date(), "Must be earlier than today").min(new Date(1900, 1, 1), "Must be later than year 1900").typeError("Invalid date. Must be in mm/dd/yyyy format, earlier than today and year must be later than year 1900"),
                })}
                onSubmit={(values, formikHelpers) => {
                    const baseUrl = process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_FORGOT_USERNAME;
                    formikHelpers.setStatus({errorMessage: null});
                    axios.post(baseUrl, values)
                        .then(() => {
                            setRequestSent(true)
                            formikHelpers.setSubmitting(false);
                        })
                        .catch((error) => {
                            formikHelpers.setSubmitting(false);
                            formikHelpers.setStatus({errorMessage: postOrPutErrorExtractor(error, formikHelpers)});
                        })
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      dirty, setFieldTouched,
                      errors,
                      isSubmitting,
                      status, setFieldValue,
                      setStatus, touched,
                  }) => (
                    <>
                        <DialogTitle>
                            {isRequestSent ? "Username sent" : "Please enter the details below"}
                        </DialogTitle>
                        <DialogContent>
                            <Box>
                                {(!isRequestSent && status?.errorMessage) &&
                                <Alert severity={"info"} sx={{marginBottom: 2}}>
                                    If the below details are not known, call Nufactor 1-800-323-6832.
                                </Alert>
                                }
                                {isRequestSent
                                    ? <Alert severity={"success"} sx={{marginY: 1}}>
                                        You will shortly receive the Username on your registered email id.
                                    </Alert>
                                    : <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Input
                                                name={"email"} required
                                                label={"Email"}
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched?.email && errors?.email)}
                                                helperText={touched.email && errors.email}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePickerCustom
                                                name={"dob"}
                                                label={"Date of Birth"} required
                                                value={values.dob}
                                                onChange={(value) => {
                                                    setFieldTouched("dob", true);
                                                    if (value) {
                                                        if (value.toString().toLowerCase() !== "invalid date") {
                                                            const selectedDob = new Date(value);
                                                            setFieldValue("dob", format(selectedDob, "MM/dd/yyyy"));
                                                        } else {
                                                            setFieldValue("dob", "Invalid Date");
                                                        }
                                                    } else {
                                                        setFieldValue("dob", null);
                                                    }
                                                }}
                                                onError={() => setFieldValue("dob", null)}
                                                onBlur={handleBlur}
                                                helperText={touched?.dob && errors?.dob}
                                                error={touched?.dob && errors?.dob !== undefined}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Box>
                            {status && status.errorMessage &&
                            <Alert severity={"error"} onClose={() => setStatus({...status, errorMessage: null})} sx={{marginY: 2}}>
                                {status.errorMessage}
                            </Alert>
                            }
                        </DialogContent>

                        <DialogActions>
                            <Stack direction={"row"} spacing={2} width={"100%"} justifyContent={"flex-end"}>
                                {isRequestSent
                                    ? <Button onClick={onClose} fullWidth color={"primary"} variant={"contained"}>
                                        Close
                                    </Button>
                                    : <>
                                        <Button onClick={onClose} disabled={isSubmitting} variant={"outlined"}>
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSubmit} variant="contained" color="primary"
                                                disabled={!dirty}
                                                endIcon={isSubmitting ?
                                                    <CircularProgress color={"inherit"} size={20}/> : undefined
                                                }
                                        >
                                            Submit
                                        </Button>
                                    </>
                                }
                            </Stack>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );
}

