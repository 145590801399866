import axios from "axios";
import {API_URN_MEDIA, API_URN_PROFILE_IMAGE} from "../constants/apiURNs";

/**
 * Give a GCS URL and in return the API would give bytes of that file
 * @param {string} fileLocation the GCS location
 */
export function fetchFileBytes(fileLocation) {
    return axios.get(API_URN_MEDIA + `/value?fileLocation=${fileLocation}`);
}

export function uploadDisplayPicture(data, currentPatientUsername) {
    return axios.post(API_URN_MEDIA + "/" + currentPatientUsername + API_URN_PROFILE_IMAGE, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
}

export function uploadFile(data) {
    return axios.post(API_URN_MEDIA + "/file", data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
}