import React, { useEffect, useState } from 'react';
import { PageContentWrapper } from '../../styles/page';
import { useDispatch, useSelector } from 'react-redux';
import withLoaderAndErrorCard from '../../../hoc/withLoaderAndErrorCard';
import { MESSAGE_CENTER } from '../../../redux/actions/ActionTypes';
import { Box, Button, Divider, LinearProgress, Slide, Stack, Typography, useTheme } from '@mui/material';
import MessageCard from './MessageCard';
import useScrollToTop from '../../../hooks/useScrollToTop';
import NewMessageIcon from '@mui/icons-material/MarkEmailUnread';

const SECTION_MAX_WIDTH = 800;

function MessageCenterPage() {
    useScrollToTop();
    const theme = useTheme();
    const { isLoading, error, data, isLoadingMore, cacheExpiryTime } = useSelector(state => state?.messageCenter);
    const [showNewMessagesNotifier, setShowNewMessagesNotifier] = useState(false);

    const { currentPatientUsername } = useSelector(state => state?.auth);
    const { unreadMessageCount: unreadMessageCountFromHeader } = useSelector(state => state?.header);
    const dispatchAction = useDispatch();

    useEffect(() => {
        currentPatientUsername && fetchMessages();
    }, [currentPatientUsername]);

    useEffect(() => {
        const unreadMess = data?.filter(message => !message?.isRead);
        // On the mounting of this component, the isLoading is false but unreadMess?.length is 0 because backend is still getting the data.
        // So the setShowNewMessagesNotifier(true) gets called which should not happen. cacheExpiryTime helps in preventing that.
        if (!isLoading && cacheExpiryTime && unreadMess?.length < unreadMessageCountFromHeader) {
            setShowNewMessagesNotifier(true);
        }
    }, [unreadMessageCountFromHeader, isLoading]);

    const fetchMessages = () => {
        dispatchAction({ type: MESSAGE_CENTER.FETCH_ALL });
    };

    const contentJsx = () => (
        data?.length > 0
            ? <Stack spacing={2} width={'100%'} bgcolor={'background.paper'} boxShadow={theme.shadows[1]}
                     border={`1px solid ${theme.palette.divider}`} paddingY={2}>
                {data?.sort((a, b) => new Date(a?.createdAt) > new Date(b?.createdAt) ? -1 : 1)
                    .map((message, i) => {
                        const { id, subject, body, createdAt, direction, isRead, careTeamMemberName, role } = message;
                        return (
                            <React.Fragment key={`message-center-cards-${id}`}>
                                <MessageCard
                                    messageId={id}
                                    content={body}
                                    direction={direction}
                                    createdAt={createdAt}
                                    messageSubject={subject}
                                    team={role} isRead={isRead}
                                    careTeamMemberName={careTeamMemberName}
                                />
                                {i + 1 < data?.length &&
                                <Divider />
                                }
                            </React.Fragment>
                        );
                    })}
            </Stack>
            : <Box
                sx={{
                    background: theme.palette.background.paper,
                    boxShadow: theme.shadows[1],
                    width: '100%',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Box marginY={2}>
                    <Typography color={'textSecondary'} fontSize={'1.2rem'}>
                        You don't have any messages to date
                    </Typography>
                </Box>
                <Box marginY={2}>
                    <Typography color={'textSecondary'}>
                        Messages are started on the My Care Team page within the patient portal and are distributed
                        to the appropriate party.
                    </Typography>
                </Box>
            </Box>
    );

    return (
        <PageContentWrapper>
            <Slide direction={'down'} in={showNewMessagesNotifier} unmountOnExit mountOnEnter>
                <Box className={'newMessagesNotifier'} maxWidth={SECTION_MAX_WIDTH} display={'flex'}
                     justifyContent={'center'} position={'fixed'} top={theme.mixins.toolbar.height}
                     left={{ xs: 0, sm: 0, md: '80px' }} right={0}>
                    <Box bgcolor={'background.paper'} paddingX={2}
                         paddingY={1} borderRadius={2} display={'flex'} flexDirection={'column'}
                         boxShadow={'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;'}
                    >
                        <Box display={'inline-flex'} alignItems={'center'} marginBottom={1}>
                            <NewMessageIcon color={'secondary'} sx={{ mr: 1 }} />
                            <Typography color={'textSecondary'}>New Unread Messages</Typography>
                        </Box>
                        <Button onClick={() => {
                            dispatchAction({ type: MESSAGE_CENTER.FETCH_MORE });
                            setShowNewMessagesNotifier(false);
                        }}>
                            Load All
                        </Button>
                    </Box>
                </Box>
            </Slide>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}
                 maxWidth={SECTION_MAX_WIDTH}>
                <Box textAlign={'left'} width={'100%'}>
                    <Typography variant={'h6'} component={'h2'} sx={{ marginBottom: 2 }}>Message Center</Typography>
                </Box>
                <Box width={'100%'} height={'5px'}>
                    {isLoadingMore ? <LinearProgress /> : null}
                </Box>
                {currentPatientUsername
                    ? withLoaderAndErrorCard(contentJsx)({
                        isLoading,
                        error,
                        onReloadClick: fetchMessages,
                    })
                    : null
                }
            </Box>
        </PageContentWrapper>
    );
}

export default MessageCenterPage;