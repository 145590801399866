import React, {useEffect, useRef, useState} from 'react';
import {Alert, Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {useDispatch} from "react-redux";
import {idpRequestSuccess_saga} from "../../../../redux/actions/authActions";
import * as yup from "yup";
import {Formik} from "formik";
import Input from "../../../components/Input";
import {API_URN_CHECK_OTP, API_URN_PATIENT} from "../../../../constants/apiURNs";
import {errorExtractor} from "../../../../utils/httpErrorHelpers";
import {FLOW_ID_QP} from "../../../../constants/queryParams";
import PhoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/MailOutline";
import {URN_AUTH, URN_LOGIN} from "../../../../constants/clientURNs";
import PropTypes from "prop-types";
import {toast} from "react-toastify";

function OtpVerification({handleResendOtp, isResendingOtp}) {
    const history = useHistory();
    const dispatchAction = useDispatch();
    const {search, state} = useLocation();
    const params = new URLSearchParams(search);
    const flowId = params.get(FLOW_ID_QP);
    const {mfaDevices} = state ?? {};

    let resendCounterRef = useRef(null);
    const [resendInCounter, setResendInCounter] = useState(45);

    useEffect(() => {
        resendCounterRef.current = setInterval(() => {
            setResendInCounter(prevState => prevState === 0 ? prevState : prevState - 1);
        }, 1000)

        return () => {
            clearInterval(resendCounterRef.current)
        }
    }, [])

    const handleOtpResend = () => {
        handleResendOtp(() => {
            toast.success("Security code resent successfully.", {position: "top-center", autoClose: 8000})
            setResendInCounter(60);
        });
    }

    const getMfaDeviceDetails = () => {
        if (mfaDevices) {
            const device = mfaDevices[0];
            const {type, email, phone} = device;
            return (
                <Box display={"inline-flex"} marginBottom={3}>
                    <Box marginRight={2}>
                        {type?.toUpperCase() === "SMS" && <PhoneIcon/>}
                        {type?.toUpperCase() === "EMAIL" && <EmailIcon/>}
                    </Box>
                    <Box>
                        <Typography variant={"subtitle1"}>
                            Security code sent as <u>{type}</u> at <strong>{email || phone}</strong>
                        </Typography>
                    </Box>
                </Box>
            )
        }
    }

    return (
        <div>
            {getMfaDeviceDetails()}

            <Formik
                initialValues={{
                    otp: "",
                    flowId: flowId
                }}
                validationSchema={yup.object().shape({
                    otp: yup.number().required("Required"),
                })}
                onSubmit={(values, formikHelpers) => {
                    axios({
                        method: "POST",
                        baseURL: process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_CHECK_OTP,
                        withCredentials: true,
                        data: values
                    }).then(({data}) => {
                        dispatchAction(idpRequestSuccess_saga(data, history))
                    }).catch(error => {
                        formikHelpers.setSubmitting(false);
                        let errorMessage = errorExtractor(error);
                        errorMessage = errorMessage.replaceAll("OTP", "Security Code")
                        formikHelpers.setStatus({errorMessage});
                    })
                }}
            >
                {({
                      values, touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      errors, status, setStatus,
                      handleBlur
                  }) => (
                    <Box component={"form"} autoComplete={"off"} sx={{marginY: 1}}>
                        <Input
                            name={"otp"} type={"number"}
                            value={values.otp} required
                            onChange={handleChange} label={"Enter Security Code"}
                            error={touched.otp && (errors.otp !== undefined)}
                            helperText={touched.otp ? errors.otp : ""}
                            onBlur={handleBlur}
                        />
                        {status?.errorMessage &&
                        <Box marginY={2}>
                            <Alert severity={"error"} onClose={() => setStatus({errorMessage: null})}>
                                {status?.errorMessage}
                            </Alert>
                        </Box>
                        }
                        <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} width={"100%"} marginTop={4}>
                            <Button onClick={() => history.replace(URN_AUTH + URN_LOGIN)}>
                                Cancel
                            </Button>
                            <Button variant={"contained"} type={"button"}
                                    disabled={resendInCounter > 0 || isResendingOtp || isSubmitting}
                                    onClick={handleOtpResend}
                                    endIcon={isResendingOtp ?
                                        <CircularProgress color={"inherit"} size={20}/>
                                        : undefined
                                    }
                            >
                                Resend Security Code {resendInCounter > 0 && `(${resendInCounter})`}
                            </Button>
                            <Button
                                type={"submit"}
                                color={"primary"} variant={"contained"} size={"small"} disabled={isSubmitting}
                                onClick={handleSubmit}
                                endIcon={isSubmitting ? <CircularProgress color={"inherit"} size={20}/> : <></>}
                            >
                                Submit
                            </Button>
                        </Stack>

                    </Box>
                )}
            </Formik>

        </div>
    );
}

OtpVerification.propTypes = {
    handleResendOtp: PropTypes.func.isRequired,
    isResendingOtp: PropTypes.bool
};

export default OtpVerification;