import { all , call , put , select , takeLatest } from "redux-saga/effects";
import {HEALTH_SUMMARY}                           from "../actions/ActionTypes";
import {setCacheExpiryTime, setData, setError, setLoading} from "../actions/healthSummaryActions";
import {pageErrorExtractor} from "../../utils/httpErrorHelpers";
import {add, isAfter}                                                               from "date-fns";
import { fetchAllergies , fetchConditions , fetchHealthSummary , fetchMedications } from "../../api/healthSummary";
import {AUTHENTICATED_USER_TYPE}                                                    from "../../constants/portalUserType";

function* handleFetchHealthSummary() {
    const healthSummaryIndivisual = new Object();//holds each indivisual summary object
    const healthSummaryList = [];// Array to hold healthSummaryIndivisual object and others if required.
    const {currentPatientUsername, role} = yield select(state => state?.auth);
    const {error, cacheExpiryTime} = yield select(state => state.healthSummary);
    const isCacheExpired = isAfter(new Date(), new Date(cacheExpiryTime));
    if (role === AUTHENTICATED_USER_TYPE.ADMIN || error || (cacheExpiryTime === null) || isCacheExpired) {
        try {
            yield put(setError(null));
            yield put(setLoading(true));
            // const response = yield call(fetchHealthSummary, currentPatientUsername);
            const [allergies, conditions,medications] = yield all([
                call(fetchAllergies, currentPatientUsername),
                call(fetchConditions, currentPatientUsername),
                 call(fetchMedications, currentPatientUsername),
            ])
            healthSummaryIndivisual["currentAllergies"]= yield allergies.data;
            healthSummaryIndivisual["currentConditions"]= yield conditions.data;
            healthSummaryIndivisual["currentMedications"]=  yield medications.data;
            healthSummaryList.push(healthSummaryIndivisual);
            const healthSummaryData={healthSummaryList};
            const data = yield healthSummaryData.healthSummaryList;
            yield put(setData(data));
            yield put(setLoading(false));
            //Set cache expiry time. Cache is valid for 2 hours
            yield put(setCacheExpiryTime(add(new Date(), {hours: 2}).toString()))
        } catch (error) {
            yield put(setError(pageErrorExtractor(error)));
            yield put(setLoading(false));
        }
    }
}


function* healthSummarySaga() {
    yield takeLatest(HEALTH_SUMMARY.FETCH_ALL, handleFetchHealthSummary);
}

export default healthSummarySaga;