import React, {useState} from 'react';
import {Box, Button, Drawer, Typography, useTheme} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {useSelector} from "react-redux";

const positionTop = 100;

function CurrentPatientDrawer() {
    const theme = useTheme();
    const [isDrawerOpen, openDrawer] = useState(false);
    const {currentPatientUsername, currentPatientName} = useSelector(state => state?.auth);

    return (
        currentPatientUsername && currentPatientName &&
        <>
            <Box position={"fixed"} top={positionTop} right={".5rem"} zIndex={theme.zIndex.drawer}>
                <Button onClick={() => openDrawer(true)} size={"small"} color={"primary"} variant={"contained"}
                        sx={{
                            minWidth: "auto",
                            borderRadius: "50%",
                            height: 35,
                            width: 35,
                            boxShadow: `${theme.palette.primary.light} 0 5px 15px`,
                            "&:hover": {
                                boxShadow: `${theme.palette.primary.light} 0 8px 20px`,
                            }
                        }}>
                    <ArrowBackIcon/>
                </Button>
            </Box>
            <Drawer anchor={"right"} open={isDrawerOpen} onClose={() => openDrawer(false)}
                    sx={{"& .MuiPaper-root": {top: positionTop, maxHeight: 250, overflow: "hidden"}}}>
                <Box minWidth={200} height={"100%"} padding={3} display={"flex"} flexDirection={"column"}>
                    <Box marginBottom={2} flex={1}>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            Current Patient Name
                        </Typography>
                        <Typography variant={"subtitle1"}>{currentPatientName}</Typography>
                    </Box>
                    <Box marginBottom={2} flex={1}>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            Current Patient Username
                        </Typography>
                        <Typography variant={"subtitle1"}>{currentPatientUsername}</Typography>
                    </Box>
                    <Button onClick={() => openDrawer(false)} fullWidth variant={"outlined"}>
                        Close
                    </Button>
                </Box>
            </Drawer>
        </>
    );
}

export default CurrentPatientDrawer;