import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { PageContentWrapper } from '../../styles/page';
import { useDispatch, useSelector } from 'react-redux';
import NoInsuranceCard from './NoInsuranceCard';
import { INSURANCE } from '../../../redux/actions/ActionTypes';
import useScrollToTop from '../../../hooks/useScrollToTop';
import withLoaderAndErrorCard from '../../../hoc/withLoaderAndErrorCard';
import InsuranceCard from './InsuranceCard';

export default function Insurance() {
    useScrollToTop();
    const dispatchAction = useDispatch();
    const { currentPatientUsername } = useSelector(state => state?.auth);
    const { isLoading, error, data } = useSelector(state => state?.insurance);

    useEffect(() => {
        currentPatientUsername && fetchInsurances();
    }, [currentPatientUsername]);

    const fetchInsurances = () => {
        dispatchAction({ type: INSURANCE.FETCH_ALL });
    };

    const contentJsx = () => (
        <Box>
            {data?.length === 0
                ? <Box display={'flex'} justifyContent={'center'}>
                    <NoInsuranceCard />
                </Box>
                : <Box>
                    <Box marginBottom={2}>
                        <Typography variant={'h6'}>Insurances {`(${data?.length})`}</Typography>
                    </Box>
                    <Stack spacing={2}>
                        {data?.map((insurance, i) => (
                            <InsuranceCard
                                key={`${insurance?.policyNumber}-${i}`} mb={4}
                                data={insurance}
                            />
                        ))}
                    </Stack>
                </Box>
            }
        </Box>
    );

    return (
        <PageContentWrapper>
            {currentPatientUsername
                ? withLoaderAndErrorCard(contentJsx)({
                    isLoading, error, onReloadClick: fetchInsurances,
                })
                : null
            }
        </PageContentWrapper>

    );
}