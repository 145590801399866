import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Input from "./Input";
import {InputAdornment} from "@mui/material";
import {VisibilityOffOutlined as HidePasswordIcon, VisibilityOutlined as ShowPasswordIcon} from "@mui/icons-material";

function PasswordInput(props) {
    const [passwordHidden, setPasswordHidden] = useState(true);
    const {value, onChange, name, id, placeholder, label, error, onBlur, autoComplete, helperText, size} = props;

    return (
        <Input
            id={id} name={name} required={true} type={passwordHidden ? "password" : "text"}
            label={label} placeholder={placeholder} autoComplete={autoComplete}
            onChange={onChange} onBlur={onBlur} value={value}
            error={error} helperText={helperText} size={size}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" sx={{cursor: "pointer"}}
                                    onClick={() => setPasswordHidden(prevState => !prevState)}>
                        {passwordHidden ? <ShowPasswordIcon/> : <HidePasswordIcon/>}
                    </InputAdornment>
                ),
            }}
        />
    );
}

PasswordInput.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    autoComplete: PropTypes.oneOf(["current-password", "new-password", "off"]).isRequired,
    size: PropTypes.oneOf(["small", "medium"])
};

export default PasswordInput;