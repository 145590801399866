import React from 'react';
import { Box } from '@mui/material';
import { PageContentWrapper } from '../../styles/page';
import Prescriptions from './Prescriptions';
import useScrollToTop from '../../../hooks/useScrollToTop';
import Supplies from './Supplies';

function PrescriptionsPage() {
    useScrollToTop();

    return (
        <PageContentWrapper>
            <Box>
                <Box marginBottom={4}>
                    <Prescriptions/>
                </Box>
                <Box>
                    <Supplies/>
                </Box>
            </Box>
        </PageContentWrapper>
    );
}

export default PrescriptionsPage;
