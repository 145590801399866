import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { fetchCareTeamMessageUnreadCount } from '../../api/careTeam';
import { setUnreadMessageCount } from '../actions/headerActions';
import { LIVE_UPDATES } from '../actions/ActionTypes';

const POLLING_DELAY = 30000; //milliseconds
const CANCEL_STATUS_POLLING = 'CancelStatusPolling';

export function* careTeamMessageUnreadPollingWorker() {
    while (true) {
        try {
            const { currentPatientUsername } = yield select(state => state?.auth);
            // const { unreadMessageCount } = yield select(state => state?.header);
            if (currentPatientUsername) {
                const { data } = yield call(fetchCareTeamMessageUnreadCount, currentPatientUsername);
                //Update the store with new status
                yield put(setUnreadMessageCount(data));
                // if (typeof data === 'number' && data > 0 && data > unreadMessageCount) {
                //     toast.info(`You have ${data} unread ${data > 1 ? 'messages' : 'message'}`,
                //         {
                //             position: 'top-right',
                //             autoClose: 4000,
                //         });
                // }
            }
            yield delay(POLLING_DELAY);
        } catch (error) {
            yield delay(POLLING_DELAY);
            // yield put({ type: CANCEL_STATUS_POLLING })
            // yield put(actions.updateOrderStatus("Error"))
        }
    }
}

function* careTeamMessageUnreadWatchWorker() {
    yield race({
        //Start the polling worker
        task: call(careTeamMessageUnreadPollingWorker),
        //Start a take effect waiting for the cancel action.
        cancel: take(CANCEL_STATUS_POLLING),
    });
}

function* liveUpdatesSaga() {
    yield  takeLatest(LIVE_UPDATES.SUBSCRIBE_CARE_TEAM_MESSAGE_UNREAD, careTeamMessageUnreadWatchWorker);
}

export default liveUpdatesSaga;