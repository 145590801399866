import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import Input from '../../components/Input';
import { FieldArray, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { fetchInsurances } from '../../../api/insurance';
import ErrorCard from '../../components/ErrorCard';
import { orderSupplies } from '../../../api/prescriptionsSupplies';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlined';
import { postOrPutErrorExtractor } from '../../../utils/httpErrorHelpers';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/HighlightOff';

const CenteredBoxWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 400px;
`;

function RefillSupplies({ data, onSuccess, onCancel }) {
    const formData = data?.map(name => ({ name, quantity: null, quantityOnHand: null }));

    const [isLoadingInsurance, setLoadingInsurance] = useState(true);
    const [insuranceError, setInsuranceError] = useState(null);
    const [isPartBInsurance, setIsPartBInsurance] = useState(false);
    //Once the refill is placed, this list helps in showing a success disclaimer and the list of items ordered.
    const [successSuppliesList, setSuccessSuppliesList] = useState([]);

    const isRefillRequestSucceed = successSuppliesList?.length > 0;

    const { currentPatientUsername } = useSelector(state => state?.auth);

    useEffect(() => {
        getInsurance();
    }, []);

    const getInsurance = () => {
        setLoadingInsurance(true);
        fetchInsurances(currentPatientUsername)
            .then(({ data }) => {
                //data is array of insurances
                setIsPartBInsurance(data?.some(insurance => insurance?.insuranceName?.toLowerCase().startsWith('part b')));
                setLoadingInsurance(false);
            })
            .catch(() => {
                setLoadingInsurance(false);
                setInsuranceError('Something went wrong, please try again');
            });
    };

    const validateInputNumber = (e, minValue) => {
        const { value } = e?.target;
        const val_ = value === '' ? null : Number(value);
        if (val_ >= minValue)
            return val_;
        else
            return null;
    };

    /**
     * Return the reason for validation error of this field
     * @param name name of field, filled by user
     * @param quantity quantity filled
     * @param quantityOnHand quantity on hand filled
     * @returns {string}
     */
    const otherFieldErrorText = (name, quantity, quantityOnHand) => {
        let response;
        if (name) {
            if (isPartBInsurance) {
                if (quantity === null || quantity === '' || quantityOnHand === '' || quantityOnHand === null)
                    response = 'Please provide QTY Request and QTY on Hand';
            } else if (quantity === null || quantity === '') {
                response = 'Please provide QTY Request';
            }
        }
        return response;
    };

    /**
     * Checks if the field has error or not and returns the boolean flag. This display the TextField as Red
     * @param name name of field, filled by user
     * @param quantity quantity filled
     * @param quantityOnHand quantity on hand filled
     * @returns {boolean}
     */
    const otherFieldErrored = (name, quantity, quantityOnHand) => {
        let response = false;
        if (name) {
            if (isPartBInsurance)
                response = quantity < 1 || (quantityOnHand === null || quantityOnHand === '');
            else
                response = quantity < 1;
        }
        return response;
    };

    return (
        <>
            <Formik
                initialValues={{
                    data: [...formData, { name: '', quantity: null, quantityOnHand: null }],
                }}
                onSubmit={(values, formikHelpers) => {
                    formikHelpers.setStatus({ errorMessage: null });
                    const { data } = values;

                    if (isPartBInsurance) {
                        let errorMessage;
                        const failedValidationItem = data?.find(
                            ({
                                 name,
                                 quantity,
                                 quantityOnHand,
                             }) => {
                                if (Boolean(name)) {
                                    if (quantity > 0) {
                                        errorMessage = <div>
                                            Please provide 'Quantity on Hand' for <strong>{name}</strong>
                                        </div>;
                                        return (quantityOnHand < 0 || quantityOnHand === '' || quantityOnHand === null) || quantityOnHand >= 0 && !Boolean(quantity);
                                    }
                                    if (quantityOnHand !== null && quantityOnHand !== '') {
                                        errorMessage = <div>
                                            Please provide 'Quantity Request' for <strong>{name}</strong>
                                        </div>;
                                        return quantity === null || quantity === '';
                                    }
                                }
                            },
                        );
                        if (failedValidationItem) {
                            formikHelpers.setStatus({ errorMessage });
                            formikHelpers.setSubmitting(false);
                            return;
                        }
                    }

                    let filteredData;
                    if (isPartBInsurance) {
                        filteredData = data?.filter(
                            ({
                                 name,
                                 quantity,
                                 quantityOnHand,
                             }) => Boolean(name) && (quantity !== null && quantity > 0) && (quantityOnHand !== null && quantityOnHand >= 0),
                        );
                    } else {
                        filteredData = data?.filter(
                            ({
                                 name,
                                 quantity,
                             }) => Boolean(name) && quantity !== null && quantity > 0,
                        );
                    }

                    orderSupplies(currentPatientUsername, filteredData)
                        .then(() => {
                            formikHelpers.setSubmitting(false);
                            setSuccessSuppliesList(filteredData);
                        })
                        .catch((error) => {
                            formikHelpers.setSubmitting(false);
                            formikHelpers.setStatus({ errorMessage: postOrPutErrorExtractor(error) });
                        });
                }}
            >
                {({
                      values, status,
                      handleChange,
                      handleSubmit, errors, setFieldValue, setStatus,
                      handleBlur, dirty, isSubmitting,
                  }) => (
                    <Dialog open={true} fullWidth maxWidth={'md'}>
                        <DialogContent>
                            {isLoadingInsurance
                                ? <CenteredBoxWrapper>
                                    <CircularProgress color={'primary'} />
                                </CenteredBoxWrapper>
                                : insuranceError
                                    ? <CenteredBoxWrapper>
                                        <ErrorCard
                                            title={'Error'}
                                            description={insuranceError}
                                            onReloadClick={getInsurance} />
                                    </CenteredBoxWrapper>
                                    : <Box sx={{
                                        '._verySmall': {
                                            width: '50px',
                                            'input': {
                                                padding: '.5rem',
                                            },
                                        },
                                    }}>
                                        {isRefillRequestSucceed &&
                                        <Stack direction={'row'} width={'100%'} alignItems={'center'} spacing={1}
                                               mb={3}>
                                            <SuccessIcon color={'success'} sx={{ width: 40, height: 40 }} />
                                            <Typography gutterBottom variant={'h5'}>
                                                Refill order placed. You will shortly receive an email for the same.
                                            </Typography>
                                        </Stack>
                                        }
                                        <TableContainer sx={{ maxHeight: '75vh' }}>
                                            <Table stickyHeader aria-label='refill supply table' sx={{ minWidth: 650 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='left'>Item Description</TableCell>
                                                        <TableCell align='center'>QTY Request</TableCell>
                                                        <TableCell align='center'>QTY On Hand</TableCell>
                                                        {!isRefillRequestSucceed &&
                                                        <TableCell width={40}>{''}</TableCell>
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {isRefillRequestSucceed
                                                        ? successSuppliesList?.map((supply, i) => {
                                                            const { name, quantity, quantityOnHand } = supply;
                                                            return (
                                                                <TableRow key={name + i}>
                                                                    <TableCell align={'left'}>
                                                                        {name}
                                                                    </TableCell>
                                                                    <TableCell align={'center'}>
                                                                        {quantity}
                                                                    </TableCell>
                                                                    <TableCell align={'center'}>
                                                                        {quantityOnHand}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                        : <FieldArray
                                                            name='data'
                                                            render={arrayHelpers => (
                                                                <>
                                                                    {values?.data?.map((supply, i) => {
                                                                        const {
                                                                            name,
                                                                            quantity,
                                                                            quantityOnHand,
                                                                        } = supply;
                                                                        const isFreeTextForName = !formData.some(supply => supply?.name === name);
                                                                        return (
                                                                            <TableRow key={i}>
                                                                                <TableCell align={'left'}>
                                                                                    {isFreeTextForName
                                                                                        ? <TextField
                                                                                            name={`data[${i}].name`}
                                                                                            id={`data[${i}].name`}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            placeholder={'Other'}
                                                                                            value={name} fullWidth
                                                                                            helperText={otherFieldErrorText(name, quantity, quantityOnHand)}
                                                                                            error={otherFieldErrored(name, quantity, quantityOnHand)}
                                                                                        />
                                                                                        :
                                                                                        <Typography>{name}</Typography>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align={'center'}
                                                                                           valign={'top'}>
                                                                                    <Input
                                                                                        name={`data[${i}].quantity`}
                                                                                        fullWidth={false}
                                                                                        autoComplete={'off'}
                                                                                        className={'_verySmall'}
                                                                                        disabled={!Boolean(name)}
                                                                                        onBlur={handleBlur}
                                                                                        value={quantity}
                                                                                        onChange={(e) => setFieldValue(`data[${i}].quantity`, validateInputNumber(e, 1))}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align={'center'}
                                                                                           valign={'top'}>
                                                                                    <Input
                                                                                        name={`data[${i}].quantityOnHand`}
                                                                                        fullWidth={false}
                                                                                        autoComplete={'off'}
                                                                                        className={'_verySmall'}
                                                                                        disabled={!Boolean(name)}
                                                                                        onBlur={handleBlur}
                                                                                        value={quantityOnHand}
                                                                                        onChange={(e) => setFieldValue(`data[${i}].quantityOnHand`, validateInputNumber(e, 0))}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell padding={'none'}>
                                                                                    {isFreeTextForName &&
                                                                                    <Tooltip title={'Remove row'}>
                                                                                        <IconButton
                                                                                            color={'error'}
                                                                                            onClick={() => arrayHelpers.remove(i)}
                                                                                        >
                                                                                            <RemoveIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    })}
                                                                    <TableRow>
                                                                        <TableCell size={'small'}
                                                                                   sx={{ border: 'none' }}>
                                                                            <Button variant={'text'}
                                                                                    startIcon={<AddIcon />}
                                                                                    onClick={() => arrayHelpers.push({
                                                                                        name: '',
                                                                                        quantity: null,
                                                                                        quantityOnHand: null,
                                                                                    })}
                                                                            >
                                                                                Add row
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Box width={'100%'} textAlign={'right'}>
                                {status?.errorMessage &&
                                <Alert severity={'error'} sx={{ mb: 1 }}
                                       onClose={() => setStatus({ errorMessage: null })}>
                                    {status?.errorMessage}
                                </Alert>
                                }
                                {!isLoadingInsurance && !insuranceError && !isRefillRequestSucceed &&
                                <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
                                    <Button variant={'outlined'} onClick={onCancel} disabled={isSubmitting}>
                                        Cancel
                                    </Button>
                                    <Button variant={'contained'}
                                            onClick={handleSubmit}
                                            disabled={isSubmitting || !dirty || Object.keys(errors)?.length > 0}
                                            endIcon={isSubmitting && <CircularProgress color={'inherit'} size={20} />}
                                    >
                                        Submit
                                    </Button>
                                </Stack>
                                }
                                {isRefillRequestSucceed &&
                                <Button variant={'outlined'} onClick={onSuccess}>
                                    Close
                                </Button>
                                }
                            </Box>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    );
}

RefillSupplies.propTypes = {
    data: PropTypes.arrayOf(PropTypes.string),
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
};

export default RefillSupplies;