import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Dialog, DialogActions, DialogContent, Link, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {useQuery} from "../../../hooks/useQuery";
import {useLocation} from "react-router-dom";

function CareTeamMemberPhoneDisplay({onClose}) {
    const location = useLocation();
    const queryParams = useQuery(location.search);
    const activeCareTeamMemberRole = queryParams.get("role");
    const {data} = useSelector(state => state.careTeam);
    const careTeamMemberKeys = Object.keys(data);
    const phoneNumber = data[careTeamMemberKeys.find(key => data[key].role === activeCareTeamMemberRole)]?.phoneNumber;

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogContent sx={{minWidth: 380}}>
                <Box minHeight={100} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    {phoneNumber
                        ? <Box sx={{"& a": {textDecoration: "none"}}}>
                            <Typography component={Link} color={"textPrimary"} href={`tel:${phoneNumber}`}>
                                <strong>{phoneNumber}</strong>
                            </Typography>
                        </Box>
                        : <Typography>Phone Number Not Available</Typography>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} fullWidth variant={"outlined"}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CareTeamMemberPhoneDisplay.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default CareTeamMemberPhoneDisplay;