import React from 'react';
import PropTypes, {bool, string} from 'prop-types';
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox, FormControl} from "@mui/material";

function CheckboxWithLabel(props) {
    const {label, name, onChange, value, disabled, style, checked} = props;

    return (
        <FormControl size={"small"} style={{style}}>
            <FormControlLabel
                name={name}
                control={
                    <Checkbox
                        color="primary"
                        name={name}
                        checked={checked || (value === true)}
                    />
                }
                label={label}
                onChange={onChange}
                disabled={disabled}
                style={style}
            />
        </FormControl>
    );
}

CheckboxWithLabel.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([string, bool]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    checked: PropTypes.bool
};

export default CheckboxWithLabel;