import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MainContentModal from '../../components/MainContentModal';
import { addOrUpdateJournal } from '../../../api/infusionJournal';
import { format } from 'date-fns';
// import Moment from 'moment';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import Input from '../../components/Input';
/**
 * import * as yup from "yup";
 */
import { Formik } from 'formik';
import { postOrPutErrorExtractor } from '../../../utils/httpErrorHelpers';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import SelectOutlined from '../../components/SelectOutlined';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { INFUSION_JOURNAL } from '../../../redux/actions/ActionTypes';
import { useDispatch } from 'react-redux';

function InfusionJournalModal ( {isOpen , onClose , data} ) {
    const [ checkBoxVal , setCheckBoxVal ] = useState ( [
        "Agitation" ,
        "Anaphylaxis-related" ,
        "Anxiety" ,
        "Aseptic meningitis" ,
        "Bloating" ,
        "BP changes, both increased & decreased" ,
        "BP changes, decreased" ,
        "BP changes, increased " ,
        "Chills" ,
        "Cognitive disturbance, memory impairment" ,
        "Constipation " ,
        "Cough" ,
        "Delirium" ,
        "Diarrhea" ,
        "Dizziness / faintness" ,
        "Dysgeusia" ,
        "Edema" ,
        "Edema, entire body" ,
        "Edema, limbs" ,
        "Erythema, mild (not injection site)" ,
        "Erythema, moderate to severe (not injection site) " ,
        "Fatigue (lack of energy)" ,
        "Fever" ,
        "Flushing " ,
        "Headache (complete pain scale)" ,
        "Hematuria" ,
        "Hemolytic anemia" ,
        "Hives / urticaria" ,
        "Injection site reaction, IV (not mechanical-related)" ,
        "Injection site reaction, SC" ,
        "Insomnia" ,
        "Irritability" ,
        "Loss of appetite" ,
        "Malaise (vague feeling of discomfort)" ,
        "Migraine (complete pain scale)" ,
        "Movements, involuntary" ,
        "Muscle weakness, genera" ,
        "Myalgia" ,
        "Nausea" ,
        "Nausea with vomiting" ,
        "Neutropenia" ,
        "Other (describe below)" ,
        "Pain" ,
        "Pain, abdominal / GI (complete pain scale)" ,
        "Pain, back (complete pain scale)" ,
        "Pain, chest [cardiac] (complete pain scale)" ,
        "Pain, chest [non-cardiac] (complete pain scale)" ,
        "Pain, extremity (complete pain scale)" ,
        "Pain, hip (complete pain scale)" ,
        "Pain, muscle [myalgia] (complete pain scale)" ,
        "Pain, neck (complete pain scale)" ,
        "Pain, nerve [neuralgia] (complete pain scale)" ,
        "Palpitations" ,
        "Phlebitis" ,
        "Pruritus / itching" ,
        "Purpura" ,
        "Rash" ,
        "Renal failure / dysfunction (BB)" ,
        "Restlessness" ,
        "Rigors" ,
        "Shortness of breath / dyspnea (not anaphylaxis-related)" ,
        "Tachycardia / increase pulse rate" ,
        "TE, cerebral vascular accident/stroke (BB)" ,
        "TE, deep vein thrombosis (BB)" ,
        "TE, myocardial infarction (BB)" ,
        "TE, pulmonary embolism (BB)" ,
        "TE, transient ischemic attack (BB)" ,
        "Transfusion-related acute lung injury (TRALI)" ,
        "Urinary retention" ,
        "Urine discoloration" ,
        "Vertigo" ,
        "Wheezing (not anaphylaxis-related)" ,
    ] );
    const [ showItems , setShowItems ] = useState ( () =>
        checkBoxVal?.length > 15 ? 15 : checkBoxVal?.length
    );
    const [ reactionCheckBox , setReactionCheckBox ] = useState ( [
        "Erythema" ,
        "Heat" ,
        "Itching" ,
        "Pain (complete pain scale)" ,
        "Swelling" ,
        "No injection site reaction"
    ] );

     const dispatchAction = useDispatch ();
    const {
        id ,
        type ,
        entryDate ,
        treatmentDescription ,
        medicationName ,
        medicationDose ,
        vialName ,
        strength ,
        lotNumber ,
        painScale ,
        sideEffectsTime ,
        sideEffects ,
        injectionSite ,
        missingSupply ,
        defectiveSupply
    } = data;

    const [ isDataSaved , setDataSaved ] = useState ( false );
    const [ isMessageSent , setMessageSent ] = useState ( false );
    const [ isConfirmationOpen , openConfirmation ] = useState ( false );
    const [ supplyIssue , setSupplyIssue ] = useState ( [
        '50ml Syringe' ,
        'Adapter Spike' ,
        'Alcohol wipes' ,
        'Benadryl' ,
        'Epi-Pen' ,
        'Freedom Pump' ,
        'Freedom Tubing' ,
        'Gauze Pads' ,
        'Gloves' ,
        'Hi-Flo Needle Set' ,
        'Paper Tape' ,
        'Sharps Container' ,
        'Sterile Drapes' ,
        'Syringe Cap Tip' ,
        'Tape' ,
        'Tylenol'
    ] );
    const fetchJournals = (  ) => {
        dispatchAction ( {type : INFUSION_JOURNAL.FETCH_ALL ,} );
    };
    return (
        <>
            <MainContentModal onClose={ onClose } isOpen={ isOpen }>
                <Formik
                    initialValues={ {
                        id ,
                        type ,
                        entryDate ,
                        treatmentDescription ,
                        medicationName ,
                        medicationDose ,
                        vialName ,
                        strength ,
                        lotNumber ,
                        painScale ,
                        sideEffectsTime ,
                        sideEffects ,
                        injectionSite ,
                        missingSupply ,
                        defectiveSupply
                    } }

                    onSubmit={ ( values , formikHelpers ) => {
                        values.entryDate = format ( new Date () , "MM/dd/yyyy" );
                        const dateStr = values.entryDate;
                        // values.entryDate = Moment ( dateStr ).format ( 'YYYY-MM-DD' );
                        let proceedSave = "";
                        if (values.isSaveAndSendCss) {
                            proceedSave = "SAVEANDSENDCSS";
                        } else if (values.isSave) {
                            proceedSave = "SAVE";
                        }
                        delete values["isSaveAndSendCss"];
                        delete values["isSave"];

                        addOrUpdateJournal ( values , proceedSave )
                            .then ( () => {
                                if(proceedSave === 'SAVEANDSENDCSS') {
                                    setMessageSent ( true );
                                }
                                if (proceedSave === 'SAVE'){
                                    setDataSaved(true)
                                }
                                formikHelpers.setSubmitting ( false );
                                fetchJournals ();
                            } )
                            .catch ( ( error ) => {
                                const errorMessage = postOrPutErrorExtractor (
                                    error ,
                                    formikHelpers
                                );
                                formikHelpers.setStatus ( { errorMessage } );
                                formikHelpers.setSubmitting ( false );
                            } );
                    } }

                >
                    { ( {
                            values ,
                            touched ,
                            handleChange ,
                            handleSubmit ,
                            status ,
                            setStatus ,
                            isSubmitting ,
                            errors ,
                            handleBlur ,
                            dirty ,
                            setFieldValue ,
                            setFieldTouched ,
                        } ) => (
                        <>
                            <DialogContent>
                                <form autoComplete={ "off" }>
                                    <Box sx={ {flexGrow : 1} }>
                                        <Grid item xs={ 12 }>
                                            <Typography variant={ "subtitle1" } color={ "primary" }>
                                                What type of issue would you like to create a journal
                                                entry for?
                                            </Typography>
                                            <SelectOutlined
                                                name={ "type" }
                                                required
                                                label={ "Select" }
                                                value={ values.type }
                                                loading={ true }
                                                onChange={ handleChange }
                                                style={ {maxWidth : 300} }
                                                onBlur={ handleBlur }
                                                helperText={ errors?.type }
                                                error={
                                                    touched?.type &&
                                                    errors?.type !== undefined
                                                }
                                            >
                                                { " " }
                                                <MenuItem key={ "sideEffectIssue" } id={ "sideEffectIssue" }
                                                          value={ "SIDE_EFFECTS" }>
                                                    Side Effect Issue
                                                </MenuItem>
                                                <MenuItem key={ "supplyIssue" } id={ "supplyIssue" }
                                                          value={ "SUPPLIES_CONCERNS" }>
                                                    Supply Issue
                                                </MenuItem>
                                                <MenuItem key={ "otherIssue" } id={ "otherIssue" } value={ "OTHER" }>
                                                    Other Issue
                                                </MenuItem>
                                            </SelectOutlined>
                                        </Grid>
                                        { values.type === 'SIDE_EFFECTS' &&
                                            (
                                                <Grid container spacing={ 2 } sx={ {m : 1 , p : 1} }>

                                                    <Grid item xs={ 8 } >
                                                        <Typography variant={ "subtitle1" } color={ "primary" }>
                                                            Select Side Effect
                                                        </Typography>
                                                        { checkBoxVal?.length > 0 ? (
                                                            <FormControl
                                                                component="fieldset"
                                                                sx={ {display : "flex"} }
                                                            >
                                                                <FormGroup row>{

                                                                    checkBoxVal.sort ( ( a , b ) => ( a < b ? -1 : 1 ) )
                                                                        .slice ( 0 , ( showItems ) ).map ( ( item , i ) =>
                                                                        (
                                                                            <Grid item xs={ 4 } key={ i }>
                                                                                <FormControlLabel key={ i }
                                                                                                  value={ item }
                                                                                                  name={ "sideEffects" }
                                                                                                  control={ <Checkbox
                                                                                                      key={ item + i }
                                                                                                      onChange={ handleChange }
                                                                                                      onBlur={ handleBlur }/> }
                                                                                                  label={ item }
                                                                                                  color={ 'inherit' }
                                                                                                  labelPlacement="end"/>
                                                                            </Grid> )
                                                                    )
                                                                }
                                                                </FormGroup>
                                                            </FormControl>

                                                        ) : (
                                                            <Box>
                                                                <Card>
                                                                    <CardContent>
                                                                        <Typography color={ "error" }>
                                                                            There are currently no Side effects . If
                                                                            this
                                                                            is incorrect, please call Nufactor technical
                                                                            support at
                                                                            1-800-323-6832 to update your profile with a
                                                                            team member.
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            </Box>
                                                        ) }
                                                        { checkBoxVal?.length > 15 && (
                                                            <Box textAlign={ "center" }
                                                                 sx={ {"& .MuiButton-root._showMore" : {color : "text.secondary"}} }>
                                                                <Button
                                                                    color={ "inherit" }
                                                                    className={ "_showMore" }
                                                                    endIcon={ showItems > 15 ? <ArrowUpIcon/> :
                                                                        <ArrowDownIcon/> }
                                                                    onClick={ () =>
                                                                        showItems === checkBoxVal?.length
                                                                            ? setShowItems ( 15 )
                                                                            : setShowItems ( checkBoxVal?.length )
                                                                    }
                                                                >
                                                                    { showItems > 15
                                                                        ? "Show Less"
                                                                        : `Show All ${ checkBoxVal.length } Side Effects` }
                                                                </Button>
                                                            </Box>
                                                        ) }
                                                    </Grid>
                                                    <Grid item xs={ 8 }>
                                                        <Typography variant={ "subtitle1" } color={ "primary" }>
                                                            When did the side effects occur?
                                                        </Typography>
                                                        <SelectOutlined
                                                            name={ "sideEffectsTime" }

                                                            label={ "Select" }
                                                            value={ values.sideEffectsTime }
                                                            style={ {maxWidth : 300} }
                                                            onChange={ handleChange }
                                                            onBlur={ handleBlur }
                                                            helperText={ errors?.sideEffectsTime }
                                                            error={
                                                                touched?.sideEffectsTime &&
                                                                errors?.sideEffectsTime !== undefined
                                                            }
                                                        >
                                                            { " " }
                                                            <MenuItem id={ "duringInfusion" }
                                                                      value={ "During infusion" }>
                                                                During infusion
                                                            </MenuItem>
                                                            <MenuItem id={ "afterInfusion" } value={ "afterInfusion" }>
                                                                After infusion
                                                            </MenuItem>
                                                        </SelectOutlined>
                                                    </Grid>
                                                    <Grid item xs={ 8 }>
                                                        <Typography variant={ "subtitle1" } color={ "primary" }>
                                                            { " " }
                                                            Did injection site have a reaction?
                                                        </Typography>
                                                        <FormControl
                                                            component="fieldset"
                                                            sx={ {display : "flex"} }
                                                        >
                                                            <FormGroup row>
                                                                {

                                                                    reactionCheckBox.map ( ( val , i ) =>
                                                                        (
                                                                            <FormControlLabel sx={ {width : 300} }
                                                                                              key={ i }
                                                                                              value={ val }
                                                                                              name={ "injectionSite" }
                                                                                              control={ <Checkbox
                                                                                                  key={ val + i }
                                                                                                  onChange={ handleChange }
                                                                                                  onBlur={ handleBlur }/> }
                                                                                              label={ val }
                                                                                              labelPlacement="end"/> )
                                                                    )
                                                                }
                                                            </FormGroup>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item xs={ 8 }>
                                                        <Typography variant={ "subtitle1" } color={ "primary" }>
                                                            Please describe the pain associated with the side
                                                            effect according to the pain rating scale (0= no pain,
                                                            10 = most intense pain).
                                                        </Typography>

                                                        <SelectOutlined
                                                            name={ "painScale" }
                                                            label={ "Select" }
                                                            value={ values.painScale }
                                                            loading={ true }
                                                            style={ {maxWidth : 300} }
                                                            onChange={ handleChange }
                                                            onBlur={ handleBlur }
                                                            helperText={ errors?.painScale }
                                                            error={
                                                                touched?.painScale &&
                                                                errors?.painScale !== undefined
                                                            }
                                                        >
                                                            { " " }
                                                            <MenuItem id={ "0" } value={ "0" }>0</MenuItem>
                                                            <MenuItem id={ "1" } value={ "1" }>1</MenuItem>
                                                            <MenuItem id={ "2" } value={ "2" }>2</MenuItem>
                                                            <MenuItem id={ "3" } value={ "3" }>3</MenuItem>
                                                            <MenuItem id={ "4" } value={ "4" }>4</MenuItem>
                                                            <MenuItem id={ "5" } value={ "5" }>5</MenuItem>
                                                            <MenuItem id={ "6" } value={ "6" }>6</MenuItem>
                                                            <MenuItem id={ "7" } value={ "7" }>7</MenuItem>
                                                            <MenuItem id={ "8" } value={ "8" }>8</MenuItem>
                                                            <MenuItem id={ "9" } value={ "9" }>9</MenuItem>
                                                            <MenuItem id={ "10" } value={ "10" }>10</MenuItem>
                                                        </SelectOutlined>

                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        {
                                            values.type === 'SUPPLIES_CONCERNS' &&
                                            (
                                                <Grid container spacing={ 2 } sx={ {m : 1 , p : 1} }>

                                                    <Grid item xs={ 8 }>
                                                        <Typography variant={ "subtitle1" } color={ "primary" }>
                                                            Please describe the concern you had relating to your
                                                            supplies.
                                                        </Typography>

                                                        <SelectOutlined
                                                            name={ "medicationName" }
                                                            required
                                                            label={ "Select" }
                                                            value={ values.medicationName }
                                                            loading={ true }
                                                            style={ {maxWidth : 300} }
                                                            onChange={ handleChange }
                                                            onBlur={ handleBlur }
                                                            helperText={ errors?.medicationName }
                                                            error={
                                                                touched?.medicationName &&
                                                                errors?.medicationName !== undefined
                                                            }

                                                        >
                                                            { " " }
                                                            {
                                                                supplyIssue.map ( ( item , i ) => (
                                                                        <MenuItem key={ item + i } id={ item + i }
                                                                                  value={ item }>{ item }</MenuItem>
                                                                    )
                                                                ) }


                                                        </SelectOutlined>

                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        {
                                            values.type === 'OTHER' &&
                                            (
                                                <Grid container spacing={ 2 } sx={ {m : 1 , p : 1} }>

                                                    <Grid item xs={ 6 } md={ 4 }>
                                                        <Input
                                                            name={ "medicationName" } label={ "Medication Name" }
                                                            onChange={ handleChange } onBlur={ handleBlur }
                                                            value={ values.medicationName }
                                                            error={ touched?.medicationName && errors && errors.medicationName !== undefined }
                                                            helperText={ touched?.medicationName && errors ? errors.medicationName : undefined }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={ 6 } md={ 4 }>
                                                        <Input
                                                            name={ "medicationDose" } label={ "Total Dose" }
                                                            onChange={ handleChange } onBlur={ handleBlur }
                                                            value={ values.medicationDose }
                                                            error={ touched?.medicationDose && errors && errors.medicationDose !== undefined }
                                                            helperText={ touched?.medicationDose && errors ? errors.medicationDose : undefined }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={ 6 } md={ 4 }>
                                                        <Input
                                                            name={ "vialName" } label={ "Vial Name" }
                                                            onChange={ handleChange } onBlur={ handleBlur }
                                                            value={ values.vialName }
                                                            error={ touched?.vialName && errors && errors.vialName !== undefined }
                                                            helperText={ touched?.vialName && errors ? errors.vialName : undefined }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={ 6 } md={ 4 }>
                                                        <Input
                                                            name={ "strength" } label={ "Vial Strength" }
                                                            onChange={ handleChange } onBlur={ handleBlur }
                                                            value={ values.strength }
                                                            error={ touched?.strength && errors && errors.strength !== undefined }
                                                            helperText={ touched?.strength && errors ? errors.strength : undefined }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={ 6 } md={ 4 }>
                                                        <Input
                                                            name={ "lotNumber" } label={ "Lot Number" }
                                                            onChange={ handleChange } onBlur={ handleBlur }
                                                            value={ values.lotNumber }
                                                            error={ touched?.lotNumber && errors && errors.lotNumber !== undefined }
                                                            helperText={ touched?.lotNumber && errors ? errors.lotNumber : undefined }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Box>
                                </form>
                                { status?.errorMessage && (
                                    <Alert
                                        severity={ "error" }
                                        onClose={ () => setStatus ( {...status , errorMessage : null} ) }
                                        sx={ {marginY : 2} }
                                    >
                                        { status.errorMessage }
                                    </Alert>
                                ) }
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={ "row" } spacing={ 2 }>
                                    <Button
                                        size={ "large" }
                                        onClick={ () => ( dirty ? openConfirmation ( true ) : onClose () ) }
                                        variant={ "outlined" }
                                        disabled={ isSubmitting }
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={ "contained" }
                                        size={ "large" }
                                        color={ "primary" }
                                        onClick={(e)=>{handleSubmit(e);setFieldValue("isSave",true);setFieldValue("isSaveAndSendCss",false);} }
                                        disabled={ !dirty }
                                        endIcon={
                                            isSubmitting ? (
                                                <CircularProgress color={ "inherit" } size={ 20 }/>
                                            ) : undefined
                                        }
                                    >
                                        { "Save" }
                                    </Button>
                                    <Button
                                        variant={ "contained" }
                                        size={ "large" }
                                        color={ "primary" }
                                        onClick={(e)=>{handleSubmit(e);setFieldValue("isSaveAndSendCss",true);setFieldValue("isSave",false)}}
                                        disabled={ !dirty }
                                        endIcon={
                                            isSubmitting ? (
                                                <CircularProgress color={ "inherit" } size={ 20 }/>
                                            ) : undefined
                                        }
                                    >
                                        { "Save & Send to CSS" }
                                    </Button>
                                </Stack>
                            </DialogActions>
                        </>
                    ) }
                </Formik>
            </MainContentModal>

            <Dialog open={ isOpen && (isMessageSent || isDataSaved) }>
                <DialogContent>
                    <Box
                        display={ "flex" }
                        flexDirection={ "column" }
                        justifyContent={ "center" }
                        alignItems={ "center" }
                        minWidth={ 200 }
                    >
                        <SuccessIcon fontSize={ "large" } color={ "success" }/>
                        { isMessageSent === true &&
                            (<Typography variant={ "h6" } color={ "success" }>
                                Data saved and mail sent successfully.
                            </Typography>)
                        }{
                            isDataSaved === true &&
                            (<Typography variant={ "h6" } color={ "success" }>
                                Data saved successfully.
                            </Typography>)
                         }

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={ onClose } variant={ "outlined" }>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={ isConfirmationOpen }>
                <DialogContent>
                    <Box display={ "flex" } alignItems={ "center" }>
                        <Typography>Are you sure you want to close? </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ onClose } variant={ "outlined" }>
                        Discard Changes
                    </Button>
                    <Button
                        color={ "primary" }
                        variant={ "contained" }
                        onClick={ () => openConfirmation ( false ) }
                    >
                        Continue to save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

InfusionJournalModal.propTypes = {
    onClose : PropTypes.func.isRequired ,
    isOpen : PropTypes.bool ,
};

export default InfusionJournalModal;
