import React from 'react';
import { styled } from '@mui/material';
import SideBarNavs from '../navigations/SideBarNavs';
import PropTypes from 'prop-types';

const DrawerMenuWrapper = styled("div")`
  height: 100vh;
  overflow: auto;
`;

function DrawerMenu({openDrawer}) {
    return (
        <DrawerMenuWrapper onMouseLeave={() => openDrawer(false)}>
            <SideBarNavs openDrawer={openDrawer} isChildOfDrawer={true}/>
        </DrawerMenuWrapper>
    );
}

DrawerMenu.propTypes = {
    openDrawer: PropTypes.func.isRequired
}

export default DrawerMenu;