import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Dialog, Divider, useMediaQuery} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useTheme} from "@mui/styles";

function MainContentModal(props) {
    const {onClose, isOpen} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} scroll={"paper"} fullWidth maxWidth={"lg"}>
            <Box paddingY={1} paddingX={1}>
                <Button startIcon={<ArrowBackIcon color={"primary"}/>} onClick={onClose} color={"primary"}>
                    Back
                </Button>
            </Box>
            <Divider/>
            {props.children}
        </Dialog>
    );
}

MainContentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool
};

export default MainContentModal;