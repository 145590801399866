import {RESULTS} from "./ActionTypes";

export const fetchRecentSuccess = (data) => ({
    type: RESULTS.FETCH_RECENT_HISTORY_SUCCESS,
    payload: {data}
})

export const loadMoreRecentSuccess = (data) => ({
    type: RESULTS.LOAD_MORE_RECENT_HISTORY_SUCCESS,
    payload: {data}
})

export const fetchHistorySuccess = (data) => ({
    type: RESULTS.FETCH_HISTORY_SUCCESS,
    payload: {data}
})

export const loadMoreHistorySuccess = (data) => ({
    type: RESULTS.LOAD_MORE_HISTORY_SUCCESS,
    payload: {data}
})

export const setLoadMoreHistoryApiUrl = (url) => ({
    type: RESULTS.SET_LOAD_MORE_HISTORY_API_URL,
    payload: {url}
})

export const setLoadMoreRecentApiUrl = (url) => ({
    type: RESULTS.SET_LOAD_MORE_RECENT_API_URL,
    payload: {url}
})

