import { HEADER, RESET } from '../actions/ActionTypes';

const defaultState = {
    unreadMessageCount: 0,
    avatarUrl: null,
    avatarBytes: null,
    refreshAvatar: false,
};

export const headerReducer = (state = defaultState, action) => {
    switch (action.type) {
        case HEADER.SET_AVATAR_URL: {
            return { ...state, avatarUrl: action.payload.avatarUrl, refreshAvatar: true };
        }

        case HEADER.REFRESH_AVATAR: {
            return { ...state, refreshAvatar: action.payload.refreshAvatar };
        }

        case HEADER.SET_AVATAR_URL_BYTES: {
            return { ...state, avatarBytes: action.payload.avatarBytes };
        }

        case HEADER.SET_UNREAD_MESSAGE_COUNT: {
            return { ...state, unreadMessageCount: action.payload.count };
        }

        case RESET: {
            return { ...defaultState };
        }

        default:
            return { ...state };

    }
};