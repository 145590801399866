export const formatPhoneNumber = (str) => {
    if (!str) {
        return str;
    }
    if (str.toString().length > 12) {
        return str;
    }

    //Filter only numbers from the input
    const cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
    }

    return cleaned;
}

export const validatePhone = (value, required) => {
    const hyphenCount = (value?.match(/-/g) || []).length;
    return value ? (value.toString().replaceAll("-", "").length === 10 && hyphenCount === 2) : !required;
}