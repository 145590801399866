import React, {Component} from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import {URN_PROFILE} from "./constants/clientURNs";

const bgImage = require("./assets/Error.svg").default;

const GlobalErrorBoundaryComponentWrapper = styled.div`
  min-height: 100%;
  width: 100%;

  ._illustration {
    background-image: url(${bgImage});
    background-size: 650px;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-position: center;
    height: 60vh;
    width: 100vw;
  }

  ._error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: rgba(0, 0, 0, .6);
    margin-bottom: 1rem;
    padding: 1rem;
  }

  ._actions {
    display: flex;
    justify-content: center;

    a {
      padding: .5rem;
      text-decoration: none;
      border-radius: 5px;
      background-color: #026DA9;
      color: white;
      transition: all .2s linear;
      width: 250px;
      text-align: center;
      margin-bottom: 1rem;

      :hover {
        opacity: .8;
      }
    }
  }
`;

class GlobalErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        try {
            if (process.env.NODE_ENV !== "development")
                this.props.errorReporter.report(error);
        } catch (e) {
            console.log("error while sending the error report", e);
        }
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <GlobalErrorBoundaryComponentWrapper>
                    <div className="_illustration"/>
                    <div className="_error">
                        <div className="_message">Something went wrong, please try again</div>
                        <div>The issue has been reported to us and will be resolved at the earliest</div>
                    </div>
                    <div className="_actions">
                        <a href={URN_PROFILE}>GO TO MY PROFILE</a>
                    </div>
                </GlobalErrorBoundaryComponentWrapper>
            );
        }

        return this.props.children;
    }

}

GlobalErrorBoundary.propTypes = {
    errorReporter: PropTypes.object.isRequired
}

export default GlobalErrorBoundary;