import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Collapse, styled, Typography } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { SUPPLIES } from '../../../redux/actions/ActionTypes';
import withLoaderAndErrorCard from '../../../hoc/withLoaderAndErrorCard';
import RefillSupplies from './RefillSupplies';

const SuppliesCardsWrapper = styled(TransitionGroup)`
  display: block;

  ._cardWrapper {
    width: 100%;
    margin-bottom: 1rem;
  }

  ${({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      '& ._cardWrapper': {
        width: '100%',
        maxWidth: '900px',
      },
    },
  })}
`;

const LIST_ITEM_DEFAULT = 2;

function Supplies() {
    const { currentPatientUsername } = useSelector(state => state?.auth);
    const { isLoading, error, data } = useSelector(state => state?.supplies);

    const dispatchAction = useDispatch();

    const fetchSupplies = () => {
        dispatchAction({ type: SUPPLIES.FETCH_ALL_SUPPLIES });
    };

    useEffect(() => {
        if (currentPatientUsername) fetchSupplies();
    }, [currentPatientUsername]);

    const [showItems, setShowItems] = useState(() => data?.length > LIST_ITEM_DEFAULT ? LIST_ITEM_DEFAULT : data?.length);
    const [refillSupplies, setRefillSupplies] = useState(false);

    const dataLength = data?.length;

    useEffect(() => {
        if (dataLength) {
            setShowItems(() => dataLength > LIST_ITEM_DEFAULT ? LIST_ITEM_DEFAULT : dataLength);
        }
    }, [dataLength]);

    const contentJsx = () => (
        <Box>
            <Box marginY={2} display={'flex'} alignItems={'center'}>
                <Typography variant={'h6'}> Supplies {data?.length ? `(${data?.length})` : ''}</Typography>
                <Button variant={'contained'} sx={{ marginLeft: 2 }} onClick={() => setRefillSupplies(true)}>
                    Request Refill
                </Button>
            </Box>
            <Box>
                {data?.length > 0
                    ? <>
                        <SuppliesCardsWrapper>
                            {data.sort((a, b) => a.name < b.name ? -1 : 1)
                                .slice(0, showItems)
                                .map((condition, i) => {
                                    const { name } = condition;
                                    return (
                                        <Collapse key={name + '-' + i} className={'_cardWrapper'}>
                                            <Card>
                                                <CardContent sx={{paddingBottom: "1rem !important"}}>
                                                    <Box marginY={1}>
                                                        <Typography component={'h6'} variant={'h6'} color={'primary'}>
                                                            {name}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Collapse>
                                    );
                                })}
                        </SuppliesCardsWrapper>

                        {data?.length > LIST_ITEM_DEFAULT &&
                        <Box textAlign={'left'}>
                            <Button color={'inherit'} className={'_showMore'}
                                    endIcon={showItems > LIST_ITEM_DEFAULT ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                    onClick={() => showItems === data?.length ? setShowItems(LIST_ITEM_DEFAULT) : setShowItems(data?.length)}>
                                {showItems > LIST_ITEM_DEFAULT ? 'Show Less' : `Show All ${data.length} Supplies`}
                            </Button>
                        </Box>
                        }
                    </>
                    : <Box>
                        <Card>
                            <CardContent>
                                <Typography color={'error'}>
                                    There are currently no supplies assigned to you. If this is incorrect, please
                                    call Nufactor technical support at 1-800-323-6832 to update your profile with a team
                                    member.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </Box>
        </Box>
    );

    return (
        <>
            {currentPatientUsername
                ? withLoaderAndErrorCard(contentJsx)({
                    isLoading,
                    loadingText: 'Loading Supplies...',
                    error,
                    onReloadClick: fetchSupplies,
                })
                : null
            }

            {refillSupplies &&
            <RefillSupplies
                data={data?.map(supply => supply?.name)}
                onSuccess={() => setRefillSupplies(false)}
                onCancel={() => setRefillSupplies(false)}
            />
            }

        </>
    );
}


export default Supplies;