import React, {useEffect} from 'react';
import {Box, capitalize, IconButton, Slide, Stack, styled, Typography} from '@mui/material';
import {PageContentWrapper} from "../../styles/page";
import {useDispatch, useSelector} from "react-redux";
import {setHealthSnapshotBannerVisibility} from "../../../redux/actions/siteSettingActions";
import MyMedications from "./MyMedications";
import withLoaderAndErrorCard from "../../../hoc/withLoaderAndErrorCard";
import MyConditions from "./MyConditions";
import CancelIcon from '@mui/icons-material/CancelOutlined';
import MyDrugAllergies from "./MyDrugAllergies";
import MyOtherAllergies from "./MyOtherAllergies";
import {HEALTH_SUMMARY} from "../../../redux/actions/ActionTypes";
import useScrollToTop from "../../../hooks/useScrollToTop";

const FloatingBannerWrapper = styled("div")`
  position: fixed;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  padding: .5rem;

  ._title, ._subTitle {
    color: ${({theme}) => theme.palette.secondary.contrastText};
  }

  ._actions {
    position: absolute;
    right: .5rem;
    top: .5rem;

    button {
      color: ${({theme}) => theme.palette.secondary.contrastText};
    }
  }

  ${({theme}) => ({
    [theme.breakpoints.up("sm")]: {
      padding: "1rem",
      left: "1rem",
      right: "1rem",
      "& ._actions": {
        right: "1rem",
        top: "1rem",
      }
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      left: "10%",
      right: "10%",
      "& ._actions": {
        right: "2rem",
        top: "2rem",
      }
    }
  })}
`;

export default function HealthSnapshot() {
    useScrollToTop();
    const {shouldBannerVisible} = useSelector(state => state?.siteSettings);
    const {firstname, currentPatientUsername} = useSelector(state => state?.auth);
    const dispatchAction = useDispatch();

    useEffect(() => {
        currentPatientUsername && fetchHealthSummary();
    }, [currentPatientUsername])

    const fetchHealthSummary = () => {
        dispatchAction({type: HEALTH_SUMMARY.FETCH_ALL})
    }

    const {isLoading, error, data} = useSelector(state => state.healthSummary)

    const {currentMedications, currentConditions, currentAllergies} = data[0] ?? {}

    const contentJsx = () => (
        <Box marginBottom={2}>
            {/*<Box marginBottom={2} overflow={"scroll"}>*/}
            {/*    <Typography variant={"h6"} color={"textSecondary"} sx={{marginBottom: 1}}>*/}
            {/*        Next Appointment*/}
            {/*    </Typography>*/}
            {/*    <Stack direction={"row"} spacing={3}>*/}
            {/*        {[].map((i) => (*/}
            {/*            <AppointmentCard key={i}/>*/}
            {/*        ))}*/}
            {/*    </Stack>*/}
            {/*</Box>*/}
            <Stack spacing={4} maxWidth={1000} sx={{"& .MuiButton-root._showMore": {color: "text.secondary"}}}>
                <Box>
                    <MyMedications data={currentMedications}/>
                </Box>
                <Box>
                    <MyConditions data={currentConditions}/>
                </Box>
                <Box>
                    <MyDrugAllergies drugAllergies={currentAllergies?.allergies ?? []}/>
                </Box>
                <Box>
                    <MyOtherAllergies otherAllergies={currentAllergies?.otherAllergy ?? []}/>
                </Box>
            </Stack>

            <Slide direction="up" in={shouldBannerVisible}>
                <FloatingBannerWrapper>
                    <Box bgcolor={"secondary.main"} width={"100%"} height={"100%"} padding={4}
                         borderRadius={1}>
                        <Box marginBottom={1}>
                            <Typography component={"h6"} variant={"h6"} className={"_title"}>
                                Welcome, {capitalize(firstname ?? "")}.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className={"_subTitle"}>
                                Access everything you need - recent orders, prescription refills, your healthcare team
                                and health & wellness information.
                            </Typography>
                        </Box>
                        <Box className={"_actions"}>
                            <IconButton onClick={() => dispatchAction(setHealthSnapshotBannerVisibility(false))}>
                                <CancelIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </FloatingBannerWrapper>
            </Slide>
        </Box>
    )

    return (
        <PageContentWrapper>
            {currentPatientUsername
                ? withLoaderAndErrorCard(contentJsx)({
                    error, isLoading, onReloadClick: fetchHealthSummary
                })
                : null
            }
        </PageContentWrapper>
    );
}