import React from 'react';
import PropTypes from 'prop-types';
import { Box , capitalize , Card , CardContent , IconButton , Tooltip , Typography , useTheme } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { useHistory } from "react-router-dom";

function CareTeamSupportMemberCard ( { firstName , lastName , role , label , email } ) {
    const theme = useTheme ();
    const history = useHistory ();


    return (
        <>
            <Card elevation={ 1 }
                  sx={ {

                      "& .MuiCardContent-root" : {
                          paddingBottom : 2
                      }
                  } }
            >
                <CardContent>
                    <Box width={ "100%" } display={ "flex" } justifyContent={ "center" } alignItems={ "center" }
                         minHeight={ 200 }>
                        <Box width={ "100%" } display={ "flex" } flexDirection={ "column" } justifyContent={ "center" }
                             alignItems={ "center" }>
                            <Box display={ "inline-flex" } justifyContent={ "center" } alignItems={ "center" }
                                 marginY={ 2 }>
                                <Typography component={ "h6" } variant={ "h6" }>
                                    <strong>{ capitalize ( firstName ?? "" ) } { capitalize ( lastName ?? "" ) }</strong>
                                </Typography>
                            </Box>
                            <Box textAlign={ "center" }>
                                <Typography variant={ "subtitle1" } color={ "textSecondary" }
                                            sx={ { textTransform : "capitalize" } }>
                                    { label }
                                </Typography>
                            </Box>
                            <Box width={ "100%" } display={ "inline-flex" } justifyContent={ "center" }
                                 alignItems={ "center" }
                                 marginY={ 2 } gap={ 1 }>
                                { email ?
                                    <Tooltip title={ "Email" }>
                                        <IconButton color={ "primary" } size="large"
                                                    onClick={ () => history.push ( `?support=email&role=${ role }` ) }>
                                            <EmailIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    : <></>
                                }
                                <Tooltip title={ "Phone" }>
                                    <IconButton color={ "primary" } size="large"
                                                onClick={ () => history.push ( `?support=phone&role=${ role }` ) }>
                                        <PhoneIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}

CareTeamSupportMemberCard.propTypes = {
    firstName : PropTypes.string.isRequired ,
    lastName : PropTypes.string ,
    role : PropTypes.string.isRequired ,
    label : PropTypes.string.isRequired ,
};

export default CareTeamSupportMemberCard;