import React, { useEffect, useState } from 'react';
import { PageContentWrapper } from '../../styles/page';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    List,
    ListItem,
    Paper,
    Stack,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import ChangePasswordForm from './ChangePasswordForm';
import axios from 'axios';
import { API_URN_MFA_DEVICES, API_URN_PATIENT } from '../../../constants/apiURNs';
import { errorExtractor } from '../../../utils/httpErrorHelpers';
import PhoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/MailOutline';
import { toast } from 'react-toastify';
import NewMfaDevice from './NewMfaDevice';
import { setCurrentPatientDetails } from '../../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHENTICATED_USER_TYPE } from '../../../constants/portalUserType';

const SettingsPageWrapper = styled(Paper)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};

  .MuiButton-root {
    text-transform: none;
  }
`;

function SettingsPage() {
    const [isMfaInfoDialogOpen, openMfaInfoDialog] = useState(false);
    const [isFetchingMfaDevices, setFetchingMfaDevices] = useState(false);
    const [changePassword, setChangePassword] = useState();
    const [mfaDevices, setMfaDevices] = useState([]);
    const [isPassChangeSuccessDialogOpen, openPassChangeSuccessDialog] = useState(false);
    const [deviceToDelete, setDeviceToDelete] = useState(null);
    const [isDeletingMfaDevice, setDeletingMfaDevice] = useState(false);
    const [isNewMfaDevice, setNewMfaDevice] = useState(false);

    const { role } = useSelector(state => state?.auth);
    const dispatchAction = useDispatch();

    useEffect(() => {
        if (role !== 'UNSET' && role !== AUTHENTICATED_USER_TYPE.PATIENT)
            dispatchAction(setCurrentPatientDetails(null, null));
    }, [role]);

    const fetchMfaDevices = () => {
        setFetchingMfaDevices(true);
        const baseUrl = process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_MFA_DEVICES;
        axios.get(baseUrl)
            .then(({ data }) => {
                const { devices } = data;
                setMfaDevices(devices);
                setFetchingMfaDevices(false);
            })
            .catch(error => {
                const errorMessage = errorExtractor(error);
                toast.error(errorMessage);
                setFetchingMfaDevices(false);
            });
    };

    const handlePasswordChangeSuccess = () => {
        setChangePassword(false);
        openPassChangeSuccessDialog(true);
    };

    const handleMfaDeviceDeletion = () => {
        setDeletingMfaDevice(true);
        const deviceId = deviceToDelete?.id;
        if (deviceId) {
            const baseUrl = process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_MFA_DEVICES + '/' + deviceId;
            axios.delete(baseUrl)
                .then(() => {
                    toast.success(`${deviceToDelete?.email ?? deviceToDelete?.phone} successfully deleted from registered MFA devices`);
                    setDeletingMfaDevice(false);
                    setMfaDevices(prevState => prevState.filter(device => device.id !== deviceId));
                    setDeviceToDelete(null);
                })
                .catch(() => {
                    toast.error('Failed to delete MFA device, please try again');
                    setDeletingMfaDevice(false);
                });
        } else {
            toast.error('Cannot process this request at the moment, please try again after some time');
        }
    };

    /**
     * Called after device added to backend
     */
    const handleNewDeviceSuccess = () => {
        setNewMfaDevice(false);
        fetchMfaDevices();
    };

    return (
        <PageContentWrapper>
            <SettingsPageWrapper elevation={0} square>
                <Box marginY={1}>
                    <Typography component={'h2'} variant={'h5'} color={'textSecondary'}>Account Settings</Typography>
                    <Box marginY={2}>
                        <Box container>
                            <Box>
                                <Button onClick={() => setChangePassword(true)}>Change Account Password</Button>
                                {changePassword &&
                                <Box padding={1}>
                                    <ChangePasswordForm
                                        onCancel={(() => setChangePassword(false))}
                                        onSuccess={handlePasswordChangeSuccess}
                                    />
                                </Box>
                                }
                            </Box>

                            <Divider flexItem sx={{ marginY: 3 }} />

                            <Box>
                                <Box>
                                    <Button onClick={fetchMfaDevices} color={'primary'} disabled={isFetchingMfaDevices}>
                                        Show My 2-Step Verification Devices
                                    </Button>
                                    <IconButton onClick={() => openMfaInfoDialog(true)}>
                                        <InfoIcon />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <List>
                                        {isFetchingMfaDevices
                                            ? <Box marginX={4}><CircularProgress size={20} /></Box>
                                            : <Box marginX={1}>
                                                {mfaDevices.map(device => {
                                                    const { id, type, email, phone, status } = device;
                                                    return (
                                                        <React.Fragment key={id}>
                                                            <ListItem disablePadding>
                                                                <Box display={'flex'}>
                                                                    <Box marginRight={2} display={'flex'}
                                                                         alignItems={'center'}>
                                                                        {type?.toUpperCase() === 'SMS' &&
                                                                        <PhoneIcon color={'primary'} />
                                                                        }
                                                                        {type?.toUpperCase() === 'EMAIL' &&
                                                                        <EmailIcon color={'primary'} />
                                                                        }
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography variant={'subtitle1'}>
                                                                            {email || phone}
                                                                        </Typography>
                                                                        <Typography variant={'subtitle2'}
                                                                                    color={'textSecondary'}>
                                                                            ({status})
                                                                        </Typography>
                                                                    </Box>
                                                                    {mfaDevices.length > 1 &&
                                                                    <Box marginLeft={1}>
                                                                        <Tooltip title={'Remove this device'}>
                                                                            <IconButton size={'small'} color={'error'}
                                                                                        onClick={() => setDeviceToDelete(device)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box>
                                                                    }
                                                                </Box>
                                                            </ListItem>
                                                            <Divider sx={{ maxWidth: '500px', marginY: 1 }} />
                                                        </React.Fragment>
                                                    );
                                                })}
                                                {mfaDevices.length > 0 &&
                                                <Stack direction={'row'} spacing={2} marginY={2}>
                                                    <Button onClick={() => setMfaDevices([])}>
                                                        Close
                                                    </Button>
                                                    {mfaDevices.length < 5 &&
                                                    < Button
                                                        startIcon={<AddIcon color={'primary'} />}
                                                        variant={'outlined'} size={'small'}
                                                        onClick={() => setNewMfaDevice(true)}
                                                    >
                                                        Add New MFA Device
                                                    </Button>
                                                    }
                                                </Stack>
                                                }
                                            </Box>
                                        }

                                    </List>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </SettingsPageWrapper>

            <Dialog open={isMfaInfoDialogOpen} onClose={() => openMfaInfoDialog(false)}>
                <DialogContent>
                    <Typography>
                        2-Step verification make your account more secure. On login, you are asked to provide the
                        security
                        code which would be sent to you Email or Phone (SMS).
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => openMfaInfoDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isPassChangeSuccessDialogOpen} onClose={() => openPassChangeSuccessDialog(false)}>
                <DialogContent>
                    <Alert severity={'success'}>
                        Your account's password is updated successfully.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => openPassChangeSuccessDialog(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/*  Device deletion confirmation  */}
            <Dialog open={Boolean(deviceToDelete)}
                    onClose={isDeletingMfaDevice ? undefined : () => setDeviceToDelete(null)}>
                <DialogContent>
                    <Box component={'p'}>
                        <Typography component={'span'}>Are you sure you want to delete</Typography>
                        <Typography component={'strong'} sx={{ backgroundColor: 'yellow' }}>
                            &nbsp;&nbsp;{deviceToDelete?.email ?? deviceToDelete?.phone}&nbsp;&nbsp;
                        </Typography>
                        <Typography component={'span'}>from the list of registered MFA devices?</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Stack direction={'row'} spacing={2}>
                        <Button onClick={() => setDeviceToDelete(null)} disabled={isDeletingMfaDevice}>Cancel</Button>
                        <Button
                            color={'error'} variant={'contained'} onClick={handleMfaDeviceDeletion}
                            disabled={isDeletingMfaDevice}
                            endIcon={isDeletingMfaDevice ? <CircularProgress size={20} color={'inherit'} /> : undefined}
                        >
                            Yes, Delete
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            {/* New Device */}
            {isNewMfaDevice &&
            <NewMfaDevice
                isOpen={true} existingMfaDevices={mfaDevices}
                onClose={() => setNewMfaDevice(false)}
                onSuccess={handleNewDeviceSuccess} />
            }

        </PageContentWrapper>
    )
        ;
}

export default SettingsPage;