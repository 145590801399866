import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Card, CardContent, Collapse, styled, Typography, useTheme} from "@mui/material";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {TransitionGroup} from "react-transition-group";

const DrugAllergiesCardsWrapper = styled(TransitionGroup)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: .5rem;

  ._cardWrapper {
    width: 100%;
  }

  ${({theme}) => ({
    [theme.breakpoints.up("sm")]: {
      "& ._cardWrapper": {
        maxWidth: 450,
        minWidth: 450
      }
    }
  })}
`;

function MyDrugAllergies({drugAllergies}) {
    const theme = useTheme();
    const [showItems, setShowItems] = useState(() => drugAllergies?.length > 2 ? 2 : drugAllergies?.length);

    return (
        <Box>
            <Box marginY={1}>
                <Typography variant={"h6"}>
                     Drug Allergies {drugAllergies?.length ? `(${drugAllergies?.length})` : ""}
                </Typography>
            </Box>
            <Box>
                {drugAllergies?.length > 0
                    ? <>
                        <DrugAllergiesCardsWrapper>
                            {drugAllergies.slice(0, showItems).map((name, i) => {
                                return (
                                    <Collapse key={name + "-" + i} className={"_cardWrapper"}>
                                        <Card >
                                            <CardContent sx={{padding: "1rem 1rem !important"}}>
                                                <Box marginY={1}>
                                                    <Typography variant={"h5"} color={"primary"}
                                                                sx={{wordBreak: "break-all"}}>
                                                        {name}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Collapse>
                                )
                            })}
                        </DrugAllergiesCardsWrapper>
                        {drugAllergies?.length > 2 &&
                        <Box textAlign={"center"}>
                            <Button color={"inherit"} className={"_showMore"}
                                    endIcon={showItems > 2 ? <ArrowUpIcon/> : <ArrowDownIcon/>}
                                    onClick={() => showItems === drugAllergies?.length ? setShowItems(2) : setShowItems(drugAllergies?.length)}>
                                {showItems > 2 ? "Show Less" : `Show All ${drugAllergies.length} Drug Allergies`}
                            </Button>
                        </Box>
                        }
                    </>
                    : <Box>
                        <Card>
                            <CardContent>
                                <Typography color={"error"}>
                                    There are currently no drug allergies assigned to you. If this is incorrect, please
                                    call Nufactor technical support at 1-800-323-6832 to update your profile with a team
                                    member.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </Box>
        </Box>
    );
}

MyDrugAllergies.propTypes = {
    drugAllergies: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MyDrugAllergies;