import React, {useState} from 'react';
import {Alert, Box, Button, Checkbox, CircularProgress, FormControl, Grid, styled, Typography} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import {URN_AUTH, URN_LOGIN, URN_PASSWORD_POLICY} from "../../../../constants/clientURNs";
import Input from "../../../components/Input";
import SuccessIcon from '@mui/icons-material/CheckCircle';
import {Link} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import {VALID_PASSWORD_REGEX, VALID_USERNAME_REGEX} from "../../../../constants/validators";
import axios from "axios";
import {errorExtractor, postOrPutErrorExtractor} from "../../../../utils/httpErrorHelpers";
import {API_URN_PATIENT, API_URN_SIGNUP} from "../../../../constants/apiURNs";
import TermsOfServiceModal from "./TermsOfServiceModal";
import {toast} from "react-toastify";
import SignupStepper from "./SignupStepper";
import {SignupStepOne} from "./SignupStepOne";
import PasswordInput from "../../../components/PasswordInput";

const SuccessWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {
    height: 50px;
    width: 50px;
    fill: ${({theme}) => theme.palette.success.main};
  }
`;

function SignupForm() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [patientData, setPatientData] = useState({
            pid: null,
            secureCodeDeliveryMessage: null,
            firstName: null,
            lastName: null
        }
    );
    const [isTermsServiceModalOpen, openTermsServiceModal] = useState(false);

    const [isCheckingUsernameAvailability, setCheckingUsernameAvailability] = useState(false);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(null); //null is not queried even a single time

    const onVerificationSuccess = (patientData) => {
        const {pid, secureCodeDeliveryMessage, firstName, lastName} = patientData ?? {};
        setPatientData({pid, secureCodeDeliveryMessage, firstName, lastName});
        setActiveStep(prevState => prevState + 1)
    }

    const handleCheckUsernameAvailability = (username) => {
        setCheckingUsernameAvailability(true);
        axios.get(process.env.REACT_APP_API_IDP_ROOT_URL + `/signup/patient/username-availability?username=${username}`)
            .then(({data}) => {
                const {available} = data;
                setIsUsernameAvailable(available);
                setCheckingUsernameAvailability(false);
            }).catch(error => {
                setCheckingUsernameAvailability(false);
                setIsUsernameAvailable(null);
                toast.error(errorExtractor(error))
            }
        )
    }

    return (
        <Box bgcolor={"background.paper"}>
            <Box paddingY={1} textAlign={"center"}>
                <Typography variant={"h5"} component={"h5"}>
                    Create your account
                </Typography>
            </Box>
            <Box width={"100%"} marginY={1}>
                <SignupStepper activeStep={activeStep}/>
            </Box>
            <div className={"_form_wrapper"}>
                {activeStep === 0 && <SignupStepOne onSuccess={onVerificationSuccess}/>}
                {activeStep === 1 &&
                <Formik
                    initialValues={{
                        pid: patientData.pid,
                        securityCode: null,
                        username: "",
                        agreeTerms: false,
                        agreeSmsConsent: "true",
                        agreePhoneConsent: "true",
                        agreeEmailConsent: "true",
                    }}
                    validationSchema={yup.object().shape({
                        pid: yup.string().required("Required"),
                        securityCode: yup.string().required("Required").typeError("Required"),
                        username: yup.string().required("Required").matches(VALID_USERNAME_REGEX, "Minimum 3 letters required. No special character or space allowed.").typeError("Required"),
                        password: yup.string().required("Required").matches(VALID_PASSWORD_REGEX, "Password must be 8 characters or longer, containing: one capital letter, one lower case letter, one number, one special character “~!@#$%^&*()-_=+[]{}|;:,.<>/?”. The password cannot have more than 2 repeated characters.").typeError("Required"),
                        confirmPassword: yup.string().oneOf([yup.ref('password'), ""], 'Passwords must match').required("Required").typeError("Required")
                    })}
                    onSubmit={(values, formikHelpers) => {
                        formikHelpers.setStatus({errorMessage: null});
                        axios.post(process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_SIGNUP + API_URN_PATIENT, values)
                            .then(() => {
                                setActiveStep(3); //anything greater than 2. So that 'Account Created' step is set to checked
                            }).catch(e => {
                                const errorMessage = postOrPutErrorExtractor(e, formikHelpers);
                                formikHelpers.setStatus({errorMessage});
                                formikHelpers.setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          values,
                          handleBlur,
                          isSubmitting,
                          handleSubmit,
                          handleChange,
                          touched,
                          status,
                          errors,
                          setFieldValue, dirty
                      }) => (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Alert severity={"info"}>{patientData.secureCodeDeliveryMessage}</Alert>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{pointerEvents: "none"}}>
                                    <Input
                                        label={"First Name"}
                                        value={patientData?.firstName}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{pointerEvents: "none"}}>
                                    <Input
                                        label={"Last Name"}
                                        value={patientData?.lastName}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    name={"securityCode"} required
                                    label="Security Code"
                                    onChange={handleChange}
                                    value={values.securityCode}
                                    autoComplete={"off"}
                                    error={touched.securityCode && errors.securityCode !== undefined}
                                    helperText={touched.securityCode ? errors.securityCode : ""}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container style={{width: "100%"}}>
                                    <Grid item xs={12} sm={6}>
                                        <Input
                                            name={"username"} required
                                            label="Username"
                                            onChange={(e) => {
                                                setFieldValue("username", e.target.value.toLowerCase());
                                                setIsUsernameAvailable(null);
                                            }}
                                            value={values.username}
                                            autoComplete={"off"}
                                            error={touched.username && errors.username !== undefined}
                                            helperText={touched.username && errors.username ? errors.username : "You will use this username to login to the portal"}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        {isCheckingUsernameAvailability
                                            ? <CircularProgress size={20} color={"primary"} style={{marginLeft: 50}}/>
                                            : (errors.username === undefined && values.username)
                                                ? <Grid container style={{marginLeft: 20}}>
                                                    <Grid item xs={6} sm={12}>
                                                        <Button type={"button"} size={"small"}
                                                                style={{padding: "0 5px"}}
                                                                onClick={() => handleCheckUsernameAvailability(values.username)}>
                                                            Check Availability
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6} sm={12}>
                                                        <Typography sx={{marginLeft: 1}} variant={"caption"}>
                                                            {isUsernameAvailable === null ? "" : isUsernameAvailable ? "Available" : "Not Available"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                : <></>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordInput
                                    name={"password"} id={"password"} required
                                    label="Password" type={"password"}
                                    onChange={handleChange}
                                    value={values.password}
                                    error={touched.password && errors.password !== undefined}
                                    helperText={touched.password ? errors.password : ""}
                                    onBlur={handleBlur}
                                    autoComplete={"new-password"}
                                />
                                {errors.password &&
                                <Box marginTop={1}>
                                    <Typography fontSize={"small"}>
                                        <Link to={URN_PASSWORD_POLICY} target={"_blank"}>Read Password Policy</Link>
                                    </Typography>
                                </Box>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordInput
                                    name={"confirmPassword"} id={"confirmPassword"} required
                                    label="Confirm Password" type={"password"}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    error={touched.confirmPassword && errors.confirmPassword !== undefined}
                                    helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                                    onBlur={handleBlur}
                                    autoComplete={"new-password"}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl size={"small"}>
                                    <FormControlLabel
                                        name={"agreeTerms"}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name={"agreeTerms"}
                                                value={values.agreeTerms}
                                                checked={values.agreeTerms}
                                                onChange={() => openTermsServiceModal(true)}
                                            />
                                        }
                                        label={"By creating an account, you agree to the Terms of Service. For more information about privacy practices, see the Privacy Statement. We'll occasionally send you account-related emails."}
                                        className={"_terms"}
                                    />
                                </FormControl>
                                <TermsOfServiceModal
                                    isOpen={isTermsServiceModalOpen}
                                    onDecline={() => {
                                        setFieldValue("agreeTerms", false);
                                        openTermsServiceModal(false);
                                    }}
                                    onAccept={() => {
                                        setFieldValue("agreeTerms", true);
                                        openTermsServiceModal(false);
                                    }}
                                    isAgreed={values.agreeTerms}
                                />
                            </Grid>

                            {status?.errorMessage &&
                            <Grid item xs={12}>
                                <Alert severity={"error"}>{status.errorMessage}</Alert>
                            </Grid>
                            }

                            {(status?.errorMessage?.includes("password policy")) &&
                            <Grid item xs={12}>
                                <Typography fontSize={"small"}>
                                    <Link to={URN_PASSWORD_POLICY} target={"_blank"}>Read Password Policy</Link>
                                </Typography>
                            </Grid>
                            }

                            <Grid item xs={12}>
                                <Box display={"inline-flex"} width={"100%"}>
                                    <Box width={150} onClick={() => window.location.reload()}>
                                        <Button variant={"outlined"} color={"primary"} disabled={isSubmitting}>
                                            Start Again
                                        </Button>
                                    </Box>
                                    <Box flex={1} textAlign={"right"}>
                                        <Button
                                            variant="contained"
                                            color={"primary"}
                                            onClick={handleSubmit}
                                            disabled={Object.keys(errors).length > 0 || !values.agreeTerms || !dirty || isSubmitting}
                                            type={"submit"}
                                        >
                                            {isSubmitting ?
                                                <CircularProgress color={"inherit"} size={22}/> : "Create Account"}
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
                }
                {activeStep > 1 &&
                <SuccessWrapper>
                    <SuccessIcon/>
                    <Box>
                        <Typography variant='body2'>
                            Congratulations, account created successfully.
                            <Link to={URN_AUTH + URN_LOGIN}><Button>Login</Button></Link>
                        </Typography>

                    </Box>
                </SuccessWrapper>
                }
            </div>

            {activeStep < 1 &&
            <div className={"_actions"}>
                <Box display={"inline-flex"} alignItems={"center"} marginTop={.5}>
                    <Typography color={"textSecondary"}>
                        Already have an account?
                    </Typography>
                    <Button component={Link} to={URN_AUTH + URN_LOGIN}>
                        Login
                    </Button>
                </Box>
            </div>
            }
        </Box>
    );
}

export default SignupForm;