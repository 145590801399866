import {put, takeLatest} from "redux-saga/effects";
import {AUTH, IDP} from "../actions/ActionTypes";
import {STATUS} from "../../constants/idpConstants";
import {
    fetchPatientMinimalDetailsRequest_saga,
    fetchPatientMinimalDetailsRequestSuccess,
    loginSuccess,
    setCurrentPatientDetails
} from "../actions/authActions";
import {
    URN_ADMIN_HOME,
    URN_AUTH,
    URN_HEALTH_SUMMARY,
    URN_LOGIN,
    URN_MINIMAL,
    URN_PATIENTS,
    URN_SIGNUP,
    URN_SUPER_ADMIN_HOME,
    URN_UN_AUTHORIZED
} from "../../constants/clientURNs";
import {setHealthSnapshotBannerVisibility} from "../actions/siteSettingActions";
import {LOCAL_STORAGE_KEYS} from "../../constants/localstorage";
import axios from "axios";
import {toast} from "react-toastify";
import {setAvatarUrl} from "../actions/headerActions";
import {
    FLOW_ID_QP,
    FLOW_ID_TIMEOUT_QP,
    MFA_DEVICE_SELECTION_QP_VAL,
    MFA_OTP_QP_VAL,
    MFA_QP,
    PASSWORD_ONLY_QP,
    PASSWORD_STATUS_QP
} from "../../constants/queryParams";
import {AUTHENTICATED_USER_TYPE} from "../../constants/portalUserType";

function* handleIdpRequestSuccess(actions) {
    const {payload} = actions;
    const {history, data} = payload;
    const {status, flowId, flowIdTimeout, mfaDevices, isAdmin, isSuperAdmin, isPatient, username} = data;
    switch (status) {
        case STATUS.AUTHENTICATED: {
            let role;
            if (isAdmin)
                role = AUTHENTICATED_USER_TYPE.ADMIN
            if (isPatient)
                role = AUTHENTICATED_USER_TYPE.PATIENT
            if (isSuperAdmin)
                role = AUTHENTICATED_USER_TYPE.SUPER_ADMIN
            yield put(loginSuccess(role));
            if (isPatient) {
                yield put(fetchPatientMinimalDetailsRequest_saga());
                yield history.push(URN_HEALTH_SUMMARY);
            } else if (isAdmin)
                yield history.push(URN_ADMIN_HOME);
            else if (isSuperAdmin)
                yield history.push(URN_SUPER_ADMIN_HOME);
            else
                yield history.push(URN_UN_AUTHORIZED);
            break;
        }
        case STATUS.PASSWORD_REQUIRED: {//this asks only for the password and username is handled by session cookie.
            const flowIdTimeout_ = flowIdTimeout - 10;
            const {pathname} = history?.location ?? {}
            if (pathname.indexOf(URN_SIGNUP) === -1) { //protect re-routing if on Signup screen and this action got fired
                history.push({
                    pathname: URN_AUTH + URN_LOGIN,
                    search: `?${PASSWORD_ONLY_QP}=${username}&${FLOW_ID_QP}=${flowId}&${FLOW_ID_TIMEOUT_QP}=${flowIdTimeout_}`,
                });
            }
            break;
        }
        case STATUS.USERNAME_PASSWORD_REQUIRED: {
            //for safer side, decreasing the flow-id timeout so that session don't expire while the login request is getting processed
            const flowIdTimeout_ = flowIdTimeout - 10;
            const {pathname} = history?.location ?? {}
            if (pathname.indexOf(URN_SIGNUP) === -1) { //protect re-routing if on Signup screen and this action got fired
                history.push({
                    pathname: URN_AUTH + URN_LOGIN,
                    search: "?" + FLOW_ID_QP + "=" + flowId + "&" + FLOW_ID_TIMEOUT_QP + "=" + flowIdTimeout_,
                });
            }
            break;
        }
        case STATUS.PASSWORD_EXPIRED:
        case STATUS.MUST_CHANGE_PASSWORD: {
            history.push({
                pathname: URN_AUTH + URN_LOGIN,
                search: `?${PASSWORD_STATUS_QP}=${status}&${FLOW_ID_QP}=${flowId}&${FLOW_ID_TIMEOUT_QP}=${flowIdTimeout}`
            });
            break;
        }
        case STATUS.OTP_REQUIRED: {
            history.push({
                pathname: URN_AUTH + URN_LOGIN,
                search: "?" + MFA_QP + "=" + MFA_OTP_QP_VAL + "&" + FLOW_ID_QP + "=" + flowId,
                state: {mfaDevices}
            });
            break;
        }
        case STATUS.DEVICE_SELECTION_REQUIRED: {
            history.push({
                pathname: URN_AUTH + URN_LOGIN,
                search: "?" + MFA_QP + "=" + MFA_DEVICE_SELECTION_QP_VAL + "&" + FLOW_ID_QP + "=" + flowId,
                state: {mfaDevices}
            });
            break;
        }
        default:
            history.push(URN_AUTH + URN_LOGIN);
            break;
    }
}

function* getPatientMinimalDetails() {
    try {
        const response = yield axios({
            url: URN_PATIENTS + URN_MINIMAL
        })
        const data = yield response.data;

        const {avatarUrl, username, firstname, lastname} = data;
        yield put(setAvatarUrl(avatarUrl));
        yield put(fetchPatientMinimalDetailsRequestSuccess(data));
        yield put(setCurrentPatientDetails(username, firstname + " " + lastname));

        //set health snapshot visibility count in localstorage
        yield setHealthSnapshotBannerVisibility_(data.pid);
    } catch (error) {
        toast("Something went wrong while fetching some of your data, please refresh the page", {type: "warning"});
    }
}

function* setHealthSnapshotBannerVisibility_(loggedInUserId) {
    if (loggedInUserId) {
        //should show welcome banner only first 5 visits of the user. This would be per user per browser
        const localStorage = window.localStorage;
        const bannerSeenCount = localStorage.getItem(LOCAL_STORAGE_KEYS.HEALTH_SNAPSHOT_BANNER_SEEN_COUNT); //null if nothing set
        localStorage.setItem(LOCAL_STORAGE_KEYS.HEALTH_SNAPSHOT_BANNER_SEEN_COUNT, bannerSeenCount ? Object.assign(JSON.stringify({[loggedInUserId]: (parseInt(JSON.parse(bannerSeenCount)[loggedInUserId]) + 1)}), {bannerSeenCount}) : JSON.stringify({[loggedInUserId]: 1}));

        const shouldShow = bannerSeenCount ? JSON.parse(bannerSeenCount)[loggedInUserId] < 10 : true;
        yield put(setHealthSnapshotBannerVisibility(shouldShow));
    }
}

function* authSaga() {
    yield takeLatest(IDP.REQUEST_SUCCESS_SAGA, handleIdpRequestSuccess);
    yield takeLatest(AUTH.FETCH_MINIMAL_DETAILS_SAGA, getPatientMinimalDetails)
}

export default authSaga;