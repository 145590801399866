import React from 'react';
import styled from "styled-components";
import {ReactComponent as PageNotFoundIcon} from "./assets/NotFound.svg"
import {Button, Divider} from "@mui/material";
import {Link} from "react-router-dom";
import {PageContentWrapper} from "./ui/styles/page";

const PageNotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ._illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    svg {
      height: 30vh;
    }
  }

  ._message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

function PageNotFound() {
    return (
        <PageContentWrapper>
            <PageNotFoundWrapper>
                <div className={"_illustration"}><PageNotFoundIcon/></div>
                <div className={"_message"}>
                    <h2 style={{padding: "1rem"}}>PAGE NOT FOUND</h2>
                    <Divider/>
                    <Button component={Link} to={"/"} replace variant={"contained"}>
                        HOME
                    </Button>
                </div>
            </PageNotFoundWrapper>
        </PageContentWrapper>
    );
}

export default PageNotFound;