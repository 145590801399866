import React, { useState } from 'react';
import { PageContentWrapper } from '../../styles/page';
import styled from 'styled-components';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { Box, CircularProgress } from '@mui/material';

const url = 'https://www.nufactor.com/patients/index.html';

const IframeModalWrapper = styled.div`
  background-color: white;
  height: 95%;
  overflow-y: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 680px) {
    margin: 1rem;
    height: calc(100vh - 2rem);
  }
`;

export default function HealthWellness() {
    useScrollToTop();
    const [isLoading, setLoading] = useState(true);

    function handleLoadSuccess() {
        setLoading(false);
    }

    return (
        <PageContentWrapper>
            <IframeModalWrapper>
                {isLoading &&
                <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress color={'primary'} size={30} />
                </Box>
                }
                <iframe src={url} frameBorder={'0'} title={url} onLoad={handleLoadSuccess} />
            </IframeModalWrapper>
        </PageContentWrapper>
    );
} 