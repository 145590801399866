import React from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import {TextField, useTheme} from "@mui/material";
import {formFieldStyleHelper} from "./utils/formFieldStyleHelper";

function DatePickerCustom(props) {
    const {name, id, label, value, onChange, error, helperText, onBlur, required, onError, readOnly, size} = props;
    const theme = useTheme();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label} readOnly={readOnly}
                value={value} id={id}
                onChange={onChange} onBlur={onBlur}
                onError={onError}
                renderInput={(params) =>
                    <TextField
                        variant={"filled"} helperText={helperText} name={name} id={id}
                        error={error} required={required} color={"secondary"}
                        onBlur={onBlur} fullWidth size={size}
                        {...params}
                        sx={formFieldStyleHelper(error, theme)}
                    />
                }
                {...props}
            />
        </LocalizationProvider>
    );
}

DatePickerCustom.defaultProps = {
    size: "small"
}

DatePickerCustom.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    onError: PropTypes.func,
    size: PropTypes.oneOf(["small", "medium"])
};

export default DatePickerCustom;