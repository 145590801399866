import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {Button, Grid, Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Input from "../../components/Input";

function AddressChange({isOpen, onSubmit, data, onCancel}) {
    return (
        <Dialog open={isOpen} onClose={onSubmit} aria-labelledby="form-dialog-title">
            <DialogContent>
                <DialogContentText>
                    <Typography variant={"subtitle1"}>Change shipping address</Typography>
                </DialogContentText>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Input
                                name={""}
                                value={data.street}
                                label={"Street"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                name={""}
                                value={data.city}
                                label={"City"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                name={""}
                                value={data.state}
                                label={"State"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                name={""}
                                value={data.zip}
                                label={"Zip"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                name={""}
                                label={"Country"}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel} color="inherit">
                    Cancel
                </Button>
                <Button onClick={onSubmit}
                        variant="contained"
                        color="primary">
                    Submit
                </Button>
            </DialogActions>

        </Dialog>
    );
}

AddressChange.propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    data: PropTypes.shape({
        street: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string,
        zip: PropTypes.string
    }).isRequired
};

export default AddressChange;