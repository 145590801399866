import React from 'react';
import {Box, Typography} from "@mui/material";
import logo from "../../../assets/patient-portal-logo.png";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

function PasswordPolicyPage() {
    const {isAuthenticated} = useSelector(state => state.auth);

    return (
        <Box padding={2} bgcolor={"background.paper"} marginY={2}>
            {!isAuthenticated &&
            <Box marginY={2}>
                <Box className='_logo'>
                    <Link to={"/"}>
                        <img src={logo} alt='Nufactor'/>
                    </Link>
                </Box>
            </Box>
            }
            <Box marginY={2}>
                <Typography component={"h2"} variant={"h5"}>Password Policy:</Typography>
            </Box>
            <Box component={"ol"} sx={{"& li": {marginY: 1}}}>
                <Box component={"li"}>
                    <Typography>
                        The password will be checked to make sure it doesn't match strings that appear in the user's
                        identity data.
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        The password will be checked to make sure it is not too similar to the user's current password.
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        The password will be checked against a list of most commonly-used passwords. For example: Password@123, Example@123
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        The password cannot have more than 2 repeated characters. For example: Example@111 repeats '1' three times.
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        The password must have a minimum of 5 unique characters.
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        The password must be between 8 and 255 characters.
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        {`The password must have at least 1 of the following characters: ~!@#$%^&*()-_=+[]{}|;:,.<>/?`}
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        The password must have at least 1 of the following characters: 0123456789
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        The password must have at least 1 of the following characters: ABCDEFGHIJKLMNOPQRSTUVWXYZ
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        The password must have at least 1 of the following characters: abcdefghijklmnopqrstuvwxyz
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        [Password update] The password must not be one from the previous 6 passwords used. 6 prior
                        passwords are maintained in the password history count for a maximum of 365 days.
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        [Password expiry] The password will expire every 180 days.
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        [Account lockout] The user's account will be locked out after 5 failed password/security-code attempts.
                    </Typography>
                </Box>
                <Box component={"li"}>
                    <Typography>
                        [Account lockout] The user's account will be automatically unlocked accounts after 30 minutes.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default PasswordPolicyPage;
