import React, {useState} from 'react';
import * as yup from "yup";
import {VALID_EMAIL_REGEX, VALID_PASSWORD_REGEX} from "../../../constants/validators";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    Grid,
    InputAdornment,
    Stack,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import Input from "../../components/Input";
import PasswordInput from "../../components/PasswordInput";
import axios from "axios";
import {errorExtractor, postOrPutErrorExtractor} from "../../../utils/httpErrorHelpers";
import {toast} from "react-toastify";

function NewAdminForm({onSuccess}) {
    const [createdNewUser, setCreatedNewUser] = useState(null); //will hold username and temporaryPassword
    const [isCheckingUsernameAvailability, setCheckingUsernameAvailability] = useState(false);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);

    const handleCheckUsernameAvailability = (username) => {
        setCheckingUsernameAvailability(true);
        axios.get(process.env.REACT_APP_API_IDP_ROOT_URL + `/signup/patient/username-availability?username=${username}`)
            .then(({data}) => {
                const {available} = data;
                setIsUsernameAvailable(available);
                setCheckingUsernameAvailability(false);
            }).catch(error => {
                setCheckingUsernameAvailability(false);
                setIsUsernameAvailable(null);
                toast.error(errorExtractor(error))
            }
        )
    }

    return (
        <>
            <Formik
                initialValues={{
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    temporaryPassword: ""
                }}
                validationSchema={yup.object().shape({
                    firstname: yup.string().trim().required("Required").typeError("Required"),
                    lastname: yup.string().trim().required("Required").typeError("Required"),
                    email: yup.string().trim().required("Required").matches(VALID_EMAIL_REGEX, "Invalid email").typeError("Required"),
                    phone: yup.string().trim().required("Required").typeError("Required"),
                    username: yup.string().required("Required").min(5, "Minimum 5 characters required").typeError("Required"),
                    temporaryPassword: yup.string().required("Required").matches(VALID_PASSWORD_REGEX, "Password must be 8 characters or longer, containing: one capital letter, one lower case letter, one number, one special character “~!@#$%^&*()-_=+[]{}|;:,.<>/?”. The password cannot have more than 2 repeated characters.").typeError("Required"),
                })}
                onSubmit={(values, formikHelpers) => {
                    formikHelpers.setStatus({errorMessage: null});
                    const url = process.env.REACT_APP_API_IDP_ROOT_URL + "/admins";
                    axios.post(url, values, {withCredentials: true})
                        .then(() => {
                            setCreatedNewUser({username: values.username, temporaryPassword: values.temporaryPassword});
                            formikHelpers.setSubmitting(false);
                            formikHelpers.resetForm();
                        })
                        .catch(error => {
                            formikHelpers.setStatus({errorMessage: postOrPutErrorExtractor(error, formikHelpers)});
                            formikHelpers.setSubmitting(false);
                        })
                }}
            >
                {({
                      values,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      errors,
                      status, setStatus,
                      touched,
                      setFieldValue, dirty
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    name={"firstname"} label={"First Name"} required
                                    value={values.firstname}
                                    onChange={handleChange} onBlur={handleBlur}
                                    error={Boolean(touched.firstname && errors.firstname)}
                                    helperText={errors.firstname}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    name={"lastname"} label={"Last Name"} required
                                    value={values.lastname}
                                    onChange={handleChange} onBlur={handleBlur}
                                    error={Boolean(touched.lastname && errors.lastname)}
                                    helperText={errors.lastname}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    name={"email"} label={"Email"} required
                                    value={values.email}
                                    onChange={handleChange} onBlur={handleBlur}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    name={"phone"} label={"Phone"} required
                                    value={values.phone}
                                    onChange={handleChange} onBlur={handleBlur}
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={errors.phone}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    name={"username"} required
                                    label="Username"
                                    onChange={(e) => {
                                        setFieldValue("username", e.target.value.toLowerCase());
                                        setIsUsernameAvailable(null);
                                    }}
                                    value={values.username}
                                    autoComplete={"off"}
                                    error={Boolean(touched.username && errors.username)}
                                    helperText={touched.username && errors.username}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {isCheckingUsernameAvailability
                                    ? <CircularProgress size={20} color={"primary"} style={{marginLeft: 50}}/>
                                    : (errors.username === undefined && values.username)
                                        ? <Grid container style={{marginLeft: 20}}>
                                            <Grid item xs={6} sm={12}>
                                                <Button type={"button"} size={"small"}
                                                        style={{padding: "0 5px"}}
                                                        onClick={() => handleCheckUsernameAvailability(values.username)}>
                                                    Check Availability
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} sm={12}>
                                                <Typography sx={{marginLeft: 1}} variant={"caption"}
                                                            color={isUsernameAvailable ? "inherit" : "error"}>
                                                    {isUsernameAvailable === null ? "" : isUsernameAvailable ? "Available" : "Not Available"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        : <></>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordInput
                                    name={"temporaryPassword"} label={"Temporary Password"} required
                                    value={values.temporaryPassword}
                                    onChange={handleChange} onBlur={handleBlur}
                                    autoComplete={"new-password"}
                                    error={Boolean(touched.temporaryPassword && errors.temporaryPassword)}
                                    helperText={errors.temporaryPassword}
                                />
                                <Alert severity={"info"} sx={{background: "none", padding: 0, marginTop: 1}}>
                                    <Typography color={"primary"} fontSize={"small"}>
                                        Temporary password is a one-time password that is used by the admin to
                                        create their permanent password.
                                    </Typography>
                                </Alert>
                            </Grid>
                        </Grid>
                        {status?.errorMessage &&
                        <Box marginY={2}>
                            <Alert severity={"error"} onClose={() => setStatus({errorMessage: null})}>
                                {status?.errorMessage}
                            </Alert>
                        </Box>
                        }
                        <Box marginY={2}>
                            <Stack direction={"row"} spacing={2}>
                                <Button variant={"contained"} type={"submit"}
                                        endIcon={isSubmitting && <CircularProgress color={"inherit"} size={25}/>}
                                        disabled={!dirty || isSubmitting || Object.keys(errors)?.length > 0}
                                >
                                    Create Admin Account
                                </Button>
                            </Stack>
                        </Box>
                    </form>
                )}
            </Formik>

            {createdNewUser &&
            <Dialog open={true} fullWidth maxWidth={"sm"}>
                <DialogContent>
                    <Alert severity={"success"} sx={{marginY: 2}}>Admin created. Please copy the credentials.</Alert>
                    <Stack direction={"column"} spacing={2}>
                        <Box>
                            <Typography fontWeight={500} color={"textSecondary"}>Username</Typography>
                            <Typography>{createdNewUser?.username}</Typography>
                        </Box>
                        <Box>
                            <Typography fontWeight={500} color={"textSecondary"}>Password</Typography>
                            <Typography>{createdNewUser?.temporaryPassword}</Typography>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSuccess} variant={"outlined"}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            }

        </>
    );
}

export default NewAdminForm;