import React from 'react';
import PropTypes from 'prop-types';
import {Box, CircularProgress, FormControl, FormHelperText, InputLabel, Select, useTheme} from "@mui/material";
import {formFieldStyleHelper} from "./utils/formFieldStyleHelper";

function SelectOutlined(props) {
    const {label, name, onChange, value, style, required, error, helperText, onBlur, size, isLoading} = props;

    const theme = useTheme();

    return (
        <FormControl
            variant="outlined" fullWidth size={size} style={style}
            required={required} error={error} onBlur={onBlur} color={"primary"}
            sx={formFieldStyleHelper(error, theme)}
        >
            <InputLabel id={name} variant={"filled"}>
                {label}
            </InputLabel>
            <Select
                name={name} variant={"filled"} id={name}
                value={value ?? ""}
                onChange={onChange} onBlur={onBlur}
                label={label}
                color={"secondary"}
            >
                {isLoading
                    ? <Box display={"flex"} justifyContent={"center"}>
                        <CircularProgress size={20}/>
                    </Box>
                    : props.children
                }
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

SelectOutlined.defaultProps = {
    size: "small"
}

SelectOutlined.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    onBlur: PropTypes.func,
    size: PropTypes.oneOf(["small", "medium"]),
    isLoading: PropTypes.bool
};

export default SelectOutlined;