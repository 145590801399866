import React, { useEffect } from 'react';
import { PageContentWrapper } from '../../styles/page';
import { Alert, AppBar, Box, Card, CardContent, CircularProgress, styled, Tab, Tabs, Typography } from '@mui/material';
import CareTeamSupportMemberCard from './CareTeamSupportMemberCard';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { CARE_TEAM, PERSONAL_CONTACTS } from '../../../redux/actions/ActionTypes';
import withLoaderAndErrorCard from '../../../hoc/withLoaderAndErrorCard';
import CareTeamEmailSupportForm from './CareTeamEmailSupportForm';
import { SUPPORT_QP, SUPPORT_QP_VAL_EMAIL, SUPPORT_QP_VAL_PHONE } from '../../../constants/queryParams';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '../../../hooks/useQuery';
import CareTeamMemberPhoneDisplay from './CareTeamMemberPhoneDisplay';
import { useTheme } from '@emotion/react';
import PersonContactsCard from './PersonContactsCard';
import ErrorCard from '../../components/ErrorCard';

const MyCareWrapper = styled('div')`
  height: 100%;
  width: 100%;
  position: relative;

  .MuiAppBar-root {
    margin-top: -1rem;
    background-color: ${({ theme }) => theme.palette.background.paper};
    box-shadow: none;
    position: sticky;
    top: ${({ theme }) => theme.mixins.toolbar.minHeight}px;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  ._cardsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 1200px;

    ._cardWrapper {
      width: 100%;
    }

    ${({ theme }) => ({
      [theme.breakpoints.up('sm')]: {
        gap: '1.5rem',
        '& ._cardWrapper': {
          minWidth: 380,
          maxWidth: 380,
        },
      },
      [theme.breakpoints.up('md')]: {
        gap: '2rem',
      },
    })}
  }

  ._personnelContactWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
  }
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function MyCare() {
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState(0);
    useScrollToTop();

    const history = useHistory();
    const location = useLocation();
    const queryParams = useQuery(location.search);
    const isEmailSupport = queryParams.get(SUPPORT_QP) === SUPPORT_QP_VAL_EMAIL;
    const isPhoneSupport = queryParams.get(SUPPORT_QP) === SUPPORT_QP_VAL_PHONE;

    const { currentPatientUsername } = useSelector(state => state?.auth);
    const dispatchAction = useDispatch();

    useEffect(() => {
        currentPatientUsername && fetchCareTeamMembers();
    }, [currentPatientUsername]);

    const fetchCareTeamMembers = () => {
        dispatchAction({ type: CARE_TEAM.FETCH_ALL });
    };
    const fetchAllPersonalContacts = () => {
        dispatchAction({ type: PERSONAL_CONTACTS.FETCH_ALL });
    };

    const { isLoading, error, data } = useSelector((state) => state?.careTeam);
    const personalContactsState = useSelector((state) => state?.personalContacts);
    const { emergencyContacts, nonEmergencyContacts } = personalContactsState.pcdata;

    const careTeamMemberKeys = Object.keys(data);
    /* Getting Emergency and Non Emergency Contacts..*/
    const emergencyContactsKeys = Object.keys(emergencyContacts);
    const nonEmergencyContactsKeys = Object.keys(nonEmergencyContacts);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        fetchAllPersonalContacts();
    };

    const contentJsx = () => (
        <MyCareWrapper>
            <AppBar
                position='static'
                sx={{
                    maxWidth: 600,
                    background: theme.palette.background.paper,
                    zIndex: theme.zIndex.appBar,
                }}
            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor='secondary'
                    textColor='primary'
                    variant='fullWidth'
                    aria-label='full width tabs example'
                >
                    <Tab label='My Care Team' {...a11yProps(0)} />
                    <Tab label='Personal Contacts' {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <Box marginY={4}>
                <TabPanel value={tabValue} index={0}>
                    <Box>
                        <Box className={'_cardsWrapper'}>
                            {/* If no care team member */}
                            {(careTeamMemberKeys?.length < 1 ||
                                careTeamMemberKeys.filter((key) => data[key] !== null)?.length === 0) && (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <Card>
                                        <CardContent>
                                            <Alert severity={'warning'}>
                                                No Care Team members found. Please contact Nufactor
                                                technical support.
                                            </Alert>
                                        </CardContent>
                                    </Card>
                                </Box>
                            )}
                            {careTeamMemberKeys.map((careTeamKey, i) => {
                                const careTeamMember = data[careTeamKey];
                                const { firstName, lastName, email, phoneNumber, role, label } = careTeamMember ?? {};

                                if (firstName && role && label)
                                    return (
                                        <Box key={firstName + i} className={'_cardWrapper'}>
                                            <CareTeamSupportMemberCard
                                                phoneNumber={phoneNumber}
                                                role={role}
                                                label={label}
                                                email={email}
                                                lastName={lastName}
                                                firstName={firstName}
                                            />
                                        </Box>
                                    );
                                else return null;
                            })}
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>

                    {personalContactsState.isLoading
                        ? <Box minHeight={200} width={'100%'} display={'flex'} justifyContent={'center'}
                               alignItems={'center'}>
                            <CircularProgress color={'primary'} size={30} />
                        </Box>
                        : personalContactsState.error
                            ? <Box display={'flex'} justifyContent={'center'}>
                                <ErrorCard title={personalContactsState.error.title}
                                           description={personalContactsState.error.description}
                                           status={personalContactsState.error.status}
                                           onReloadClick={fetchAllPersonalContacts} />
                            </Box>
                            : <React.Fragment>
                                <Box my={3}>
                                    <Typography variant={'h6'} gutterBottom>
                                        Emergency Contact
                                    </Typography>
                                    {(emergencyContactsKeys?.length < 1 ||
                                        emergencyContactsKeys?.filter(
                                            (key) => emergencyContacts[key] !== null,
                                        )?.emergencyContacts === 0) && (
                                        <Box className={'_personnelContactWrapper'}>
                                            <Card>
                                                <CardContent>
                                                    <Alert severity={'warning'}>
                                                        You do not have any emergency contacts saved on the
                                                        Nufactor system. Please call Nufactor technical support at
                                                        1-800-323-6832
                                                    </Alert>
                                                </CardContent>
                                            </Card>
                                        </Box>

                                    )}
                                    {emergencyContactsKeys.map((emergencyContactsKey, i) => {
                                        const emergencyContact = emergencyContacts[emergencyContactsKey];
                                        const { firstName, lastName, phone, email, relationship } =
                                        emergencyContact ?? {};
                                        return (
                                            <PersonContactsCard
                                                key={firstName + i}
                                                firstName={firstName}
                                                lastName={lastName}
                                                phone={phone}
                                                email={email}
                                                relationship={relationship}
                                            />
                                        );
                                    })}
                                </Box>
                                <Box>
                                    <Typography variant={'h6'} gutterBottom>
                                        Other Listed Contacts
                                    </Typography>
                                    {(nonEmergencyContactsKeys?.length < 1 ||
                                        nonEmergencyContactsKeys.filter(
                                            (key) => nonEmergencyContacts[key] !== null,
                                        ).nonEmergencyContacts === 0) && (
                                        <Box className={'_personnelContactWrapper'}>
                                            <Card>
                                                <CardContent>
                                                    <Alert severity={'warning'}>
                                                        You do not have any other contacts saved on the Nufactor
                                                        system.
                                                    </Alert>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    )}
                                    {nonEmergencyContactsKeys.map((nonEmergencyContactsKey, i) => {
                                        const nonEmergencyContact =
                                            nonEmergencyContacts[nonEmergencyContactsKey];
                                        const { firstName, lastName, phone, email, relationship, id } =
                                        nonEmergencyContact ?? {};
                                        return (
                                            <PersonContactsCard
                                                key={firstName + i}
                                                id={id}
                                                firstName={firstName}
                                                lastName={lastName}
                                                phone={phone}
                                                email={email}
                                                relationship={relationship}
                                            />
                                        );
                                    })}
                                </Box>
                            </React.Fragment>
                    }
                </TabPanel>
            </Box>
        </MyCareWrapper>
    );

    return (
        <PageContentWrapper>
            {currentPatientUsername
                ? withLoaderAndErrorCard(contentJsx)({
                    error,
                    isLoading,
                    onReloadClick: fetchCareTeamMembers,
                })
                : null
            }

            {isEmailSupport && (
                <CareTeamEmailSupportForm
                    isOpen={true}
                    onClose={() => history.goBack()}
                />
            )}

            {isPhoneSupport && (
                <CareTeamMemberPhoneDisplay onClose={() => history.goBack()} />
            )}
        </PageContentWrapper>
    );
}

export default MyCare;
