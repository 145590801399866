import {styled} from "@mui/material";

export const PageContentWrapper = styled("div")`
  padding: 1rem;
  flex: 1;
  background: ${({theme}) => theme.palette.background.default};

  ._page_actions {
    width: 100%;
    margin-bottom: 1rem;
    text-align: right;
  }

  ._noInfoWarning {
    font-size: 12px;
    padding: 2px 10px;
    max-width: 500px;

    svg {
      height: 20px;
    }
  }

  @media (max-width: 480px) {
    padding: .5rem;
  }
`;