import { HEADER } from './ActionTypes';

/**
 * @param {string} avatarUrl
 */
export const setAvatarUrl = (avatarUrl) => ({
    type: HEADER.SET_AVATAR_URL,
    payload: {
        avatarUrl,
    },
});

export const setUnreadMessageCount = (count) => ({
    type: HEADER.SET_UNREAD_MESSAGE_COUNT,
    payload: {
        count,
    },
});