import axios from 'axios';
import { API_URN_PRESCRIPTIONS, API_URN_SUPPLIES } from '../constants/apiURNs';

export function fetchAllPrescriptions(username) {
    return axios.get(API_URN_PRESCRIPTIONS + '/' + username);
}

export function fetchAllSupplies(username) {
    return axios.get(API_URN_SUPPLIES + '/' + username);
}

export function orderPrescription(username, rxNumber) {
    return axios.post(API_URN_PRESCRIPTIONS + '/' + username, rxNumber, { headers: { 'Content-Type': 'text/plain' } });
}

export function orderSupplies(username, data) {
    return axios.post(API_URN_SUPPLIES + '/' + username, data);
}