import React, { useEffect, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Avatar,
    Backdrop,
    Badge,
    Box,
    Button,
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    styled,
    Tooltip,
    useTheme,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    URN_ADMIN_HOME,
    URN_AUTH,
    URN_MESSAGE_CENTER,
    URN_PASSWORD_POLICY,
    URN_PROFILE,
    URN_SETTINGS,
    URN_SIGN_OFF,
} from '../../../constants/clientURNs';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess } from '../../../redux/actions/authActions';
import logo from '../../../assets/patient-portal-logo.png';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URN_LOGOUT, API_URN_PATIENT } from '../../../constants/apiURNs';
import { fetchFileBytes } from '../../../api/media';
import { HEADER, RESET } from '../../../redux/actions/ActionTypes';
import DrawerMenu from './DrawerMenu';
import { SIGN_OFF_MESSAGE_QP } from '../../../constants/queryParams';
import InboxIcon from '@mui/icons-material/EmailOutlined';
import { AUTHENTICATED_USER_TYPE } from '../../../constants/portalUserType';

const LoggedInHeaderWrapper = styled("header")`
  width: 100%;
  display: inline-flex;

  button {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  > * {
    display: inline-flex;
    justify-content: center;
  }

  ._section1 {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ._logo {
      display: ${({user_role}) => user_role === AUTHENTICATED_USER_TYPE.SUPER_ADMIN ? "flex" : "none"};
    }

    ${({theme}) => ({
      [theme.breakpoints.up("md")]: {
        "& ._logo": {
          display: "flex"
        },
        "& ._menuToggler": {
          display: "none"
        }
      },
    })},
  }

  ._section2 {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    ._messageCenter {
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiBadge-badge {
        right: 5px;
        top: 5px;
        color: white;
      }

      ._active {
        .MuiSvgIcon-root {
          border-bottom: 3px solid ${({theme}) => theme.palette.secondary.main};
        }
      }
    }

    ._more_option {
      svg {
        font-size: 30px;
      }

      .avatar {
        height: ${({theme}) => theme.spacing(4)};
        width: ${({theme}) => theme.spacing(4)};
      }

      .MuiButton-startIcon, .MuiButton-endIcon {
        margin: 0;
      }
    }
  }
`;

function LoggedInHeader() {
    const theme = useTheme();
    const [isDrawerOpen, openDrawer] = useState(false);
    const [moreOptionAnchor, setMoreOptionAnchor] = React.useState(null);
    const [isLoginOut, setLoginOut] = useState(false);
    const open = Boolean(moreOptionAnchor);
    const history = useHistory();
    const location = useLocation();
    const dispatchAction = useDispatch();

    const {avatarUrl, refreshAvatar, unreadMessageCount} = useSelector(state => state?.header);
    const {currentPatientUsername, role} = useSelector(state => state?.auth);

    const [isFetchingProfileImage, setFetchingProfileImage] = useState(false);
    const [avatarBytes, setAvatarBytes] = useState(null);

    const isSuperAdmin = role === AUTHENTICATED_USER_TYPE.SUPER_ADMIN;
    const isAdmin = role === AUTHENTICATED_USER_TYPE.ADMIN;
    const isAdminHomePage = location?.pathname.includes(URN_ADMIN_HOME);
    const isSettingsPage = location?.pathname.includes(URN_SETTINGS);
    const isPasswordPolicyPage = location?.pathname.includes(URN_PASSWORD_POLICY);

    useEffect(() => {
        refreshAvatar && getImageBytes(avatarUrl)
    }, [avatarUrl, refreshAvatar])

    useEffect(() => {
        dispatchAction({type: HEADER.SET_AVATAR_URL_BYTES, payload: {avatarBytes: avatarBytes}});
    }, [avatarBytes])

    /**
     * The imageURL is a GCS's gsutil URI which is not public. Backend need to respond with the file bytes using this file location
     * @param imageURL the gsutil URI
     */
    const getImageBytes = (imageURL) => {
        if (imageURL) {
            imageURL = encodeURIComponent(avatarUrl);
            setFetchingProfileImage(true);
            fetchFileBytes(imageURL)
                .then(({data}) => {
                    const {bytes} = data;
                    setAvatarBytes("data:image/png;base64, " + bytes);
                    setFetchingProfileImage(false);
                    refreshAvatar && dispatchAction({type: HEADER.REFRESH_AVATAR, payload: {refreshAvatar: false}})
                })
                .catch(() => {
                    setFetchingProfileImage(false);
                    refreshAvatar && dispatchAction({type: HEADER.REFRESH_AVATAR, payload: {refreshAvatar: false}})
                    toast.warn("Failed to fetch your display picture");
                })
        } else {
            setAvatarBytes(null);
            refreshAvatar && dispatchAction({type: HEADER.REFRESH_AVATAR, payload: {refreshAvatar: false}})
        }
    }

    const handleMoreClick = (event) => {
        setMoreOptionAnchor(event.currentTarget);
    };

    const handleMoreClose = () => {
        setMoreOptionAnchor(null);
    };

    const handleLogout = () => {
        setLoginOut(true);
        axios({
            method: "POST",
            baseURL: process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_LOGOUT,
            withCredentials: true
        }).then(() => {
            dispatchAction(logoutSuccess());
            dispatchAction({type: RESET});
            history.push(`${URN_AUTH + URN_SIGN_OFF}?${SIGN_OFF_MESSAGE_QP}=You have been logged out successfully. You can close the window now.`);
        }).catch(() => {
            setLoginOut(false);
            toast("Something went wrong, please try again", {type: "error"})
        })
    }

    //close the menu dropdown on location change
    useEffect(() => {
        setMoreOptionAnchor(null);
    }, [location])

    return (
        <LoggedInHeaderWrapper user_role={role}>
            <Backdrop open={isLoginOut}>
                <CircularProgress color={"secondary"}/>
            </Backdrop>
            <div className="_section1">
                {!isAdminHomePage && !isSuperAdmin &&
                <div className="_menuToggler">
                    <button type={"button"} onClick={() => openDrawer(true)}>
                        <MenuIcon fontSize={"large"} color={"primary"}/>
                    </button>
                </div>
                }
                <div className='_logo'>
                    <Link to={"/"}>
                        <img src={logo} alt='Nufactor'/>
                    </Link>
                </div>
            </div>
            <div className="_section2">
                {/* Admin only */}
                {(!isAdminHomePage && isAdmin && !currentPatientUsername && !isSettingsPage) && !isPasswordPolicyPage &&
                <Box position={"fixed"} top={0} left={"50%"}
                     sx={{
                         transform: "translateX(-50%)",
                         "& .MuiSvgIcon-root": {fontSize: 50},
                         boxShadow: theme.shadows[2],
                         zIndex: 1
                     }}>
                    <Alert severity={"warning"} sx={{maxWidth: 400}}>
                        <AlertTitle>No patient is selected, please select a patient from Admin home page</AlertTitle>
                        <Button component={Link} to={URN_ADMIN_HOME} variant={"outlined"}>Admin Home</Button>
                    </Alert>
                </Box>
                }
                {currentPatientUsername &&
                <div className="_messageCenter">
                    <Badge badgeContent={unreadMessageCount} color={"secondary"} max={9}>
                        <Tooltip title={"Message Center"}>
                            <IconButton component={NavLink} to={URN_MESSAGE_CENTER} activeClassName={"_active"}
                                        color={unreadMessageCount ? "secondary" : "primary"}>
                                <InboxIcon fontSize={"large"}/>
                            </IconButton>
                        </Tooltip>
                    </Badge>
                </div>
                }
                <Divider orientation="vertical" variant="middle" flexItem sx={{mx : 1}}/>
                <div className="_more_option">
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        color={"primary"}
                        onClick={handleMoreClick}
                        startIcon={isFetchingProfileImage ?
                            <CircularProgress color={"primary"} size={20}/>
                            : <Avatar
                                src={avatarBytes}
                                alt={"avatar image"}
                                className={"avatar"}
                            />
                        }
                        endIcon={<ArrowDropDownIcon/>}
                    />

                    <Menu
                        id="header-more-action-menu"
                        aria-controls="header-more-action-menu" aria-haspopup="true"
                        anchorEl={moreOptionAnchor}
                        keepMounted open={open}
                        onClose={handleMoreClose}
                        variant={"selectedMenu"}
                        PaperProps={{
                            style: {
                                minWidth: '20ch',
                                border: `1px solid ${theme.palette.divider}`
                            },
                        }}
                    >
                        {currentPatientUsername &&
                        <MenuItem onClick={() => history.push(URN_PROFILE)}>Profile</MenuItem>
                        }
                        {isAdmin && currentPatientUsername && <Divider/>}
                        {isAdmin && <MenuItem onClick={() => history.push("/")}>Admin Home</MenuItem>}
                        <MenuItem onClick={() => history.push(URN_SETTINGS)}>Settings</MenuItem>
                        <MenuItem onClick={handleLogout} disabled={isLoginOut}>Logout</MenuItem>
                    </Menu>
                </div>
            </div>
            <Drawer open={isDrawerOpen} onClose={() => openDrawer(false)}>
                <DrawerMenu openDrawer={openDrawer}/>
            </Drawer>
        </LoggedInHeaderWrapper>
    );
}

export default LoggedInHeader;