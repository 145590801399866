import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {fetchFileBytes} from "../../api/media";
import {Avatar, CircularProgress} from "@mui/material";
import useIsMounted from "../../hooks/useIsMounted";

/**
 * MUI avatar wrapper to handle the src having private URL.
 * The private URLs cannot be access directly, so the bytes for that resource are fetched from backend using the private url
 */
function AvatarFromBytes({avatarUrl, initials, sx}) {
    const isMounted = useIsMounted();
    const [avatarBytes, setAvatarBytes] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (avatarUrl) {
            getImageBytes(avatarUrl);
        }
    }, [avatarUrl])

    const getImageBytes = (imageURL) => {
        if (imageURL) {
            imageURL = encodeURIComponent(imageURL);
            setIsLoading(true);
            fetchFileBytes(imageURL)
                .then(({data}) => {
                    const {bytes} = data;
                    if (isMounted.current) {
                        setAvatarBytes("data:image/png;base64, " + bytes);
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    if (isMounted.current) {
                        setIsLoading(false);
                    }
                })
        }
    }

    return (
        <Avatar src={avatarBytes} sx={sx}>
            {isLoading ? <CircularProgress size={20} color={"inherit"}/> : initials}
        </Avatar>
    );
}

AvatarFromBytes.propTypes = {
    avatarUrl: PropTypes.string,
    initials: PropTypes.string
};

export default AvatarFromBytes;