import { RESET, SUPPLIES } from '../actions/ActionTypes';

const defaultState = {
    isLoading: false,
    error: null, //object
    data: [],
    cacheExpiryTime: null,
};

export const suppliesReducer = (state = defaultState, action) => {
    const { type, payload } = action;
    
    switch (type) {
        case SUPPLIES.SET_DATA: {
            const { data } = payload;
            return { ...state, data };
        }
        case SUPPLIES.SET_LOADING: {
            const { isLoading } = payload;
            return { ...state, isLoading };
        }

        case SUPPLIES.SET_PAGE_ERROR: {
            const { error } = payload;
            return { ...state, error };
        }

        case SUPPLIES.SET_CACHE_EXPIRY_TIME: {
            const { cacheExpiryTime } = payload;
            return { ...state, cacheExpiryTime };
        }

        case RESET: {
            return { ...defaultState };
        }

        default:
            return { ...state };
    }
};