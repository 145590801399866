import React, {useEffect, useState} from 'react';
import {PageContentWrapper} from "../../styles/page";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import ResultPreview from "./ResultPreview";
import axios from "axios";
import {API_URN_HISTORY, API_URN_RECENT, API_URN_RESULTS} from "../../../constants/apiURNs";
import {CircularProgressWrapper} from "../../styles/loader";
import ErrorCard from "../../components/ErrorCard";
import {errorExtractor, pageErrorExtractor} from "../../../utils/httpErrorHelpers";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchHistorySuccess,
    fetchRecentSuccess,
    loadMoreHistorySuccess,
    loadMoreRecentSuccess,
    setLoadMoreHistoryApiUrl,
    setLoadMoreRecentApiUrl
} from "../../../redux/actions/resultActions";
import {format} from "date-fns";
import { Alert } from '@mui/material';

export default function Results() {
    const [isLoadingRecent, loadingRecent] = useState(false);
    const [recentResultError, setRecentResultError] = useState(null);
    const [isLoadingMoreRecent, loadMoreRecent] = useState(false);

    const [isLoadingHistory, loadingHistory] = useState(false);
    const [resultHistoryError, setResultHistoryError] = useState(null);
    const [isLoadingMoreHistory, loadMoreHistory] = useState(false);

    const [viewResult, setViewResult] = useState(false);

    const dispatchAction = useDispatch();
    const {
        recentResults,
        resultHistory,
        loadMoreHistoryApiUrl,
        loadMoreRecentApiUrl
    } = useSelector(state => state.results);

    useEffect(() => {
        recentResults.length < 1 && fetchRecentResults(0, 5);
        resultHistory.length < 1 && fetchResultHistory(0, 5);
    }, [])

    const fetchRecentResults = (page, size) => {
        loadingRecent(true);
        setRecentResultError(null);
        axios({
            method: "GET",
            url: API_URN_RESULTS + API_URN_RECENT + `?page=${page}&size=${size}`
        }).then(({data}) => {
            const {_embedded, _links} = data;
            const {resultResponseDtoList} = _embedded;
            dispatchAction(fetchRecentSuccess(resultResponseDtoList));
            if (_links.hasOwnProperty("next"))
                dispatchAction(setLoadMoreRecentApiUrl(_links.next.href));
            loadingRecent(false);
        }).catch(error => {
            setRecentResultError(pageErrorExtractor(error));
            loadingRecent(false);
        })
    }

    const fetchResultHistory = (page, size) => {
        loadingHistory(true);
        setResultHistoryError(null);
        axios({
            method: "GET",
            url: API_URN_RESULTS + API_URN_HISTORY + `?page=${page}&size=${size}`
        }).then(({data}) => {
            const {_embedded, _links} = data;
            const {resultResponseDtoList} = _embedded;
            dispatchAction(fetchHistorySuccess(resultResponseDtoList));
            if (_links.hasOwnProperty("next"))
                dispatchAction(setLoadMoreHistoryApiUrl(_links.next.href));

            loadingHistory(false);
        }).catch(error => {
            setResultHistoryError(pageErrorExtractor(error));
            loadingHistory(false);
        })
    }

    const fetchMoreHistory = () => {
        loadMoreHistory(true);
        axios({
            method: "GET",
            baseURL: loadMoreHistoryApiUrl
        }).then(({data}) => {
            const {_embedded, _links} = data;
            const {resultResponseDtoList} = _embedded;
            dispatchAction(loadMoreHistorySuccess(resultResponseDtoList))
            if (_links.hasOwnProperty("next"))
                dispatchAction(setLoadMoreHistoryApiUrl(_links.next.href));
            else
                dispatchAction(setLoadMoreHistoryApiUrl(null));
            loadMoreHistory(false);
        }).catch((error) => {
            toast(errorExtractor(error), {type: "error"});
            loadMoreHistory(false);
        })
    }

    const fetchMoreRecent = () => {
        loadMoreRecent(true);
        axios({
            method: "GET",
            baseURL: loadMoreRecentApiUrl
        }).then(({data}) => {
            const {_embedded, _links} = data;
            const {resultResponseDtoList} = _embedded;
            dispatchAction(loadMoreRecentSuccess(resultResponseDtoList))
            if (_links.hasOwnProperty("next"))
                dispatchAction(setLoadMoreRecentApiUrl(_links.next.href));
            else
                dispatchAction(setLoadMoreRecentApiUrl(null));
            loadMoreRecent(false);
        }).catch((error) => {
            toast(errorExtractor(error), {type: "error"});
            loadMoreRecent(false);
        })
    }

    const tableContentJSX = (result) => {
        const {id, testName, minValue, maxValue, uom, testValue, reviewedBy, testDate} = result ?? {};
        const standardRange = (minValue && maxValue) ? `${minValue} - ${maxValue} ${uom}` : minValue ? `${minValue} ${uom}` : `${maxValue} ${uom}`
        return (
            <TableRow key={result.id}>
                <TableCell>{id}</TableCell>
                <TableCell>{testName}</TableCell>
                <TableCell>{testDate ? format(new Date(testDate), "MMM' 'd' ,'yyyy") : undefined}</TableCell>
                <TableCell>{standardRange}</TableCell>
                <TableCell align={"center"}>{testValue}</TableCell>
                <TableCell
                    style={{minWidth: 100}}>{reviewedBy}</TableCell>
                <TableCell>
                    <Button variant={"outlined"} size={"small"}
                            color={"primary"}
                            onClick={() => setViewResult(true)}>View</Button>
                </TableCell>
            </TableRow>
        )
    }

    const tableHeaderJsx = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell width={100}>Order ID</TableCell>
                    <TableCell>Test</TableCell>
                    <TableCell width={120}>Test Date</TableCell>
                    <TableCell width={150}>Standard Range</TableCell>
                    <TableCell align={"center"} width={100}>Range</TableCell>
                    <TableCell width={200}>Reviewed By</TableCell>
                    <TableCell width={50}>{""}</TableCell>
                </TableRow>
            </TableHead>
        )
    }

    return (
        <PageContentWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant={"h6"}>Recent History</Typography>
                            {isLoadingRecent
                                ? <CircularProgressWrapper height={150}>
                                    <CircularProgress color={"primary"}/>
                                </CircularProgressWrapper>
                                : recentResultError
                                    ? <ErrorCard title={recentResultError.title}
                                                 description={recentResultError.description}
                                                 status={recentResultError.status} onReloadClick={fetchRecentResults}/>
                                    : <TableContainer>
                                        <Table>
                                            {tableHeaderJsx()}
                                            <TableBody>
                                                {recentResults.map(result => tableContentJSX(result))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                            }
                            {isLoadingMoreRecent && <LinearProgress color={"secondary"}/>}
                        </CardContent>
                        {loadMoreRecentApiUrl &&
                        <CardActions style={{justifyContent: "center"}}>
                            <Button variant={"outlined"} size={"small"} onClick={fetchMoreRecent}>
                                Load More
                            </Button>
                        </CardActions>
                        }
                        {(!isLoadingRecent && recentResults.length < 1) &&
                        <Box>
                            <Alert severity={"info"}>No results found</Alert>
                        </Box>
                        }
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant={"h6"}>Results History</Typography>
                            {isLoadingHistory
                                ? <CircularProgressWrapper height={150}>
                                    <CircularProgress color={"primary"}/>
                                </CircularProgressWrapper>
                                : resultHistoryError
                                    ? <ErrorCard title={resultHistoryError.title}
                                                 description={resultHistoryError.description}
                                                 status={resultHistoryError.status}
                                                 onReloadClick={() => fetchResultHistory(0, 5)}/>
                                    : <TableContainer>
                                        <Table>
                                            {tableHeaderJsx()}
                                            <TableBody>
                                                {resultHistory.map(result => tableContentJSX(result))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                            }
                            {isLoadingMoreHistory && <LinearProgress color={"secondary"}/>}
                        </CardContent>
                        {loadMoreHistoryApiUrl &&
                        <CardActions style={{justifyContent: "center"}}>
                            <Button variant={"outlined"} size={"small"} onClick={fetchMoreHistory}>
                                Load More
                            </Button>
                        </CardActions>
                        }
                        {(!isLoadingHistory && resultHistory.length < 1) &&
                        <Box>
                            <Alert severity={"info"}>No results found</Alert>
                        </Box>
                        }
                    </Card>
                </Grid>
            </Grid>

            <ResultPreview isOpen={viewResult} onClose={() => setViewResult(false)}/>
        </PageContentWrapper>
    );
}