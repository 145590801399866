import React, {useState} from 'react';
import styled from "styled-components";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import Input from "../../components/Input";

const ProductDetailWrapper = styled.div`
  //   overflow: auto;
  margin: 0.5rem;
  padding: 0.5rem;
  position: relative;
  background-color: white;
  overflow: hidden;

  ._close {
    position: absolute;
    top: .5rem;
    right: .5rem;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  ._title {
    margin: .5rem 0 1rem 0;
  }

  ._content {
    width: 100%;
    padding: 0.5rem;
    // max-height: 80vh;
    overflow: hidden;

    ._player {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 1rem;

      ._image {
        border-radius: 10px;
      }
    }

    ._description {
      height: 300px;

      h6 {
        margin: 0;
      }

      p {
        margin: 0;
      }

      > * {
        margin-bottom: 1rem;
      }
    }
  }

  :focus {
    outline: none;
  }
`;


const OrdersWrapper = styled.div`
  width: 100%;

  .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color: blue; //Todo
  }
`;

function ProductDetails() {
    const [orders, setOrders] = useState([
        {
            productName: 'Gamunex-C',
            batchNo: "1234567",
            expiryDate: "23 - Dec - 2023",
            quantity: "3",
        }]);

    return (

        <ProductDetailWrapper>
            <div className="_content">
                <div className="_description">
                    <OrdersWrapper>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product Name</TableCell>
                                        <TableCell>Batch no</TableCell>
                                        <TableCell>Expiry Date</TableCell>
                                        <TableCell width={150}>Quantity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders.map((order) => (
                                        <TableRow key={order.productName}>
                                            <TableCell>{order.productName}</TableCell>
                                            <TableCell>{order.batchNo}</TableCell>
                                            <TableCell>{order.expiryDate}</TableCell>
                                            <TableCell>
                                                <Input
                                                    type="number"
                                                    name="quantity"
                                                    value={order.quantity}
                                                />
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </OrdersWrapper>
                </div>
            </div>
        </ProductDetailWrapper>

    );
}


export default ProductDetails;