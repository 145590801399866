import {AUTH, IDP} from "./ActionTypes";

export const loginSuccess = (role) => ({
    type: AUTH.LOGIN_SUCCESS,
    payload: {
        role
    }
})


export const logoutSuccess = () => ({
    type: AUTH.LOGOUT_SUCCESS
})

export const autoLogin = (isAuthenticated) => ({
    type: AUTH.AUTO_LOGIN,
    payload: {isAuthenticated}
})

/**
 *
 * @param data response from IDP
 * @param history the react-router history
 * @returns {{payload: {data, history}, type: string}}
 */
export const idpRequestSuccess_saga = (data, history) => ({
    type: IDP.REQUEST_SUCCESS_SAGA,
    payload: {data, history}
})

export const fetchPatientMinimalDetailsRequest_saga = () => ({
    type: AUTH.FETCH_MINIMAL_DETAILS_SAGA,
})

export const fetchPatientMinimalDetailsRequestSuccess = (data) => ({
    type: AUTH.FETCH_MINIMAL_DETAILS_SUCCESS,
    payload: {data}
})

/**
 * Sets the username of the patient for which the data would be brought in the portal.
 * This is helpful in admin role
 * @param username patient's username
 * @param name patient's full name
 * @returns {{payload: {username}, type: string}}
 */
export const setCurrentPatientDetails = (username, name) => ({
    type: AUTH.SET_ACTIVE_PATIENT_DETAILS,
    payload: {username, name}
})