import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Typography } from '@mui/material';

function PersonContactsCard(props) {
    const { firstName, lastName, phone, email, relationship } = props;

    return (
        <Card sx={{ maxWidth: 600 }}>
            <CardContent>
                <Box width={'100%'} display={'flex'} mb={1}>
                    <Box flex={1}>
                        <Typography color={'textSecondary'}>
                            Name
                        </Typography>
                        <Typography>
                            {firstName} {lastName}
                        </Typography>
                    </Box>
                    <Box flex={1}>
                        <Typography color={'textSecondary'}>
                            Phone
                        </Typography>
                        <Typography>
                            {phone}
                        </Typography>
                    </Box>
                </Box>
                <Box width={'100%'} display={'flex'}>
                    <Box flex={1}>
                        <Typography color={'textSecondary'}>
                            Relation
                        </Typography>
                        <Typography>
                            {relationship}
                        </Typography>
                    </Box>
                    <Box flex={1}>
                        <Typography color={'textSecondary'}>
                            Email
                        </Typography>
                        <Typography>
                            {email}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

PersonContactsCard.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
};

export default PersonContactsCard;
