import React, { useState } from 'react';
import { Box, Drawer, styled } from '@mui/material';
import SideBarNavs from './SideBarNavs';
import DrawerMenu from '../header/DrawerMenu';
import { debounce } from 'debounce';
import { useSelector } from 'react-redux';

const NavigationsWrapper = styled("nav")`
  background: ${({theme}) => theme.palette.background.default};
  display: none;
  ${({theme}) => ({
    [theme.breakpoints.up("md")]: {
      display: "flex",
      minWidth: "80px",
      maxWidth: "80px",
      minHeight: "500px"
    }
  })}
`;

function Navigator() {
    const [isDrawerOpen, openDrawer] = useState(false);
    const {currentPatientUsername} = useSelector(state => state?.auth);
    let shouldOpen = false; //Prevent drawer from opening if the mouse left the box

    const onMouseEnter = () => {
        openDrawer(shouldOpen);
    }

    const handleMouseLeave = () => {
        shouldOpen = false;
    }

    return (
        <NavigationsWrapper>
            {currentPatientUsername
                ? <>
                    <Box
                        position={"fixed"} top={0} left={0} maxHeight={"100vh"} overflow={"auto"}
                        onMouseEnter={() => {
                            shouldOpen = true;
                            debounce(onMouseEnter, 500)()
                        }}
                        onMouseLeave={handleMouseLeave} onClick={handleMouseLeave}
                    >
                        <SideBarNavs/>
                    </Box>
                    <Drawer open={isDrawerOpen} onClose={() => openDrawer(false)}>
                        <DrawerMenu openDrawer={openDrawer}/>
                    </Drawer>
                </>
                : null
            }
        </NavigationsWrapper>
    );
}

export default Navigator;