import React, { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Link,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { PRESCRIPTIONS } from '../../../redux/actions/ActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import withLoaderAndErrorCard from '../../../hoc/withLoaderAndErrorCard';
import { orderPrescription } from '../../../api/prescriptionsSupplies';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlined';
import { postOrPutErrorExtractor } from '../../../utils/httpErrorHelpers';

const PrescriptionCardsWrapper = styled(TransitionGroup)`
  display: block;

  ._cardWrapper {
    width: 100%;
    margin-bottom: 1rem;
  }

  ${({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      '& ._cardWrapper': {
        width: '100%',
        maxWidth: '900px',
      },
    },
  })}
`;

const LIST_ITEM_DEFAULT = 1;

function Prescriptions() {
    const { currentPatientUsername } = useSelector(state => state?.auth);
    const { isLoading, error, data } = useSelector(state => state?.prescriptions);

    const [orderPrescriptionRxNumber, setOrderPrescriptionRxNumber] = useState(null);
    const [orderPrescriptionProcessing, setOrderPrescriptionProcessing] = useState(null);
    const [orderPrescriptionError, setOrderPrescriptionError] = useState(null);
    const [orderPrescriptionSuccess, setOrderPrescriptionSuccess] = useState(false);

    const dispatchAction = useDispatch();

    const fetchPrescriptions = () => {
        dispatchAction({ type: PRESCRIPTIONS.FETCH_ALL_PRESCRIPTIONS });
    };

    useEffect(() => {
        if (currentPatientUsername) fetchPrescriptions();
    }, [currentPatientUsername]);

    const [showItems, setShowItems] = useState(0);

    const dataLength = data?.length;

    useEffect(() => {
        if (dataLength) {
            setShowItems(() => dataLength > LIST_ITEM_DEFAULT ? LIST_ITEM_DEFAULT : dataLength);
        }
    }, [dataLength]);

    function handleRefillPrescription(rxNumber) {
        setOrderPrescriptionError(null);
        setOrderPrescriptionProcessing(true);
        orderPrescription(currentPatientUsername, rxNumber)
            .then(() => {
                setOrderPrescriptionProcessing(false);
                setOrderPrescriptionRxNumber(null);
                setOrderPrescriptionSuccess(true);
            })
            .catch((error) => {
                setOrderPrescriptionProcessing(false);
                setOrderPrescriptionError(postOrPutErrorExtractor(error));
            });
    }

    const contentJsx = () => (
        <Box>
            <Box marginY={2}>
                <Typography variant={'h6'}>
                    Prescriptions {data?.length ? `(${data?.length})` : ''}
                </Typography>
            </Box>
            <Box>
                {data?.length > 0 ? (
                    <>
                        <PrescriptionCardsWrapper>
                            {data
                                .sort((a, b) => (a?.rxDescription < b?.rxDescription ? -1 : 1))
                                .slice(0, showItems)
                                .map((condition, i) => {
                                    const {
                                        rxDescription,
                                        trackingNumber,
                                        dateFilled,
                                        status,
                                        rxNumber,
                                        list,
                                        rxExpiration,
                                    } = condition;

                                    return (
                                        <Collapse key={rxDescription + '-' + i} className={'_cardWrapper'}>
                                            <Card>
                                                <CardContent>
                                                    <Box marginY={1} display={'flex'} alignItems={'Center'}>
                                                        <Box flex={1}>
                                                            <Typography component={'h6'} variant={'h6'}
                                                                        color={'primary'}>
                                                                {rxDescription}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Button variant={'contained'}
                                                                    onClick={() => setOrderPrescriptionRxNumber(rxNumber)}>
                                                                Request Refill
                                                            </Button>
                                                        </Box>
                                                        <Divider sx={{ marginY: 2 }} />
                                                    </Box>
                                                    <Box marginY={1}>
                                                        <Typography color={'textSecondary'}>
                                                            <strong>RX Number</strong> : {rxNumber}
                                                        </Typography>
                                                    </Box>
                                                    <Box marginY={1}>
                                                        <Typography color={'textSecondary'}>
                                                            <strong>Date Filled</strong> : {dateFilled}
                                                        </Typography>
                                                    </Box>
                                                    <Box marginY={1}>
                                                        <Typography color={'textSecondary'}>
                                                            <strong>Status</strong> : {status}
                                                        </Typography>
                                                    </Box>
                                                    <Box marginY={1}>
                                                        <Typography color={'textSecondary'}>
                                                            <strong>List</strong> : {list}
                                                        </Typography>
                                                    </Box>
                                                    <Box marginY={1}>
                                                        <Typography color={'textSecondary'}>
                                                            <strong>Rx Expiration</strong> : {rxExpiration}
                                                        </Typography>
                                                    </Box>
                                                    <Box marginY={1}>
                                                        <Typography color={'textSecondary'}>
                                                            <strong>Tracking Number</strong> :&nbsp;
                                                            <Link
                                                                href='https://www.fedex.com/en-in/tracking.html'
                                                                target={'_blank'}
                                                                rel={'noreferrer'}
                                                            >
                                                                {trackingNumber}
                                                            </Link>
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Collapse>
                                    );
                                })}
                        </PrescriptionCardsWrapper>

                        {data?.length > LIST_ITEM_DEFAULT && (
                            <Box textAlign={'left'}>
                                <Button
                                    color={'inherit'}
                                    className={'_showMore'}
                                    endIcon={showItems > LIST_ITEM_DEFAULT ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                    onClick={() =>
                                        showItems === data?.length
                                            ? setShowItems(LIST_ITEM_DEFAULT)
                                            : setShowItems(data?.length)
                                    }
                                >
                                    {showItems > LIST_ITEM_DEFAULT
                                        ? 'Show Less'
                                        : `Show All ${data.length} Prescriptions`}
                                </Button>
                            </Box>
                        )}

                        <Dialog open={Boolean(orderPrescriptionRxNumber)}>
                            <DialogContent>
                                <Typography variant={'body1'} fontSize={'large'}>
                                    Are you sure you want to refill prescription with
                                    <strong> RX Number : {orderPrescriptionRxNumber}</strong>
                                </Typography>
                                {orderPrescriptionError &&
                                <Alert severity={'error'} sx={{ marginTop: 2 }}>
                                    {orderPrescriptionError}
                                </Alert>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2} justifyContent={'right'} width={'100%'}>
                                    <Button variant={'outlined'}
                                            disabled={orderPrescriptionProcessing}
                                            onClick={() => setOrderPrescriptionRxNumber(null)}>
                                        Cancel
                                    </Button>
                                    <Button variant={'contained'}
                                            onClick={() => handleRefillPrescription(orderPrescriptionRxNumber)}
                                            disabled={orderPrescriptionProcessing}
                                            startIcon={orderPrescriptionProcessing &&
                                            <CircularProgress color={'inherit'} size={20} />}
                                    >
                                        Yes
                                    </Button>
                                </Stack>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={orderPrescriptionSuccess}>
                            <DialogContent>
                                <Stack justifyContent={'center'} width={'100%'} alignItems={'center'}>
                                    <SuccessIcon color={'success'} sx={{ width: 60, height: 60, mb: 2 }} />
                                    <Typography variant={'body1'}>
                                        Order placed successfully
                                    </Typography>
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button variant={'outlined'} fullWidth
                                        onClick={() => setOrderPrescriptionSuccess(false)}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                ) : (
                    <Box>
                        <Card>
                            <CardContent>
                                <Typography color={'error'}>
                                    There are currently no prescriptions assigned to you. If this
                                    is incorrect, please call Nufactor technical support at
                                    1-800-323-6832 to update your profile with a team member.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </Box>
        </Box>
    );

    return (
        currentPatientUsername
            ? withLoaderAndErrorCard(contentJsx)({
                isLoading,
                loadingText: 'Loading Prescriptions...',
                error,
                onReloadClick: fetchPrescriptions,
            })
            : null
    );
}


export default Prescriptions;
