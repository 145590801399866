import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";

function NoInsuranceCard() {
    return (
        <Card sx={{maxWidth: 500}}>
            <CardContent>
                <Typography color={"textSecondary"}>
                    No health insurance information data is attached to your profile in our system. Please contact the
                    Intake/Authorization team at 1-800-323-6832 to verify your health insurance information.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default NoInsuranceCard;