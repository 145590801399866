import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import insuranceSaga from './insuranceSaga';
import careTeamSaga from './careTeamSaga';
import messageCenterSaga from './messageCenterSaga';
import healthSummarySaga from './healthSummarySaga';
import prescriptionsSaga from './prescriptionsSaga';
import infusionJournalSaga from './infusionJournalSaga';
import personalContactsSaga from './personalContactsSaga';
import liveUpdatesSaga from './liveUpdatesSaga';
import suppliesSaga from './suppliesSaga';


export default function* rootSaga() {
    yield all(
        [
            authSaga(),
            insuranceSaga(),
            careTeamSaga(),
            messageCenterSaga(),
            healthSummarySaga(),
            prescriptionsSaga(),
            infusionJournalSaga(),
            personalContactsSaga(),
            liveUpdatesSaga(),
            suppliesSaga(),
        ],
    );
}