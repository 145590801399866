import React from "react";
import PropTypes from "prop-types";
import MainContentModal from "../../components/MainContentModal";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Grid,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
/**
 * import * as yup from "yup";
 */
import {useTheme} from "@mui/styles";

function InfusionJournalViewModal ({isOpen, onClose, data}) {

    const {
        type,
        medicationName,
        medicationDose,
        vialName,
        strength,
        lotNumber,
        painScale,
        sideEffectsTime,
        sideEffects,
        injectionSite,
    } = data;
    const theme = useTheme();
    useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <MainContentModal onClose={onClose} isOpen={isOpen}>


                <DialogContent>
                    <Box sx={{flexgrow : 1}}>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}>
                                {type === 'SIDE_EFFECTS' ? "Side Effect Issue" : type === 'SUPPLIES_CONCERNS' ? "Supply Issue" : type === 'OTHER' ? "Other Issue" : ""}
                            </Typography>
                        </Grid>
                        {type === 'SIDE_EFFECTS' &&
                            (
                                <Grid container spacing={2} sx={{m : 1, p : 1}}>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Side Effects :</Typography>
                                        <Typography variant={"subtitle1"}> {sideEffects.toString()}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Side Effect Time
                                            : </Typography>
                                        <Typography variant={"subtitle1"}> {sideEffectsTime}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Injection Site
                                            :</Typography>
                                        <Typography variant={"subtitle1"}> {injectionSite}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Pain Scale : </Typography>
                                        <Typography variant={"subtitle1"}> {painScale}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {type === 'SUPPLIES_CONCERNS' &&
                            (
                                <Grid container spacing={2} sx={{m : 1, p : 1}}>
                                    <Grid item xs={8}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Supply Issue :</Typography>
                                        <Typography variant={"subtitle1"}> {medicationName}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {type === 'OTHER' &&
                            (
                                <Grid container spacing={2} sx={{m : 1, p : 1}}>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Medication Name
                                            : </Typography>
                                        <Typography variant={"subtitle1"}> {medicationName}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Total Dose :</Typography>
                                        <Typography variant={"subtitle1"}> {medicationDose}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Vial Name : </Typography>
                                        <Typography variant={"subtitle1"}> {vialName}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Vial Strength
                                            : </Typography>
                                        <Typography variant={"subtitle1"}> {strength}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"} color={"primary"}> Lot Number : </Typography>
                                        <Typography variant={"subtitle1"}> {lotNumber}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} spacing={2}>
                        <Button
                            size={"large"}
                            onClick={() => (onClose())}
                            variant={"outlined"}

                        >
                            Close
                        </Button>

                    </Stack>
                </DialogActions>
            </MainContentModal>


        </>
    );
}

InfusionJournalViewModal.propTypes = {
    onClose : PropTypes.func.isRequired,
    isOpen : PropTypes.bool,
};

export default InfusionJournalViewModal;
