export const MFA_QP = "mfa";
export const FLOW_ID_QP = "flowId";
export const FLOW_ID_TIMEOUT_QP = "flowIdTimeout";
export const PASSWORD_ONLY_QP = "passwordOnly";
export const SUPPORT_QP = "support";
export const SIGN_OFF_MESSAGE_QP = "soMessage";
export const PASSWORD_STATUS_QP = "passwordStatus";

//values for query params
export const MFA_DEVICE_SELECTION_QP_VAL = "deviceSelection";
export const MFA_OTP_QP_VAL = "otp";
export const SUPPORT_QP_VAL_EMAIL = "email";
export const SUPPORT_QP_VAL_PHONE = "phone";