import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, Typography, useTheme} from "@mui/material";
import InfusionJournalViewModal from "./InfusionJournalViewModal";
import {format} from "date-fns";


function InfoJournalTileBoard (props) {
    const {
        id,
        type,
        entryDate
    } = props;
    const theme = useTheme();
    const [isOpen, setOpen] = useState(false);
    const entryDateConverted = new Date(entryDate.replace(/-/g, '\/'));
    return (
        <>
            <Card elevation={1}
                  sx={{
                      "&:hover" : {boxShadow : theme.shadows[3]},
                      "& .MuiCardContent-root" : {
                          paddingBottom : 2
                      },
                      "maxHeight" : 100,
                      "cursor" : "pointer",


                  }}
                  onClick={() => setOpen(true)}
            >
                <CardContent display={"flex"} sx={{"paddingBottom" : 1}}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography component={"h6"} variant={"h6"}>
                            <strong>{type === 'SIDE_EFFECTS' ? "Side Effect Issue" : type === 'SUPPLIES_CONCERNS' ? "Supply Issue" : type === 'OTHER' ? "Other Issue" : ""}</strong>
                        </Typography>
                    </Box>
                </CardContent>
                <Box width={"100%"} display={"flex"} justifyContent={"right"}>
                    <Typography variant={"caption"}
                                align="right">{format(entryDateConverted, "MMM/dd/yyyy")}</Typography>
                </Box>
            </Card>
            {isOpen &&
                <InfusionJournalViewModal
                    isOpen={true} onClose={() => setOpen(false)} data={props}
                />
            }
        </>
    );
}

InfoJournalTileBoard.propTypes = {
    id : PropTypes.number,
    type : PropTypes.string,
    entryDate : PropTypes.string,
    treatmentDescription : PropTypes.string,
    medicationName : PropTypes.string,
    medicationDose : PropTypes.string,
    vialName : PropTypes.string,
    strength : PropTypes.string,
    lotNumber : PropTypes.string,
    painScale : PropTypes.number,
    sideEffectsTime : PropTypes.string,
    sideEffects : PropTypes.array,
    injectionSite : PropTypes.array,
    missingSupply:PropTypes.bool,
    defectiveSupply: PropTypes.bool
};

export default InfoJournalTileBoard;