import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MainContentModal from "../../components/MainContentModal";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
  Hidden
} from "@mui/material";
import TextArea                       from "../../components/TextArea";
import SelectOutlined                 from "../../components/SelectOutlined";
import * as yup                       from "yup";
import { Formik }                     from "formik";
import { toast }                      from "react-toastify";
import { useLocation }                from "react-router-dom";
import { useQuery }                   from "../../../hooks/useQuery";
import { fetchCareTeamEmailSubjects } from "../../../api/dropdowns";
import { sendMessageToCareTeam }      from "../../../api/careTeam";
import { postOrPutErrorExtractor }    from "../../../utils/httpErrorHelpers";
import SuccessIcon                    from "@mui/icons-material/CheckCircle";
import { useSelector }                from "react-redux";

function CareTeamEmailSupportForm({ isOpen, onClose }) {
  const location = useLocation();
  const queryParams = useQuery(location.search);
  const careTeamMemberRole = queryParams.get("role");
  const [subjects, setSubjects] = useState([]);
  const [isLoadingSubjects, setLoadingSubjects] = useState(false);
  const [isMessageSent, setMessageSent] = useState(false);
  const [isConfirmationOpen, openConfirmation] = useState(false);
  const {currentPatientUsername} = useSelector(state => state?.auth);

  useEffect(() => {
    isOpen && getSubjectList();
  }, [isOpen]);

  const getSubjectList = () => {
    setLoadingSubjects(true);
    fetchCareTeamEmailSubjects(careTeamMemberRole)
      .then(({ data }) => {
        setSubjects(data);
        setLoadingSubjects(false);
      })
      .catch(() => {
        toast.error("Failed to load subjects. Please try again.");
        setLoadingSubjects(false);
      });
  };

  return (
    <>
      <MainContentModal onClose={onClose} isOpen={isOpen}>
        {careTeamMemberRole === "PHARMACIST" ? (
          <Box paddingX="24px" sx={{ marginY: 2 }}>
            <Alert severity={"info"} variant={"standard"}>
              If this is a medical emergency, call 9-1-1.
            </Alert>
          </Box>
        ) : null}

        <Formik
          initialValues={{
            subject: "",
            body: "",
            direction: "PATIENT_TO_CARE_TEAM",
            role : careTeamMemberRole
          }}
          validationSchema={yup.object().shape({
            subject: yup
              .string()
              .trim()
              .required("Required")
              .typeError("Required"),
            body: yup
              .string()
              .trim()
              .required("Required")
              .typeError("Required"),
          })}
          onSubmit={(values, formikHelpers) => {
            sendMessageToCareTeam(values,currentPatientUsername)
              .then(() => {
                setMessageSent(true);
                formikHelpers.setSubmitting(false);
              })
              .catch((error) => {
                const errorMessage = postOrPutErrorExtractor(
                  error,
                  formikHelpers
                );
                formikHelpers.setStatus({ errorMessage });
                formikHelpers.setSubmitting(false);
              });
          }}
        >
          {({
            values,
            touched,
            handleChange,
            handleSubmit,
            status,
            setStatus,
            isSubmitting,
            errors,
            handleBlur,
            dirty,
          }) => (
            <>
              <DialogContent>
                <form autoComplete={"off"}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectOutlined
                        name={"subject"}
                        required
                        label={"Select a Subject"}
                        value={values.subject}
                        loading={true}
                        onChange={handleChange}
                        style={{ maxWidth: 300 }}
                        onBlur={handleBlur}
                        isLoading={isLoadingSubjects}
                        helperText={errors?.subject}
                        error={
                          touched?.subject &&
                          errors?.subject !== undefined
                        }
                      >
                        {subjects.map((subject) => {
                          const { id, team, description } = subject ?? {};
                          return (
                            <MenuItem key={id + team} value={description}>
                              {description}
                            </MenuItem>

                          );
                        })}
                      </SelectOutlined>
                    </Grid>
                    <Grid item xs={12}>
                      <TextArea
                        name={"body"}
                        required
                        label={"Body"}
                        placeholder={"Please write a message"}
                        rowsMin={5}
                        helperText={errors?.body}
                        value={values.body}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched?.body && errors?.body !== undefined}
                        maxLength={500} //Text Area is fixed to 500 char length.
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          p: 1,
                          m: 1,
                        }}
                      >
                        <Typography variant={"caption"} color={"primary"}>
                          {" "}
                          Characters Remaning : {`${500 - values.body.length}`}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
                {status?.errorMessage && (
                  <Alert
                    severity={"error"}
                    onClose={() => setStatus({ ...status, errorMessage: null })}
                    sx={{ marginY: 2 }}
                  >
                    {status.errorMessage}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    size={"large"}
                    onClick={() => (dirty ? openConfirmation(true) : onClose())}
                    variant={"outlined"}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"contained"}
                    size={"large"}
                    color={"primary"}
                    onClick={handleSubmit}
                    disabled={
                     (!dirty)|| (values.subject != "" && values.body != ""
                        ? false
                        : true) || isSubmitting
                    }
                    endIcon={
                      isSubmitting ? (
                        <CircularProgress color={"inherit"} size={20} />
                      ) : undefined
                    }
                  >
                    {isMessageSent ? "Sent" : "Send"}
                  </Button>
                </Stack>
              </DialogActions>
            </>
          )}
        </Formik>
      </MainContentModal>

      <Dialog open={isOpen && isMessageSent}>
        <DialogContent>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minWidth={200}
          >
            <SuccessIcon fontSize={"large"} color={"success"} />
            <Typography variant={"h6"} color={"success"}>
              Message Sent
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={onClose} variant={"outlined"}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isConfirmationOpen}>
        <DialogContent>
          <Box display={"flex"} alignItems={"center"}>
            <Typography>
              Message was not sent, are you sure you want to close?{" "}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant={"outlined"}>
            Discard Changes
          </Button>
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={() => openConfirmation(false)}
          >
            Continue to send mail
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

CareTeamEmailSupportForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default CareTeamEmailSupportForm;
