import {AUTH, RESET} from "../actions/ActionTypes";

const defaultState = {
    isAuthenticated: false,
    isLoading: true,
    firstname: null,
    lastname: null,
    pid: null,
    username: null,
    role: "UNSET",
    currentPatientUsername: null,
    currentPatientName: null
}

export const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case AUTH.LOGIN_SUCCESS: {
            const {payload} = action;
            const {role} = payload;
            return {...state, isAuthenticated: true, isLoading: false, role};
        }

        case AUTH.LOGOUT_SUCCESS: {
            return {...state, isAuthenticated: false};
        }

        case AUTH.AUTO_LOGIN: {
            return {...state, isAuthenticated: action.payload.isAuthenticated, isLoading: false}
        }

        case AUTH.FETCH_MINIMAL_DETAILS_SUCCESS: {
            const {payload} = action;
            const {pid, firstname, lastname, username} = payload.data;
            return {...state, firstname, lastname, username, pid}
        }

        case AUTH.SET_ACTIVE_PATIENT_DETAILS: {
            const {payload} = action;
            const {username, name} = payload ?? {}
            return {...state, currentPatientUsername: username, currentPatientName: name}
        }

        case RESET: {
            return {...defaultState, isLoading: false}
        }

        default:
            return {...state};
    }
}

