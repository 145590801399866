import { call , put , select , takeLatest }                       from "redux-saga/effects";
import { PERSONAL_CONTACTS }                                      from "../actions/ActionTypes";
import { fetchPersonalContacts , }                                from "../../api/careTeam";
import { setCacheExpiryTime , setData , setError , setLoading , } from "../actions/personalContactsActions";
import { pageErrorExtractor }                                     from "../../utils/httpErrorHelpers";
import { add , isAfter }                                          from "date-fns";
import { AUTHENTICATED_USER_TYPE }                                from "../../constants/portalUserType";

/*
Fetchs mapped personal contacts,
filters and sorts emergency & non emergency contacts.
*/
const getEmergencyEBContacts = ( data ) => {
    const contactsWithEBTemp = data
        .filter (
            ( item ) =>
                ( item.relationshipType === "E" || item.relationshipType === "B" ) == true
        )
        .sort ( ( a , b ) => a.relationshipOrder - b.relationshipOrder );
    return contactsWithEBTemp;
};
const getOtherContactsWithP = ( data ) => {
    const contactsWithPTemp = data
        .filter ( ( item ) => ( item.relationshipType === "P" ) == true )
        .sort ( ( a , b ) => a.relationshipOrder - b.relationshipOrder );
    return contactsWithPTemp;
};
const getOtherContactsWithO = ( data ) => {
    const contactsWithOTemp = data
        .filter ( ( item ) => ( item.relationshipType === "O" ) == true )
        .sort ( ( a , b ) => a.relationshipOrder - b.relationshipOrder );
    return contactsWithOTemp;
};
const getOtherContactsWithMisc = ( data ) => {
    const contactsWithMiscTemp = data
        .filter (
            ( item ) =>
                ( item.relationshipType !== "E" &&
                    item.relationshipType !== "B" &&
                    item.relationshipType !== "O" &&
                    item.relationshipType !== "P" ) == true
        )
        .sort ( ( a , b ) => a.relationshipOrder - b.relationshipOrder );
    return contactsWithMiscTemp;
};

function* handleFetchAllPersonalContacts () {
    const { currentPatientUsername , role } = yield select ( state => state?.auth );
    const { error , cacheExpiryTime } = yield select ( ( state ) => state.personalContacts );
    const isCacheExpired = isAfter ( new Date () , new Date ( cacheExpiryTime ) );

    if (role === AUTHENTICATED_USER_TYPE.ADMIN || error || cacheExpiryTime === null || isCacheExpired) {
        try {
            yield put ( setError ( null ) );
            yield put ( setLoading ( true ) );
            const response = yield call ( fetchPersonalContacts , currentPatientUsername );
            let emergencyContacts = [];
            let nonEmergencyContacts = [];
            const { data } = response;
            //List fo only emergency contacts
            const contactsWithEB = getEmergencyEBContacts ( data );
            //Resitrict to having only one record for Emergency contact. That the type may be E || B
            //according to ranking.
            contactsWithEB.forEach ( ( element ) => {
                if (
                    emergencyContacts.length == 0 &&
                    element.relationshipType === "E" &&
                    element.relationshipOrder === 1
                ) {
                    emergencyContacts.push ( element );
                } else if (
                    emergencyContacts.length == 0 &&
                    element.relationshipType === "B" &&
                    element.relationshipOrder === 1
                ) {
                    emergencyContacts.push ( element );
                } else {
                    nonEmergencyContacts.push ( element );
                }
            } );
            // Creating list with specific LinkType.
            const contactsWithP = getOtherContactsWithP ( data );
            const contactsWithO = getOtherContactsWithO ( data );
            const contactsWithMisc = getOtherContactsWithMisc ( data );

            //Merging all lists to one...
            if (contactsWithP.length > 0) {
                nonEmergencyContacts.push ( ...contactsWithP );
            }
            if (contactsWithO.length > 0) {
                nonEmergencyContacts.push ( ...contactsWithO );
            }
            if (contactsWithMisc.length > 0) {
                nonEmergencyContacts.push ( ...contactsWithMisc );
            }

            // Setting to Action.

            const pcdata = { emergencyContacts , nonEmergencyContacts };
            yield put ( setData ( pcdata ) );
            yield put ( setLoading ( false ) );
            //Set cache expiry time. Cache is valid for 2 hours
            yield put ( setCacheExpiryTime ( add ( new Date () , { hours : 2 } ).toString () ) );
        } catch (error) {
            yield put ( setError ( pageErrorExtractor ( error ) ) );
            yield put ( setLoading ( false ) );
        }

    }


}

function* personalContactsSaga () {
    yield takeLatest ( PERSONAL_CONTACTS.FETCH_ALL , handleFetchAllPersonalContacts );
}

export default personalContactsSaga;
