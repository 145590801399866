import {RESET, RESULTS} from "../actions/ActionTypes";

const defaultState = {
    recentResults: [],
    resultHistory: [],
    loadMoreHistoryApiUrl: null,
    loadMoreRecentApiUrl: null,
}

export const resultsReducer = (state = defaultState, action) => {
    const {payload} = action;

    switch (action.type) {
        case RESULTS.FETCH_RECENT_HISTORY_SUCCESS: {
            const {data} = payload;
            return {...state, recentResults: data};
        }

        case RESULTS.LOAD_MORE_RECENT_HISTORY_SUCCESS: {
            const {data} = payload;
            const updated = state.recentResults.concat(data);
            return {...state, recentResults: updated};
        }
        case RESULTS.FETCH_HISTORY_SUCCESS: {
            const {data} = payload;
            return {...state, resultHistory: data};
        }

        case RESULTS.LOAD_MORE_HISTORY_SUCCESS: {
            const {data} = payload;
            const updated = state.resultHistory.concat(data);
            return {...state, resultHistory: updated};
        }

        case RESULTS.SET_LOAD_MORE_HISTORY_API_URL: {
            const {url} = payload;
            return {...state, loadMoreHistoryApiUrl: url}
        }

        case RESULTS.SET_LOAD_MORE_RECENT_API_URL: {
            const {url} = payload;
            return {...state, loadMoreRecentApiUrl: url}
        }

        case RESET: {
            return {...defaultState}
        }

        default:
            return {...state}
    }
}