import React from 'react';
import PropTypes from 'prop-types';
import {Button, styled} from '@mui/material';

const SelectFileButtonWrapper = styled("div")`
  > * {
    margin: ${({theme}) => theme.spacing(1)};
  }

  input {
    display: none
  }

  .button {
    text-transform: none;
  }
`;

function SelectFileButton({content, accept, endIcon, startIcon, onSelect, multiple}) {

    return (
        <SelectFileButtonWrapper>
            <input
                accept={accept ?? "*/*"}
                id="contained-button-file"
                multiple={multiple}
                type="file"
                onChange={onSelect}
            />
            <label htmlFor="contained-button-file">
                <Button variant="contained" color="secondary" component="span" startIcon={startIcon} endIcon={endIcon}
                        className={"button"}>
                    {content ?? "Select File"}
                </Button>
            </label>
        </SelectFileButtonWrapper>
    );
}

SelectFileButton.propTypes = {
    content: PropTypes.string,
    accept: PropTypes.string, //e.g "image/png, image/jpeg"
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    onSelect: PropTypes.func.isRequired,
    multiple: PropTypes.bool
};

export default SelectFileButton;