import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Backdrop, Modal, Typography} from "@mui/material";
import Icon from "@mdi/react";
import {mdiCloseCircle} from "@mdi/js";

const ResultPreviewWrapper = styled.div`
  overflow: auto;
  margin: 50px;
  padding: 1rem;
  position: relative;
  background-color: white;
  height: 90vh;

  ._close {
    position: absolute;
    top: .5rem;
    right: .5rem;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  ._header {
    display: flex;
    flex-direction: column;

    > * {
      display: flex;
      align-items: center;
    }
  }


  :focus {
    outline: none;
  }
`;

//Todo: Convert to Dialog
function ResultPreview({isOpen, onClose}) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 400,
            }}
        >
            <ResultPreviewWrapper>
                <div className={"_close"}>
                    <button onClick={onClose}><Icon path={mdiCloseCircle} size={1.2}/></button>
                </div>
                <div className="_header">
                    <div>
                        <Typography variant={"subtitle1"}>Lab Order: &nbsp;</Typography>
                        <Typography variant={"subtitle1"} color={"textSecondary"}>102</Typography>
                    </div>
                    <div>
                        <Typography variant={"subtitle1"}>Ordered: &nbsp;</Typography>
                        <Typography variant={"subtitle1"} color={"textSecondary"}>12 Dec, 2020</Typography>
                    </div>
                    <div>
                        <Typography variant={"subtitle1"}>Order By: &nbsp;</Typography>
                        <Typography variant={"subtitle1"} color={"textSecondary"}>Dr. Jasper Stone</Typography>
                    </div>
                </div>
            </ResultPreviewWrapper>
        </Modal>
    );
}

ResultPreview.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};

export default ResultPreview;