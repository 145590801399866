import {HEALTH_SUMMARY} from "./ActionTypes";

export const setLoading = (isLoading) => ({
    type: HEALTH_SUMMARY.SET_LOADING,
    payload: {
        isLoading
    }
})

/**
 * @param {object} error
 */
export const setError = (error) => ({
    type: HEALTH_SUMMARY.SET_PAGE_ERROR,
    payload: {
        error
    }
})

export const setData = (data) => ({
    type: HEALTH_SUMMARY.SET_DATA,
    payload: {
        data
    }
})

export const setCacheExpiryTime = (cacheExpiryTime) => ({
    type: HEALTH_SUMMARY.SET_CACHE_EXPIRY_TIME,
    payload: {
        cacheExpiryTime
    }
})