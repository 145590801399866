import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Formik} from "formik";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";
import Input from "../../components/Input";
import axios from "axios";
import * as yup from "yup";
import {postOrPutErrorExtractor} from "../../../utils/httpErrorHelpers";
import {VALID_EMAIL_REGEX, VALID_NAME_REGEX} from "../../../constants/validators";
import {formatPhoneNumber, validatePhone} from "../../../utils/numberFormator";
import InfoIcon from '@mui/icons-material/InfoOutlined';

function ProfileEditForm({data, onClose, onSuccess, apiURL}) {
    const [isConfirmationOpen, openConfirmation] = useState(false);
    const {
        contactEmail,
        phoneCell,
        phoneHome,
        phoneWork,
        lastname,
        firstname,
        mrn,
        preferredContact,
        profilePicUrl,
    } = data;

    return (
        <>
            <Formik
                initialValues={{
                    firstname, lastname,
                    profilePicUrl, mrn, contactEmail,
                    phoneCell, phoneWork, phoneHome, preferredContact,
                }}
                validationSchema={yup.object().shape({
                    firstname: yup.string().trim().required("Required").min(2, "Minimum 2 characters required").max(50, "Maximum 50 characters allowed").matches(VALID_NAME_REGEX, "Special characters and numeric not allowed").typeError("Required"),
                    lastname: yup.string().trim().required("Required").min(2, "Minimum 2 characters required").max(50, "Maximum 50 characters allowed").matches(VALID_NAME_REGEX, "Special characters and numeric not allowed").typeError("Required"),
                    phoneCell: yup.string().trim().required("Required").test("valid phone", "Must be of format xxx-xxx-xxxx", (value) => validatePhone(value, true)).typeError("Required"),
                    phoneHome: yup.string().trim().test("valid phone", "Must be of format xxx-xxx-xxxx", (value) => validatePhone(value, false)).default(null).nullable().typeError("Invalid Value"),
                    phoneWork: yup.string().trim().test("valid phone", "Must be of format xxx-xxx-xxxx", (value) => validatePhone(value, false)).default(null).nullable().typeError("Invalid Value"),
                    contactEmail: yup.string().trim().matches(VALID_EMAIL_REGEX, "Invalid email"),
                    preferredContact: yup.string().trim().required("Select preferred contact").typeError("Select preferred contact"),
                })}
                onSubmit={(values, formikHelpers) => {
                    formikHelpers.setStatus(null);
                    axios({
                        method: "PUT",
                        baseURL: apiURL,
                        data: values
                    }).then(({data}) => {
                        onSuccess(data);
                    }).catch(error => {
                        formikHelpers.setStatus({error: postOrPutErrorExtractor(error, formikHelpers)});
                        formikHelpers.setSubmitting(false);
                    })
                }}
            >
                {({
                      values,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      errors,
                      status, setStatus,
                      touched,
                      setFieldValue, dirty
                  }) => (
                    <>
                        <DialogContent>
                            <form autoComplete={"off"} onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Input
                                            name={"firstname"} required
                                            label={"First Name"} autoComplete={"off"}
                                            value={values.firstname}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.firstname}
                                            error={touched.firstname && errors.firstname !== undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Input
                                            name={"lastname"} required
                                            label={"Last Name"} autoComplete={"off"}
                                            value={values.lastname}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.lastname}
                                            error={touched.lastname && errors.lastname !== undefined}
                                        />
                                    </Grid>
                                </Grid>

                                <Box marginY={2}>
                                    <Typography color={"textSecondary"} component={"h5"} variant={"h6"}
                                                sx={{marginBottom: 1}}>
                                        Contact Information</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <Input
                                                name={"phoneCell"} required
                                                label={"Phone(Primary)"}
                                                value={values.phoneCell}
                                                onChange={(e) => setFieldValue("phoneCell", formatPhoneNumber(e?.target?.value?.trim()))}
                                                onBlur={handleBlur}
                                                helperText={errors.phoneCell}
                                                error={touched.phoneCell && errors.phoneCell !== undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Input
                                                name={"phoneHome"}
                                                label={"Phone(Home)"}
                                                value={values.phoneHome}
                                                onChange={(e) => setFieldValue("phoneHome", formatPhoneNumber(e?.target?.value?.trim()))}
                                                onBlur={handleBlur}
                                                helperText={errors.phoneHome}
                                                error={touched.phoneHome && errors.phoneHome !== undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Input
                                                name={"phoneWork"}
                                                label={"Phone(Work)"}
                                                value={values.phoneWork}
                                                onChange={(e) => setFieldValue("phoneWork", formatPhoneNumber(e?.target?.value?.trim()))}
                                                onBlur={handleBlur}
                                                helperText={errors.phoneWork}
                                                error={touched.phoneWork && errors.phoneWork !== undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Input
                                                name={"contactEmail"} readOnly
                                                label={"Email"}
                                                value={values.contactEmail}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={errors.contactEmail}
                                                error={touched.contactEmail && errors.contactEmail !== undefined}
                                            />
                                            <Box display={"flex"} alignItems={"flex-start"} gap={.5} marginTop={"5px"}>
                                                <InfoIcon color={"primary"} fontSize={"small"}/>
                                                <Typography color={"primary"} variant={"caption"}>
                                                    If you need to change your email on file with Nufactor and the
                                                    Patient Portal, please call Nufactor technical support at
                                                    1-800-323-6832.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginY={2}>
                                    <FormControl component="fieldset" style={{padding: "0 .5rem"}}
                                                 required
                                                 error={errors.preferredContact !== undefined}>
                                        <FormLabel>
                                            Preferred Contact
                                        </FormLabel>
                                        <RadioGroup row name={"preferredContact"} value={values.preferredContact}
                                                    onChange={handleChange}>
                                            <FormControlLabel value="email" control={<Radio/>} label="Email"/>
                                            <FormControlLabel value="phone" control={<Radio/>} label="Phone"/>
                                            <FormControlLabel value="text" control={<Radio/>} label="Text"/>
                                        </RadioGroup>
                                        {errors.preferredContact &&
                                        <Typography color={"error"}
                                                    variant={"caption"}>{errors.preferredContact}</Typography>
                                        }
                                    </FormControl>
                                </Box>

                                {status && status.error &&
                                <Alert severity={"error"} onClose={() => setStatus({...status, error: null})}>
                                    {status.error}
                                </Alert>
                                }
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={"row"} spacing={2} justifyContent={"right"}>
                                <Button onClick={() => dirty ? openConfirmation(true) : onClose()}
                                        disabled={isSubmitting} variant={"outlined"}>
                                    Cancel
                                </Button>
                                <Button color={"primary"} variant={"contained"} type={"submit"}
                                        disabled={!dirty || isSubmitting || Object.keys(errors)?.length > 0}
                                        onClick={handleSubmit}>
                                    {isSubmitting ? <CircularProgress color={"secondary"} size={25}/> : "Update"}
                                </Button>
                            </Stack>
                        </DialogActions>
                    </>
                )}
            </Formik>

            <Dialog open={isConfirmationOpen}>
                <DialogContent>
                    <Typography>
                        You have some unsaved changes, are you sure you wanna close?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant={"outlined"}>Discard Changes</Button>
                    <Button color={"primary"} variant={"contained"} onClick={() => openConfirmation(false)}>
                        Continue Editing
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

ProfileEditForm.propTypes = {
    apiURL: PropTypes.string.isRequired,
    data: PropTypes.shape({
        firstname: PropTypes.string.isRequired,
        middlename: PropTypes.string,
        lastname: PropTypes.string.isRequired,
        profilePicUrl: PropTypes.string,
        addresses: PropTypes.arrayOf(PropTypes.shape({
            street: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zip: PropTypes.string,
            country: PropTypes.string,
            isPrimary: PropTypes.bool
        })),
        emergencyContact: PropTypes.shape({
            name: PropTypes.string,
            phone: PropTypes.string,
            email: PropTypes.string,
            relation: PropTypes.string
        }),
        phone: PropTypes.string,
        contactEmail: PropTypes.string,
        preferredContact: PropTypes.string
    }).isRequired,
    /**
     * takes the updated details
     */
    onSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default ProfileEditForm;
