import React from 'react';
import {PageContentWrapper} from "./ui/styles/page";
import {Alert, AlertTitle, Box, Card, CardContent} from "@mui/material";

function UnAuthorizedPage() {
    return (
        <PageContentWrapper>
            <Box display={"flex"} justifyContent={"center"}>
                <Card sx={{minWidth: 350, maxWidth: 400, "& .MuiSvgIcon-root": {fontSize: 50}}}>
                    <CardContent>
                        <Alert severity={"warning"}>
                            <AlertTitle>Access Denied</AlertTitle>
                            You are not authorized to access this page
                        </Alert>
                    </CardContent>
                </Card>
            </Box>
        </PageContentWrapper>
    );
}

export default UnAuthorizedPage;