export const URN_AUTH = Object.freeze("/auth");
export const URN_LOGIN = Object.freeze("/login");
export const URN_SIGN_OFF = Object.freeze("/signOff");
export const URN_SIGNUP = Object.freeze("/signup");
export const URN_PROFILE = Object.freeze("/profile");
export const URN_HEALTH_SUMMARY = Object.freeze("/health-summary");
export const URN_PRESCRIPTION = Object.freeze("/prescription");
export const URN_RESULTS = Object.freeze("/results");
export const URN_INSURANCE = Object.freeze("/insurance");
export const URN_MY_ORDERS = Object.freeze("/orders");
export const URN_HEALTH_WELLNESS = Object.freeze("/health-wellness");
export const URN_SCHEDULE = Object.freeze("/schedule");
export const URN_EDUCATION_TRAINING = Object.freeze("/education-training");
export const URN_INFUSION_JOURNAL = Object.freeze("/infusion-journal");
export const URN_MY_CARE = Object.freeze("/my-care");
export const URN_ME = Object.freeze("/me");
export const URN_PATIENTS = Object.freeze("/patients");
export const URN_MINIMAL = Object.freeze("/minimal");
export const URN_SETTINGS = Object.freeze("/settings");
export const URN_PASSWORD_POLICY = Object.freeze("/password-policy");
export const URN_MESSAGE_CENTER = Object.freeze("/message-center");
export const URN_ADMIN_HOME = Object.freeze("/admin-home");
export const URN_SUPER_ADMIN_HOME = Object.freeze("/super-admin-home");
export const URN_UN_AUTHORIZED = Object.freeze("/un-authorized");

