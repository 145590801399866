import React , { useEffect , useState } from "react";
import PropTypes                        from 'prop-types';
import {Formik} from "formik";
import * as yup from "yup";
import axios from "axios";
import {API_URN_REQUEST_PATIENT_MRN_DOB, API_URN_SIGNUP} from "../../../../constants/apiURNs";
import {postOrPutErrorExtractor} from "../../../../utils/httpErrorHelpers";
import {Alert, Box, Button, CircularProgress, Grid} from "@mui/material";
import Input from "../../../components/Input";
import {format,parse, isDate} from "date-fns";
import DatePickerCustom from "../../../components/DatePickerCustom";

export function SignupStepOne({onSuccess}) {
    let dateVal = new Date();
    function checkMinimumDate(value, originalValue) {
        if (originalValue === null) {
            dateVal = isDate ( originalValue )
                      ? originalValue
                      : parse ( originalValue , "MM/dd/yyyy" , new Date () );

        }
        else {
            let minValue = parse ( '01/01/1900' , "MM/dd/yyyy" , new Date () );
            let convertedValue = parse ( originalValue , "MM/dd/yyyy" , new Date () );
            const isMinimum = (
                convertedValue < minValue
            );
            dateVal = isMinimum
                      ? parse ( '01/01/1899' , "MM/dd/yyyy" , new Date () )
                      : new Date ( originalValue );

        }
        return new Date ( dateVal )
    }
    return <Formik
        initialValues={{
            dob: null,
            mrn: null,
        }}
        validationSchema={yup.object().shape({
            dob: yup.date().required("Required").default(null).transform(checkMinimumDate).max(new Date(), "Must be earlier than today").min(new Date(1900, 0, 1),"Invalid date. Must be in mm/dd/yyyy format, earlier than today and year must be later than year 1900").typeError("Invalid date. Must be in mm/dd/yyyy format, earlier than today and year must be later than year 1900"),
            mrn: yup.string().required("Required").typeError("Required"),
        })}
        onSubmit={(values, formikHelpers) => {
            formikHelpers.setStatus({errorMessage: null});
            axios.post(process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_SIGNUP + API_URN_REQUEST_PATIENT_MRN_DOB, values)
                .then(({data}) => {
                    onSuccess(data);
                }).catch(e => {
                    const errorMessage = postOrPutErrorExtractor(e, formikHelpers);
                    formikHelpers.setStatus({errorMessage});
                    formikHelpers.setSubmitting(false);
                }
            )
        }}
    >
        {({
              values, status,
              handleChange,
              handleSubmit,
              isSubmitting,
              errors,
              touched, setFieldTouched,
              dirty, setFieldValue, handleBlur
          }) => (
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} maxWidth={400}>
                <Box component={"form"} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity={"info"}>
                                If you don’t know your MRN number, please call Nufactor technical support at
                                1-800-323-6832.
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                name={"mrn"} required
                                label="MRN"
                                onChange={handleChange}
                                value={values.mrn}
                                error={touched.mrn && errors.mrn !== undefined}
                                helperText={touched.mrn ? errors.mrn : ""}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePickerCustom
                                name={"dob"} id={"dob"}
                                label={"Date of Birth"} required
                                value={values.dob}
                                maxDate={new Date()}
                                minDate={new Date(1900, 0, 1)}
                                onChange={(value) => {
                                    setFieldTouched("dob", true);
                                    if (value) {
                                        if (value.toString().toLowerCase() !== "invalid date") {
                                            const selectedDob = new Date(value);
                                            setFieldValue("dob", format(selectedDob, "MM/dd/yyyy"));
                                        } else {
                                            setFieldValue("dob", "Invalid Date");
                                        }
                                    } else {
                                        setFieldValue("dob", null);
                                    }
                                }}
                                onError={() => setFieldValue("dob", null)}
                                onBlur={handleBlur}
                                helperText={touched?.dob && errors?.dob}
                                error={touched?.dob && errors?.dob !== undefined}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {status?.errorMessage &&
                <Box marginY={2}>
                    <Alert severity={"error"}>{status.errorMessage}</Alert>
                </Box>
                }

                <Box textAlign={"right"} marginY={2}>
                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={handleSubmit}
                        disabled={Object.keys(errors).length > 0 || !dirty || isSubmitting}
                        type={"submit"}
                    >
                        {isSubmitting ? <CircularProgress color={"secondary"} size={22}/> : "Next"}
                    </Button>
                </Box>
            </Box>
        )}
    </Formik>
}

SignupStepOne.propTypes = {
    onSuccess: PropTypes.func.isRequired
}

