import { Route, Switch } from 'react-router-dom';
import { Box, LinearProgress, styled, ThemeProvider } from '@mui/material';
import Header from './ui/containers/header';
import PrivateRoute from './PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import {
    URN_ADMIN_HOME,
    URN_AUTH,
    URN_HEALTH_SUMMARY,
    URN_HEALTH_WELLNESS,
    URN_INFUSION_JOURNAL,
    URN_INSURANCE,
    URN_MESSAGE_CENTER,
    URN_MY_CARE,
    URN_MY_ORDERS,
    URN_PASSWORD_POLICY,
    URN_PRESCRIPTION,
    URN_PROFILE,
    URN_RESULTS,
    URN_SETTINGS,
    URN_SUPER_ADMIN_HOME,
    URN_UN_AUTHORIZED,
} from './constants/clientURNs';
import AuthPage from './ui/containers/auth/AuthPage';
import PageNotFound from './PageNotFound';
import Navigator from './ui/containers/navigations';
import Profile from './ui/containers/profile';
import HealthSnapshot from './ui/containers/healthSummary';
import PrescriptionsPage from './ui/containers/prescriptionsAndSupplies';
import Insurance from './ui/containers/insurance';
import MyOrders from './ui/containers/myOrders';
import HealthWellness from './ui/containers/healthWellness';
import MyCare from './ui/containers/myCare';
import Results from './ui/containers/results';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import Home from './ui/containers/home';
import InfusionJournal from './ui/containers/infusionJournal';
import { GlobalStyle, muiCustomTheme } from './styleOverrides';
import Footer from './ui/containers/footer';
import SettingsPage from './ui/containers/settings';
import PasswordPolicyPage from './ui/containers/passwordPolicy';
import MessageCenterPage from './ui/containers/messageCenter';
import AdminHomePage from './ui/containers/admin/AdminHomePage';
import UnAuthorizedPage from './UnAuthorizedPage';
import React, { useEffect } from 'react';
import CurrentPatientDrawer from './ui/containers/admin/CurrentPatientDrawer';
import { AUTHENTICATED_USER_TYPE } from './constants/portalUserType';
import SuperAdminHomePage from './ui/containers/admin/SuperAdminHomePage';
import { LIVE_UPDATES } from './redux/actions/ActionTypes';

/************ Stack-driver config start ***********/
const StackdriverErrorReporter = require('stackdriver-errors-js/dist/stackdriver-errors-concat.min.js');
const errorReporter = new StackdriverErrorReporter();
errorReporter.start({
    key: process.env.REACT_APP_STACKDRIVER_KEY,
    projectId: process.env.REACT_APP_GCP_PROJECT_ID,
});
/************ Stack-driver config ends ***********/

const MainContentWrapper = styled(Box)`
  height: 100%;
  display: block;
  ${({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  })}
`;

export default function App() {
    const { isAuthenticated, isLoading, role, currentPatientUsername } = useSelector(state => state?.auth);
    const isSuperAdmin = role === AUTHENTICATED_USER_TYPE.SUPER_ADMIN;
    const dispatchAction = useDispatch();

    useEffect(() => {
        if (isAuthenticated && currentPatientUsername)
            dispatchAction({ type: LIVE_UPDATES.SUBSCRIBE_CARE_TEAM_MESSAGE_UNREAD });
    }, [isAuthenticated, currentPatientUsername]);

    return isLoading
        ? <LinearProgress />
        : <ThemeProvider theme={muiCustomTheme}>
            <GlobalErrorBoundary errorReporter={errorReporter}>
                <GlobalStyle />
                {isAuthenticated && <Header />}
                <ToastContainer position={'bottom-center'} hideProgressBar={true} closeOnClick={true} />
                {role === AUTHENTICATED_USER_TYPE.ADMIN && <CurrentPatientDrawer />}
                <MainContentWrapper>
                    {isAuthenticated && <Navigator />}
                    <Box flex={1} height={'100%'} display={'flex'} flexDirection={'column'}>
                        <Switch>
                            <Route exact path={URN_AUTH + '/:id'} component={AuthPage} />
                            <Route exact path={URN_PASSWORD_POLICY} component={PasswordPolicyPage} />
                            <PrivateRoute exact path={'/'} component={Home}
                                          isAuthenticated={isAuthenticated} />
                            <PrivateRoute path={URN_SETTINGS} component={SettingsPage}
                                          isAuthenticated={isAuthenticated} />
                            <PrivateRoute path={URN_SUPER_ADMIN_HOME} component={SuperAdminHomePage}
                                          isAuthenticated={isAuthenticated} />
                            <PrivateRoute path={URN_ADMIN_HOME} component={AdminHomePage}
                                          isAuthenticated={isAuthenticated} />

                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_HEALTH_SUMMARY} component={HealthSnapshot}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_PROFILE} component={Profile}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_PRESCRIPTION} component={PrescriptionsPage}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_RESULTS} component={Results}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_INSURANCE} component={Insurance}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute exact path={URN_MY_ORDERS} component={MyOrders}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute exact path={URN_MY_ORDERS + '/:id'} component={MyOrders}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_HEALTH_WELLNESS} component={HealthWellness}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_MY_CARE} component={MyCare}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_INFUSION_JOURNAL} component={InfusionJournal}
                                          isAuthenticated={isAuthenticated} />
                            }
                            {!isSuperAdmin &&
                            <PrivateRoute path={URN_MESSAGE_CENTER} component={MessageCenterPage}
                                          isAuthenticated={isAuthenticated} />
                            }

                            <PrivateRoute exact path={URN_UN_AUTHORIZED} component={UnAuthorizedPage}
                                          isAuthenticated={isAuthenticated} />

                            <Route component={PageNotFound} />
                        </Switch>
                        {isAuthenticated && <Footer />}
                    </Box>
                </MainContentWrapper>
            </GlobalErrorBoundary>
        </ThemeProvider>
        ;
}
