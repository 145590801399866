import { call , put , select , takeLatest } from "redux-saga/effects";
import { CARE_TEAM } from "../actions/ActionTypes";
import { fetchCareTeamMembers , } from "../../api/careTeam";
import { setCacheExpiryTime , setCareTeamMembers , setError , setLoading , } from "../actions/careTeamActions";
import { pageErrorExtractor } from "../../utils/httpErrorHelpers";
import { add , isAfter } from "date-fns";
import { AUTHENTICATED_USER_TYPE } from "../../constants/portalUserType";

function* handleFetchAllCareTeamMembers() {
  const {currentPatientUsername, role} = yield select(state => state?.auth);
  const { error, cacheExpiryTime } = yield select((state) => state.careTeam);
  const isCacheExpired = isAfter(new Date(), new Date(cacheExpiryTime));
  if (role === AUTHENTICATED_USER_TYPE.ADMIN || error || cacheExpiryTime === null || isCacheExpired) {
    try {
      yield put(setError(null));
      yield put(setLoading(true));
      const response = yield call(fetchCareTeamMembers, currentPatientUsername);
      const data = yield response.data;
      yield put(setCareTeamMembers(data));
      yield put(setLoading(false));
      //Set cache expiry time. Cache is valid for 2 hours
      yield put(setCacheExpiryTime(add(new Date(), { hours: 2 }).toString()));
    } catch (error) {
      yield put(setError(pageErrorExtractor(error)));
      yield put(setLoading(false));
    }
  }
}



function* careTeamSaga() {
  yield takeLatest(CARE_TEAM.FETCH_ALL, handleFetchAllCareTeamMembers);
}

export default careTeamSaga;
