import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from "@mui/material/FormLabel";
import {FormHelperText, TextareaAutosize} from "@mui/material";
import FormControl from "@mui/material/FormControl";

function TextArea(props) {
    const {
        className,
        id,
        required,
        label,
        name,
        onChange,
        rowsMax,
        rowsMin,
        style,
        value,
        textareaRef,
        placeholder,
        disabled,
        error,
        helperText,
        onBlur,
        maxLength
    } = props;

    return (
        <FormControl fullWidth error={error} onBlur={onBlur} disabled={disabled} required={required}>
            {label && <FormLabel>{label}</FormLabel>}
            <TextareaAutosize
                id={id}
                name={name}
                minRows={rowsMin}
                maxRows={rowsMax}
                aria-label="maximum height"
                value={value ?? ""}
                onChange={onChange}
                style={style}
                className={className}
                ref={textareaRef}
                placeholder={placeholder}
                maxLength = {maxLength}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

TextArea.defaultProps = {
    style: {width: "100%"}
}

TextArea.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    rows: PropTypes.number,
    rowsMin: PropTypes.number,
    rowsMax: PropTypes.number,
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    textareaRef: PropTypes.object,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    onBlur: PropTypes.func,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number
};

export default TextArea;