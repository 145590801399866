import {RESET, SITE_SETTINGS} from "../actions/ActionTypes";

const defaultState = {
    shouldBannerVisible: false
}

export const siteSettingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SITE_SETTINGS.HEALTH_SNAPSHOT_BANNER_VISIBLE: {
            const {payload} = action;
            const {shouldShow} = payload;
            return {...state, shouldBannerVisible: shouldShow};
        }

        case RESET: {
            return {...defaultState}
        }

        default:
            return {...state};
    }
}