export const API_URN_LOGIN = Object.freeze("/login");
export const API_URN_AUTH_STATUS = Object.freeze("/auth-status");
export const API_URN_SIGNUP = Object.freeze("/signup");
export const API_URN_LOGOUT = Object.freeze("/logout");
export const API_URN_RESULTS = Object.freeze("/results");
export const API_URN_RECENT = Object.freeze("/recent");
export const API_URN_HISTORY = Object.freeze("/history");
export const API_URN_PATIENT = Object.freeze("/patient");
export const API_URN_ME = Object.freeze("/me");
export const API_URN_PATIENTS = Object.freeze("/patients");
export const API_URN_PROFILE = Object.freeze("/profile");
export const API_URN_REQUEST_PATIENT_MRN_DOB = Object.freeze("/request/patient/mrn-dob");
export const API_URN_LOGIN_REQUEST = Object.freeze("/login-request");
export const API_URN_CHECK_OTP = Object.freeze("/check-otp");
export const API_URN_SEND_OTP = Object.freeze("/send-otp");
export const API_URN_RECOVER_PASSWORD = Object.freeze("/recover-password");
export const API_URN_FORGOT_PASSWORD = Object.freeze("/forgot-password");
export const API_URN_RESET_PASSWORD = Object.freeze("/reset-password");
export const API_URN_CHANGE_PASSWORD = Object.freeze("/change-password");
export const API_URN_FORGOT_USERNAME = Object.freeze("/forgot-username");
export const API_URN_MFA_DEVICES = Object.freeze("/mfa-devices");
export const API_URN_MEDIA = Object.freeze("/media");
export const API_URN_PROFILE_IMAGE = Object.freeze("/profile-image");
export const API_URN_DISPLAY_PICTURE = Object.freeze("/display-picture");
export const API_URN_INSURANCES = Object.freeze("/insurances");
export const API_CARE_TEAM = Object.freeze("/care-team");
export const API_URN_MESSAGE_SUBJECT = Object.freeze("/message-subjects");
export const API_URN_HEALTH_SUMMARY = Object.freeze("/health-snapshot");
export const API_URN_PRESCRIPTIONS = Object.freeze("/prescriptions");
export const API_URN_SUPPLIES = Object.freeze("/supplies");
export const API_INFUSION_JOURNAL = Object.freeze("/journal");
export const API_URN_ALLERGIES = Object.freeze("/allergies");
export const API_URN_MEDICATIONS = Object.freeze("/medications");
export const API_URN_CONDITIONS = Object.freeze("/conditions");