import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SUPPLIES } from '../actions/ActionTypes';
import { fetchAllSupplies } from '../../api/prescriptionsSupplies';
import { pageErrorExtractor } from '../../utils/httpErrorHelpers';
import { add, isAfter } from 'date-fns';
import { setCacheExpiryTime, setData, setError, setLoading } from '../actions/suppliesActions';
import { AUTHENTICATED_USER_TYPE } from '../../constants/portalUserType';

function* handleFetchAllSupplies() {
    const { currentPatientUsername, role } = yield select(state => state?.auth);
    const { error, cacheExpiryTime } = yield select((state) => state?.supplies);
    const isCacheExpired = isAfter(new Date(), new Date(cacheExpiryTime));

    if (role === AUTHENTICATED_USER_TYPE.ADMIN || error || cacheExpiryTime === null || isCacheExpired) {
        try {
            yield put(setError(null));
            yield put(setLoading(true));
            const suppliesResponse = yield call(fetchAllSupplies, currentPatientUsername);///get All prescriptions
            const suppliesData = suppliesResponse.data;
            yield put(setData(suppliesData));
            yield put(setLoading(false));
            //Set cache expiry time. Cache is valid for 2 hours
            yield put(setCacheExpiryTime(add(new Date(), { hours: 2 }).toString()));
        } catch (error) {
            yield put(setError(pageErrorExtractor(error)));
            yield put(setLoading(false));
        }
    }

}

function* suppliesSaga() {
    yield takeLatest(SUPPLIES.FETCH_ALL_SUPPLIES, handleFetchAllSupplies);
}

export default suppliesSaga;