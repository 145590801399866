import {RESET} from "../actions/ActionTypes";

const defaultState = {
    firstname: null,
    lastname: null,
    dob: null,
    phone: null,
    addresses: [],
}

export const patientReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RESET: {
            return {...defaultState}
        }
        default:
            return {...state};
    }
}