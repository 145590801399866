import React from 'react';
import PropTypes from 'prop-types';
import {
    Backdrop,
    Box,
    Button,
    FormControlLabel,
    Grid,
    Modal,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";
import styled from "styled-components";
import {useFormikContext} from "formik";

const TermsOfServiceWrapper = styled.div`
  margin: var(--height-header) auto;
  padding: 1rem;
  background-color: white;
  height: calc(100vh - 2 * var(--height-header));
  overflow: auto;
  width: 80%;
  position: relative;
  font-size: 12px;
  border: 2px solid var(--color-site-yellow);
  border-radius: 5px;

  :focus {
    outline: none;
  }

  @media (max-width: 680px) {
    width: 95%;
    margin: 20px auto;
    height: calc(100vh - 40px);
  }
`;

function TermsOfServiceModal({isOpen, onAccept, onDecline, isAgreed}) {
    const {values, handleChange} = useFormikContext();

    return (
        <Modal
            open={isOpen} onClose={onDecline}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 400,
            }}
        >
            <TermsOfServiceWrapper>
                <Typography variant={"h6"}>Nufactor, Inc. Terms of Use</Typography>
                <Typography component='div'>
                <Typography paragraph variant="body1"> This website is controlled, operated and administered by Nufactor, Inc. (or its licensees). All
                    inquiries may be directed to:</Typography>
                <Typography paragraph variant="body1">Phone: (800) 323-6832</Typography>
                <Typography paragraph variant="body1">Email: <a href="mailto:nxinfo@nufactor.com">nxinfo@nufactor.com</a></Typography>

                <Typography paragraph variant="body1">Your access to and use of our website(s) (the "Website") is subject to the following terms of use
                    (these "Terms of Use") as well as our Privacy Statement and all applicable statutes, orders,
                    regulations, rules, and other laws.</Typography>
                <Typography paragraph variant="body1">By accessing and browsing the Website or clicking “I AGREE” or similar links or buttons
                    associated with or referring to these Terms of Use where these terms are referred to, you accept
                    and agree to be bound by these Terms of Use and Privacy Statement, which are conditions of
                    permission to access the Website. If you do not agree to these Terms of Use and Privacy
                    Statement, you may not have full access to the Website.</Typography>
                <Typography paragraph variant="body1">If you are younger than 18 years of age, you are forbidden from registering on the Website or
                    posting information on the Website.</Typography>
                <Typography paragraph variant="body1">The form and nature of the services, Content and all information posted on the Website is subject
                    to change without notice. In addition, these Terms of Use may be changed, altered or modified at
                    any time without prior notice. Nufactor, Inc. ("Company," "we," or "us") will make such changes
                    by posting them here. Company and you are each a “Party” to this Terms of Use and collectively
                    the “Parties”.</Typography>
                <Typography paragraph variant="body1">You should check this page periodically for such changes. You can determine when these Terms of
                    Use were last revised by referring to the "LAST UPDATED" legend at the bottom of these Terms of
                    Use. Your continued access of the Website after such changes conclusively demonstrates your
                    acceptance of those changes.</Typography>
                <Typography paragraph variant="body1">We reserve the right, at any time and from time to time, temporarily or permanently, in whole or
                    in part, to: modify or discontinue the Website, with or without notice; charge fees in
                    connection with the use of the Website; modify and/or waive any fees charged in connection with
                    the Website; and/or offer opportunities to some or all users of the Website. In these Terms of
                    Use, “Affiliated Entities” means any direct or indirect parent, subsidiaries, sponsors, or
                    affiliated companies of Nufactor, Inc. You agree that neither we nor any Affiliated Entities
                    shall be liable to you or to any third party for any modification, suspension or discontinuance
                    of the Website, in whole or in part, or of any service, information and materials contained
                    herein, software used to make the Website, or feature or product offered through the
                    Website.</Typography>

                <ol>
                    <li>
                        <h3>Usage, Portals and Security</h3>
                        <Typography paragraph variant="body1">Unless otherwise specified, the Website, mobile application and associated services are
                            for your personal and non-commercial use. Portions of the Website and mobile application
                            may include various password protected portals for different types of users of the
                            Website, including potentially for nurses or other health care providers, patients,
                            administrators, or other users (each a “Portal” and collectively the “Portals”).</Typography>

                        <h4>Minimum Requirements and Security</h4>
                        <ul>
                            <li>This Website and mobile application utilize encryption software. In order to use the
                                Website or a Portal, you must have a personal computer or other device with an
                                Internet browser that has adequate encryption of at least 128-bit with TLS 1.2 or
                                above.
                            </li>
                            <li>While we use state-of-the-art security, no system can guard against risks of
                                intentional intrusion or inadvertent disclosure of information. When using the
                                Website or a Portal, information may be transmitted over media that are beyond the
                                control of Company or its Affiliated Entities, and their contractors and
                                subcontractors, and that may not be secure. For example, you may receive email,
                                text, or telephone communications in connection with your use of a Portal, all of
                                which are inherently unsecure and subject to disclosure to or access by third
                                parties (e.g., if your phone is used by someone else, you do not keep your phone or
                                email information up to date on the Portal and communications are misdirected, or
                                the network or systems of a telecommunications provider are hacked). Furthermore,
                                electronic services such as those provided by Company or its Affiliated Entities,
                                their contractors and subcontractors, and any telecommunications providers involved
                                in the transmission of data are all inherently subject to failure and none of the
                                foregoing can guarantee that their services will be provided without error or
                                interruption at all times that you may wish to use those services. YOU HEREBY
                                EXPRESSLY ASSUME THE RISK OF ANY UNAUTHORIZED DISCLOSURE OR INTENTIONAL INTRUSION,
                                OR OF ANY DELAY, FAILURE, INTERRUPTION, OR CORRUPTION OF DATA OR OTHER INFORMATION
                                TRANSMITTED IN CONNECTION WITH THE USE OF ANY SERVICE RELATED TO ANY PORTAL
                            </li>
                        </ul>

                        <h4>Security, Confidentiality, and Usage Guidelines for the Portals</h4>
                        <ul>
                            <li>YOU MUST NOT SEND ANY MESSAGES REQUIRING URGENT ATTENTION USING A PATIENT PORTAL.
                                Doing so may create a delay in your receiving necessary medical treatment and could
                                result in loss of life, permanent injury, or significant deterioration in your
                                health. If you believe that you have an urgent medical matter, you should call 911
                                immediately or proceed to the nearest emergency room. If the matter is not urgent
                                but you need a response in less than two business days, please contact your
                                clinician's office directly by telephone during our regular business hours, which
                                you may find on the login screen for the patient Portal.
                                <div>FOR ALL MEDICAL EMERGENCIES, IMMEDIATELY CALL 911</div>
                            </li>
                            <li>Each Portal is designed as a secure Internet-based environment through which you may
                                receive or transmit confidential medical information. When you initially enroll to
                                use the Portal, you will need to confirm your identity via two unique workflows, as
                                well as establish an email and a confidential password for login credentials. Please
                                DO NOT SHARE YOUR PASSWORD with anyone. It is your responsibility to prevent
                                disclosure of your password and to change your password if you feel that your
                                security has been compromised. If you believe that your password has become known to
                                an unauthorized person and want verification of any changes that may have been made
                                to your account, please contact us for assistance.
                            </li>
                            <li>Anyone with access to your patient Portal password will be able to view your medical
                                and financial information and communicate with your health care team as if that
                                person were you. Thus, when you give someone your patient Portal password, you are
                                authorizing that person to access your account, and you are responsible for all
                                transactions that person performs while using your service.
                            </li>
                            <li>We secure your personal information from unauthorized access, use or disclosure. The
                                personally identifiable information you provide is maintained by us on computer
                                servers in a controlled, secure environment, protected from unauthorized access, use
                                or disclosure. When personal information is transmitted from your PC and/or mobile
                                devices to a Portal, it is protected through the use of encryption, such as
                                Transport Layer Security (TLS) protocol. As a user of a Portal, you hereby agree
                                that you (and not Company or its Affiliated Entities) are solely liable for viruses,
                                worms, Trojan horses, cancel bots, and other electronic mechanisms designed to
                                destroy or impair the functioning of computer systems already residing on your PC
                                and/or mobile device.
                            </li>
                        </ul>

                        <h4>Portal Users Responsibilities</h4>
                        <ul>
                            <li>For your use of the patient Portal, you understand that by accepting the Terms of
                                Use of this Agreement you are agreeing to receive your patient balance statements
                                delivered electronically to your patient Portal account. You have the option at any
                                time to revert to receiving your patient balance statements as paper statements sent
                                to you via U.S. Mail. You may exercise that option by using the appropriate options
                                on the patient Portal.
                            </li>
                            <li>You acknowledge that by entering your home and/or mobile telephone number into the
                                Portal you are providing written consent for Company or its Affiliated Entities,
                                their contractors and subcontractors, to send automated, prerecorded, or artificial
                                voice telephone calls to all provided numbers. You may opt-out of receiving these
                                calls on the appropriate pages of a Portal.
                            </li>
                            <li>You understand that a Portal provides you with online access to some clinical
                                information. You are solely responsible for any sharing of the Portal Content that
                                you intentionally or unintentionally communicate to others.
                            </li>
                            <li>By using the patient Portal, you acknowledge and agree that this messaging service
                                is intended to facilitate dialogue regarding health matters in accordance with the
                                Terms of Use.
                            </li>
                        </ul>

                        <h4>Patient Clinical Information</h4>
                        <ul>
                            <li>As we provide your health care, we are required to maintain documentation of your
                                medical history and treatments. The patient Portal is an Internet application that
                                enables a patient to have secure web-based access to personal clinical information,
                                as released by the health care provider, and allows secure electronic messaging with
                                our health care providers. By using the patient Portal, you understand that not all
                                of your medical record with us will be accessible through the patient Portal
                                account. Posting of information to your patient Portal account is at the sole
                                discretion of your individual health care provider(s).
                            </li>
                            <li>Messages you send or receive via the patient Portal may become part of your
                                permanent medical record at the sole discretion of your health care provider. If
                                such messages are made part of your permanent medical record, they will be
                                accessible to our current and future staff members who are involved with your care.
                            </li>
                            <li>All of our electronic medical records are subject to state and federal regulations
                                governing the security and confidentiality of medical records.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h3>Health-Related Content</h3>
                        <Typography paragraph variant="body1">IF YOU ARE EXPERIENCING A MEDICAL CRISIS, PLEASE CALL 911 OR CONTACT YOUR LOCAL EMERGENCY
                            ASSISTANCE SERVICE IMMEDIATELY.</Typography>
                        <Typography paragraph variant="body1">Any health-related Content residing on Website describes general principles of health
                            care that should not be construed as specific instructions for individual patients. The
                            Content is not intended as a substitute for a consultation with your physician or other
                            health care professional, and should not be used to diagnose or treat a health problem
                            without consulting your physician. It is for reference only and should not be used to
                            determine treatment for specific medical conditions. Only a health care provider can do
                            that. The Website and its health-related information and resources are not intended and
                            must not be taken as the rendering of medical, nursing or professional health care
                            advice or services, or the practice of medicine, nursing or professional health care in
                            any jurisdiction. You should discuss the information provided with a physician,
                            pharmacist, nurse or other licensed health care professional. You should also check
                            product information (including package inserts) regarding dosage, precautions, warnings,
                            interactions and contraindications before administering or using any device, drug, herb,
                            vitamin or supplement discussed on this Website, if any.</Typography>
                        <Typography paragraph variant="body1">You understand and agree that in no event will Company be liable for any decision made or
                            action taken in reliance on the information contained on, or accessible through, the
                            Website. RELIANCE ON ANY INFORMATION PROVIDED BY, OR OTHERWISE APPEARING ON, THE WEBSITE
                            IS SOLELY AT YOUR OWN RISK.</Typography>
                        <Typography paragraph variant="body1">Proper treatment of health conditions depends upon a number of factors, including, but
                            not limited to, your medical history, diet, lifestyle and medication regimen. Your
                            health care provider can best assess and address your individual health care needs. You
                            should consult with your health care provider before starting a new diet, fitness or
                            supplement regimen.</Typography>
                    </li>

                    <li>
                        <h3>Electronic Communications</h3>
                        <Typography paragraph variant="body1">When you use the Website or send e-mails to us, you are communicating with us
                            electronically. To the maximum extent permitted by law, you consent to receive
                            communications from us electronically. We will communicate with you by e-mail or by
                            posting notices within the Website. To the maximum extent permitted by law, you agree
                            that all agreements, notices, disclosures and other communications that we provide to
                            you electronically satisfy any legal requirement that such communications be in
                            writing.</Typography>
                    </li>

                    <li>
                        <h3>Counseling</h3>
                        <Typography paragraph variant="body1">By accepting these Terms of Use you will be able to contact a licensed pharmacist at any
                            time to ask questions about your medications via telephone, text message or secure
                            communication via the portal to the maximum extent permitted by law, Company may from
                            time to time contact you via telephone without your prior consent at its sole
                            discretion</Typography>
                    </li>

                    <li>
                        <h3>Permitted Use, Limited License and Authorization to Reproduce</h3>
                        <Typography paragraph variant="body1">Subject to these Terms of Use and any other terms and conditions on the Website, Company
                            hereby grants to you the non-exclusive right to use the Website and download, install,
                            reproduce, use and disclose the contents of the files or other media provided on the
                            Website that are specifically identified as available for download, subject to the
                            following conditions: (i) the material may be used for informational and noncommercial
                            purposes only; (ii) it may not be modified in any way, nor distributed, transmitted or
                            re-posted; (iii) no copy is made of any Company trademark or logo apart from the page on
                            which it appears; and (iv) any copy of any portion of the material must include the
                            copyright notice appearing on the Website. The Website, all of the information and
                            materials contained herein, and the software used to make the Website available
                            (collectively, "Content") are and shall remain the property of Company and its licensors
                            and suppliers, and are protected, without limitation, pursuant to U.S. and foreign
                            copyright, trademark, patent and/or other proprietary rights and laws. You do not
                            acquire any right, title or interest in such Content by virtue of accessing the Website
                            or making use of the permitted uses allowed under these Terms of Use.</Typography>
                    </li>

                    <li>
                        <h3>Copyrights</h3>
                        <Typography paragraph variant="body1">You should assume that everything you see or read on the Website is copyrighted unless
                            otherwise noted and may not be used except as provided in these Terms of Use (see
                            Section 1 above) or in the text on the Website without the written permission of
                            Company. Company neither warrants nor represents that your use of materials displayed on
                            Company’s Websites will not infringe rights of third parties not owned by or affiliated
                            with Company. Images, photographs, or illustrations displayed on the Website are either
                            the property of, or used with permission by, Company. The use of these materials by you,
                            or anyone else authorized by you, is prohibited unless specifically permitted by these
                            Terms of Use or specific permission provided elsewhere on the Website. Any unauthorized
                            use of the images may violate copyright laws, trademark laws, the laws of privacy and
                            publicity, and communications regulations and statutes.</Typography>
                        <Typography paragraph variant="body1">PLEASE NOTE THAT UNAUTHORIZED USE OF THE WEBSITE OR ANY CONTENT ON THE WEBSITE (INCLUDING
                            WITHOUT LIMITATION ANY SOFTWARE MADE AVAILABLE THROUGH THE WEBSITE) MAY IN PARTICULAR
                            JURISDICTIONS RESULT IN MONETARY DAMAGES AND OTHER CIVIL AND CRIMINAL PENALTIES
                            INCLUDING WITHOUT LIMITATION, FOR COPYRIGHT INFRINGEMENT.</Typography>
                    </li>

                    <li>
                        <h3>Trademarks</h3>
                        <Typography paragraph variant="body1">The trademarks, logos, service marks, brand identities, title, characters, trade names,
                            graphics, designs, copyrights and other properties appearing within Website
                            (collectively the "Trademarks") displayed on the Website are protected intellectual
                            property that include registered and unregistered trademarks of Company, its affiliates
                            and others. Nothing contained on the Website should be construed as granting, by
                            implication, or otherwise, any license or right to use any Trademark displayed on this
                            Website without the written permission of Company or such third party that may own the
                            Trademark displayed on the Website. Your misuse of the Trademarks displayed on the
                            Website, or any other Content on the Website, except as provided herein, is strictly
                            prohibited.</Typography>
                    </li>

                    <li>
                        <h3>Copyright Infringement Notification</h3>
                        <Typography paragraph variant="body1">Company is committed to complying with U.S. copyright law and to responding to claims of
                            copyright infringement. Company will promptly process and investigate notices of alleged
                            infringement and will take appropriate actions under the Digital Millennium Copyright
                            Act, Title 17, United States Code, Section 512(c) ("DMCA").</Typography>
                        <Typography paragraph variant="body1">Pursuant to the DMCA, notifications of claimed copyright infringement should be sent to a
                            Service Provider's Designated Agent. Notification must be submitted to the following
                            Designated Agent for the Website in the manner described below:</Typography>
                        <div>
                            <span>By Mail:</span>
                            <span>
                                    <div>
                                        Nufactor, Inc.
                                        44900 Winchester Road
                                        Temecula,  California 92590
                                        Attn: Chief Compliance Officer
                                    </div>
                                </span>
                        </div>
                        <div>
                            <Typography paragraph variant="body1">By Email:<a
                                href={"mailto:fffethicshotline@integritycounts.ca"}>fffethicshotline@integritycounts.ca</a>
                            </Typography>
                        </div>

                        <div>
                            <Typography paragraph variant="body1">For your complaint to be valid under the DMCA, you must provide all of the following
                                information when providing notice of the claimed copyright infringement:</Typography>
                            <ol type={"a"}>
                                <li>A physical or electronic signature of a person authorized to act on behalf of
                                    the copyright owner;
                                </li>
                                <li>Identification of the copyrighted work claimed to have been infringed;</li>
                                <li>Identification of the material that is claimed to be infringing or to be the
                                    subject of the infringing activity, and that is to be removed or access to which
                                    is to be disabled, as well as information reasonably sufficient to permit
                                    Company to locate the material;
                                </li>
                                <li>Information reasonably sufficient to permit Company to contact the copyright
                                    owner, such as an address, telephone number, and, if available, an electronic
                                    mail address;
                                </li>
                                <li>A statement that you have a good faith belief that use of the material in the
                                    manner complained of is not authorized by the copyright owner, its agent, or
                                    law; and
                                </li>
                                <li>A statement that the information in the notification is accurate, and under
                                    penalty of perjury, that you are authorized to act on behalf of the copyright
                                    owner.
                                </li>
                            </ol>
                            <Typography paragraph variant="body1">For more details on the information required for valid notification, see 17 U.S.C.
                                512(c)(3).</Typography>
                            <Typography paragraph variant="body1">You should be aware that, under the DMCA, claimants who make misrepresentations
                                concerning copyright infringement may be liable for damages incurred as a result of
                                the removal or blocking of the material, court costs, and attorney’s fees.</Typography>
                        </div>
                    </li>

                    <li>
                        <h3>Unauthorized Use or Access</h3>
                        <Typography paragraph variant="body1">Unless otherwise expressly authorized in these Terms of Use or on the Website, you may
                            not take any action to interfere with the Website or any other user's use of the Website
                            or decompile, reverse engineer or disassemble any Content or other products or processes
                            accessible through the Website, nor insert any code or product or manipulate the Content
                            in any way that affects any user's experience. While using the Website you are required
                            to comply with all applicable statutes, orders, regulations, rules, and other laws. In
                            addition, we expect users of the Website to respect the rights and dignity of others.
                            Your use of the Website is conditioned on your compliance with the rules of conduct set
                            forth in this section.</Typography>
                        <Typography paragraph variant="body1">You may not (and you expressly agree that you will not) do any of the following, which
                            violate these Terms of Use:</Typography>
                        <ol type={"a"}>
                            <li>
                                <Typography paragraph variant="body1">Post, upload, share, transmit, distribute, facilitate distribution of, or
                                    otherwise make available, through or in connection with the Website:</Typography>
                                <ol type={"i"}>
                                    <li>Anything that is or may be (a) threatening, harassing, degrading, hateful or
                                        intimidating; (b) defamatory; (c) fraudulent or tortious; (d) obscene,
                                        indecent, pornographic or otherwise objectionable; or (e) protected by
                                        copyright, trademark, trade secret, right of publicity or other proprietary
                                        right without the express prior consent of the owner of such right.
                                    </li>
                                    <li>Any material that would give rise to criminal or civil liability; that
                                        contains or promotes violence, drug use, illegal gambling or other criminal
                                        activity; that encourages conduct that constitutes a criminal offense; or
                                        that encourages or provides instructional information about illegal
                                        activities or activities such as "hacking," "cracking," or "phreaking."
                                    </li>
                                    <li>Any virus, worm, Trojan Horse, Easter egg, time bomb, spyware, or other
                                        computer code, file, or program that is harmful or invasive or may or is
                                        intended to damage or hijack the operation of, or to monitor the use of, any
                                        hardware, software or equipment.
                                    </li>
                                    <li>Any unsolicited or unauthorized advertising, promotional material, "junk
                                        mail," "spam," "chain letter," "pyramid scheme" or investment opportunity,
                                        or any other form of solicitation.
                                    </li>
                                    <li>Any material, non-public information about a company without the proper
                                        authorization to do so.
                                    </li>
                                </ol>
                            </li>
                            <li>Use the Website for any fraudulent or unlawful purpose.</li>
                            <li>Use the Website to defame, abuse, harass, stalk, threaten or otherwise violate the
                                legal rights of others, including without limitation others' privacy rights or
                                rights of publicity, or harvest or collect personally identifiable information about
                                users of the Website.
                            </li>
                            <li>Impersonate any person or entity, including without limitation any representative of
                                Company or an Affiliated Entities; falsely state or otherwise misrepresent your
                                affiliation with any person or entity in connection with the Website; or express or
                                imply that we endorse any statement you make.
                            </li>
                            <li>Interfere with or disrupt the operation of the Website or the servers or networks
                                used to make the Website available; or violate any requirements, procedures,
                                policies or regulations of such networks.
                            </li>
                            <li>Restrict or inhibit any other person from using the Website (including without
                                limitation by hacking or defacing any portion of the Website).
                            </li>
                            <li>Use the Website to advertise or offer to sell or buy any goods or services for any
                                business purpose, without Company's express prior written consent.
                            </li>
                            <li>Reproduce, duplicate, copy, sell, resell or otherwise exploit for any commercial
                                purposes, any portion of, use of, or access to the Website.
                            </li>
                            <li>Modify, adapt, translate, reverse engineer, decompile or disassemble any portion of
                                the Website.
                            </li>
                            <li>Remove any copyright, trademark or other proprietary rights notice from the Website
                                or materials originating from the Website.
                            </li>
                            <li>Frame or mirror any part of the Website without Company's express prior written
                                consent.
                            </li>
                            <li>Create a database by systematically downloading and storing Website Content.</li>
                            <li>Use any robot, spider, site search/retrieval application or other manual or
                                automatic device to retrieve, index, "scrape," "data mine" or in any way gather
                                Website Content or reproduce or circumvent the navigational structure or
                                presentation of the Website.
                            </li>
                        </ol>
                        <Typography paragraph variant="body1">Additionally, you acknowledge and agree that you (and not Company) are responsible for
                            obtaining and maintaining all telecommunications, broadband, and computer hardware,
                            equipment, and services needed to access and use the Website, and paying all charges
                            related thereto.</Typography>
                    </li>

                    <li>
                        <h3>International Users</h3>
                        <Typography paragraph variant="body1">The information presented on the Website is intended for use in the United States only.
                            The Website is controlled, operated and administered by Company (or its licensees) from
                            its offices within the United States of America and is not intended to subject Company
                            to the laws or jurisdiction of any state, country or territory other than that of the
                            United States. Company does not represent or warrant that the Website or any part
                            thereof is appropriate or available for use in any particular jurisdiction other than
                            the United States. Those who choose to access the Website do so on their own initiative
                            and at their own risk, and are responsible for complying with all local statutes,
                            orders, regulations, rules, and other laws. You are also subject to United States export
                            controls and are responsible for any violations of such controls, including without
                            limitation any United States embargoes or other federal rules and regulations
                            restricting exports. Company may limit the Website's availability, in whole or in part,
                            to any person, geographic area or jurisdiction we choose, at any time and in our sole
                            discretion.</Typography>
                    </li>

                    <li>
                        <h3>Termination of Access</h3>
                        <Typography paragraph variant="body1">These Terms of Use are in effect until terminated by either party. You may terminate this
                            agreement and any account created through the Website at any time, by destroying all
                            materials obtained from all Company Websites, along with all related documentation and
                            all copies and installations.</Typography>
                        <Typography paragraph variant="body1">In addition to any right or remedy that may be available to Company under applicable law,
                            Company may suspend, limit, or terminate all or a portion of your access to the Website,
                            and/or your rights to use any of the Content, at any time with or without notice and
                            with or without cause, including without limitation, if Company believes that you have
                            violated or acted inconsistently with the letter or spirit of these Terms of Use. Upon
                            any such termination, your right to use the Website will immediately cease. In addition,
                            Company may refer any information on illegal activities, including your identity, to the
                            proper authorities. You agree that Company and the Affiliated Entities shall not be
                            liable to you or any third party for any suspension, limitation, or termination of your
                            access to the Website.</Typography>
                    </li>

                    <li>
                        <h3>Accuracy of Information You Submit</h3>
                        <Typography paragraph variant="body1">You may be permitted to or asked to submit information to the Website. You expressly
                            represent and warrant: (a) that you have the authority to provide Company with all such
                            information; (b) that all such information may be used by Company for the purposes
                            intended; (c) that all such information shall be true, accurate, and complete; and (d)
                            that you will maintain and update such information as needed, such that the information
                            remains true, accurate and complete. You agree that if any information you provide is
                            false, inaccurate, obsolete or incomplete, we may terminate your access to or use of the
                            Website.</Typography>
                    </li>

                    <li>
                        <h3>Content you share with us</h3>
                        <Typography paragraph variant="body1">We may include features on the Website that allow you to share your information,
                            comments, reviews, ideas, concepts, drawings, and other texts, photos, pictures and
                            other images, films, movies, music and other audio files, software, applications or
                            games (together referred to as “User Content”) with us and other users of the Website.
                            Please note that by sharing User Content through the Website, your User Content may
                            become publicly accessible and will not be subject to any confidentiality restrictions.
                            You grant to Company and its Affiliated Entities a worldwide, non-exclusive,
                            transferable, royalty-free, perpetual, irrevocable right and license, without
                            compensation to you:</Typography>
                        <ul>
                            <li>to use, reproduce, distribute, adapt (including without limitation edit, modify,
                                translate, and reformat), derive, transmit, display and perform, publicly or
                                otherwise, such User Content, in any media now known or hereafter developed, for
                                Company and/or the Affiliated Entities' business purposes,
                            </li>
                            <li>to sublicense the foregoing rights, through multiple tiers, to the maximum extent
                                permitted by applicable law, and
                            </li>
                            <li>to use your name, or screen name, hometown, photograph, portrait, picture, voice,
                                likeness and biographical information as news or information in any and all media
                                and for advertising or promotional purposes, whether in connection with your
                                submission.
                            </li>
                        </ul>
                        <Typography paragraph variant="body1">The foregoing licenses shall survive any termination of your use of the Website. For all
                            of the User Content you share through the Website, you represent and warrant that you
                            have all rights necessary for you to grant these licenses, and that such User Content,
                            and your provision or creation thereof through the Website, complies with all applicable
                            laws, rules, and regulations and does not infringe or otherwise violate the copyright,
                            trademark, trade secret, privacy or other intellectual property or other rights of any
                            third party, and is furthermore free from viruses and other malware. You further, to the
                            extent permissible by law, irrevocably waive any "moral rights" or other rights with
                            respect to attribution of authorship or integrity of materials regarding each item of
                            User Content that you submit.</Typography>
                        <Typography paragraph variant="body1">Please note that we do not solicit or encourage submissions of User Content containing
                            ideas or suggestions relating to the Website, our business or our Affiliated Entities’
                            businesses. If you send us any ideas or suggestions, regardless of the topic, we and our
                            Affiliated Entities will have no obligations with respect to such ideas or suggestions
                            (including without limitation, confidentiality) and may use them for any purpose
                            whatsoever. You understand and acknowledge that Company has both internal resources and
                            other external resources which may have developed, or may in the future develop, ideas
                            identical to or similar to any ideas or suggestions or comments to suggestions you may
                            submit. Any idea or suggestion you submit to us shall be subject to the foregoing User
                            Content policy and terms.</Typography>
                    </li>

                    <li>
                        <h3>Monitoring; Removal of Submissions</h3>
                        <Typography paragraph variant="body1">You acknowledge and agree that we reserve the right (but have no obligation) to do one or
                            all of the following, at our sole discretion: (a) evaluate User Content before allowing
                            it to be posted on the Website; (b) monitor User Content; (c) alter, remove, reject, or
                            refuse to post or allow to be posted, without notice to you, any User Content, for any
                            reason or for no reason whatsoever; provided, however, that we shall have no obligation
                            or liability to you for failure to do so or for doing so in any particular manner;
                            and/or (d) disclose any User Content, and the circumstances surrounding their
                            transmission, to any third party in order to operate the Website; to protect Company,
                            the Affiliated Entities, and their respective employees, officers, directors,
                            shareholders, agents, and representatives, and the Website's users and visitors; to
                            comply with legal obligations or governmental requests; to enforce these Terms of Use;
                            or for any other reason or purpose.</Typography>
                    </li>

                    <li>
                        <h3>Links to Other Sites</h3>
                        <Typography paragraph variant="body1">The Website may provide links to other websites and online resources. We are not
                            responsible for and do not endorse such external sites or resources. Other sites may
                            link to the Website with or without our authorization, and we may block any links to or
                            from the Website without prior notice.</Typography>
                        <Typography paragraph variant="body1"><strong>YOU AGREE THAT YOUR USE OF THIRD-PARTY WEBSITES AND RESOURCES, INCLUDING WITHOUT
                            LIMITATION YOUR USE OF ANY CONTENT, INFORMATION, DATA, ADVERTISING, PRODUCTS, OR OTHER
                            MATERIALS ON OR AVAILABLE THROUGH SUCH WEBSITES AND RESOURCES, IS AT YOUR OWN RISK AND
                            IS SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH SITES AND
                            RESOURCES.</strong></Typography>
                    </li>

                    <li>
                        <h3>Privacy</h3>
                        <Typography paragraph variant="body1">We take your privacy seriously. Your submission of personally identifiable information
                            and personal data through the Website is governed by Company's Privacy Policy
                            http://www.nufactor.com/Assets/Downloads/NxNoticePrivacyPractices-English.pdf. These
                            Terms of Use incorporate by reference the terms and conditions of the Privacy
                            Policy.</Typography>
                    </li>

                    <li>
                        <h3>Disclaimers</h3>
                        <ul>
                            <li>
                                <h3>Technical Disclaimer</h3>
                                <Typography paragraph variant="body1">The material in this Website could include technical inaccuracies or other
                                    errors. Your use and browsing of the Website are at your risk. Company does not
                                    warrant that the functional aspects of the Website will be uninterrupted or
                                    error free or that this Website or the server that makes it available are free
                                    of viruses or other harmful components.</Typography>
                                <Typography paragraph variant="body1">FURTHER, WE DO NOT PROMISE THAT THIS WEBSITE, NOR ANY INFORMATION, CONTENT OR
                                    MATERIALS MADE AVAILABLE THROUGH THE WEBSITE, WILL BE ACCURATE, RELIABLE,
                                    COMPLETE, ERROR FREE OR COMPATIBLE WITH ANY PARTICULAR HARDWARE OR SOFTWARE.</Typography>
                            </li>
                            <li>
                                <h3>General Disclaimer</h3>
                                <Typography paragraph variant="body1">WITHOUT LIMITING THE FOREGOING, COMPANY, ITS AFFILIATED ENTITIES AND THEIR
                                    DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS AND PROVIDERS
                                    MAKE NO REPRESENTATION OR WARRANTY (a) REGARDING THE STATEMENTS, ACTS OR
                                    OMISSIONS OF ANY THIRD PARTIES; (B) THAT THE WEBSITE AND/OR ANY OF ITS FEATURES
                                    WILL BE AVAILABLE ON A TIMELY BASIS, OR THAT ACCESS TO THE WEBSITE AND/OR ANY OF
                                    ITS FEATURES WILL BE UNINTERRUPTED OR SECURE; (C) THAT DEFECTS OR ERRORS WILL BE
                                    CORRECTED; OR (D) THAT THE WEBSITE OR THE SERVERS OR NETWORKS THROUGH WHICH THE
                                    WEBSITE IS MADE AVAILABLE ARE SECURE OR FREE OF VIRUSES OR OTHER HARMFUL
                                    COMPONENTS.</Typography>
                                <Typography paragraph variant="body1">TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE WEBSITE, YOUR USE OF THE WEBSITE, ITS
                                    CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, INCLUDING THE
                                    ONLINE STORE, IS AT YOUR OWN RISK; AND THE WEBSITE, ITS CONTENT AND ANY SERVICES
                                    OR ITEMS OBTAINED THROUGH THE WEBSITE, INCLUDING THE ONLINE STORE, ARE PROVIDED
                                    ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
                                    EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH
                                    THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                                    COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
                                    WEBSITE.</Typography>
                                <Typography paragraph variant="body1">WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH
                                    THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES
                                    OR ITEMS OBTAINED THROUGH THE WEBSITE INCLUDING THE ONLINE STORE WILL BE
                                    ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
                                    THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                                    HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                                    THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</Typography>
                                <Typography paragraph variant="body1">THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                                    IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                                    MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</Typography>
                                <Typography paragraph variant="body1">NEITHER COMPANY, NOR ITS AFFILIATED ENTITIES, NOR ANY OF ITS AGENCIES, NOR ANY
                                    OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE WEBSITE, IS
                                    LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT, OR PUNITIVE DAMAGES
                                    OR LOSSES ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO, OR USE OF, THE
                                    WEBSITE, ITS FEATURES OR ANY CONTENT MADE AVAILABLE THROUGH THE WEBSITE, EVEN IF
                                    ADVISED IN ADVANCE OF SUCH DAMAGES OR LOSSES, TO THE EXTENT PERMITTED BY
                                    APPLICABLE LAW. OUR MAXIMUM LIABILITY FOR ALL DAMAGES, LOSSES AND CAUSES OF
                                    ACTION, WHETHER IN CONTRACT, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE) OR
                                    OTHERWISE SHALL BE THE TOTAL AMOUNT PAID BY YOU TO US TO ACCESS AND USE THE
                                    WEBSITE.</Typography>
                                <Typography paragraph variant="body1">IT IS POSSIBLE THAT APPLICABLE LAW MAY NOT ALLOW FOR LIMITATIONS ON CERTAIN
                                    IMPLIED WARRANTIES OR EXCLUSIONS OR LIMITATIONS OF CERTAIN DAMAGES; SOLELY TO
                                    THE EXTENT THAT SUCH LAW APPLIES TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
                                    EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
                                    RIGHTS.</Typography>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h3>Indemnification</h3>
                        <Typography paragraph variant="body1">To the maximum extent permitted by law, you agree to defend, hold harmless and indemnify
                            Company, the Affiliated Entities, and their respective officers, directors, employees,
                            consultants, agents, shareholders, and representatives, from and against any and all
                            claims, liabilities, damages, fines, penalties or costs of whatsoever nature (including
                            reasonable attorneys' fees and costs), arising out of or in any way connected with: (i)
                            any breach by you of these Terms of Use; or (ii) your use of the Content or services
                            available on the Website in any unauthorized manner or (iii) your use of any items
                            obtained through the Website, including the online store.</Typography>
                    </li>

                    <li>
                        <h3>Severability</h3>
                        <Typography paragraph variant="body1">If any part of these Terms of Use shall be held or declared to be invalid or
                            unenforceable for any reason by any court of competent jurisdiction, such provision
                            shall be ineffective but shall not affect any other part of these Terms of Use.</Typography>
                    </li>

                    <li>
                        <h3>Waiver; Remedies</h3>
                        <Typography paragraph variant="body1">The failure of Company to partially or fully exercise any rights or the waiver of Company
                            of any breach of these Terms of Use by you, shall not prevent a subsequent exercise of
                            such right by Company or be deemed a waiver by Company of any subsequent breach by you
                            of the same or any other term of these Terms of Use. The rights and remedies of Company
                            under these Terms of Use shall be cumulative, and the exercise of any such right or
                            remedy shall not limit Company's right to exercise any other right or remedy.</Typography>
                    </li>

                    <li>
                        <h3>California Consumer Protection Information</h3>
                        <Typography paragraph variant="body1">Under California Civil Code Section 1789.3, California users are entitled to the
                            following consumer rights notice: California residents may reach the Complaint
                            Assistance Unit of the Division of Consumer Services of the California Department of
                            Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by
                            telephone at (916) 445-1254 or (800) 952-5210.</Typography>
                    </li>

                    <li>
                        <h3>Governing Law; Forum</h3>
                        <Typography paragraph variant="body1">The laws of the State of California shall govern these Terms of Use, without regard to
                            its conflict of laws principles. Subjection to the arbitration requirements below, you
                            hereby expressly consent to the exclusive jurisdiction of and venue in the courts
                            located in the State of California, and waive any objection to such courts, including
                            without limitation, on the basis of personal jurisdiction, venue or inconvenience of the
                            forum.</Typography>
                    </li>

                    <li>
                        <h3>Arbitration</h3>
                        <Typography paragraph variant="body1">In the event of any controversy or claim arising from or relating to this Terms of Use or
                            the breach thereof (a “Dispute”), the Parties shall use their commercially reasonable
                            efforts to settle the Dispute by negotiating with one another in good faith. If the
                            Parties do not resolve the Dispute within twenty (20) days from the first sending of
                            notice of the Dispute by either Party to the other Party, the Dispute(s) shall be
                            finally settled by confidential arbitration administered by the American Arbitration
                            Association (“AAA”) under its Commercial Arbitration Rules (the “Rules”). Either Party
                            may commence the arbitration by filing a demand for arbitration in accordance with the
                            Rules and providing notice thereof in accordance with this Terms of Use. The arbitration
                            shall be governed by the Federal Arbitration Act. The language of the arbitration shall
                            be English, and the seat of arbitration shall be San Diego, California. Unless the
                            Parties agree otherwise in writing: (a) the Dispute will be submitted to one arbitrator,
                            and (b) the consent of the Parties to selection of the arbitrator shall be contingent on
                            the arbitrator’s commitment at the time of appointment to exercise reasonable efforts to
                            issue a final award within six (6) months of such appointment. The confidentiality
                            requirements of this Terms of Use shall apply to all aspects of the dispute resolution
                            process set forth in this Section, including all written or oral submissions and
                            exchanges of information in the arbitral proceedings and all settlement or other
                            discussions relating to the Dispute. Each Party shall be responsible to pay arbitration
                            fees (including filing, administrative, hearing and/or other fees) calculated in
                            accordance with the Rules, without any fee-shifting. Each Party shall be responsible for
                            payment of its own legal fees and related expenses. Any award rendered by the arbitrator
                            shall be final and binding on the Parties and may be confirmed by any court of competent
                            jurisdiction.</Typography>
                    </li>

                    <li>
                        <h3>Class Action Waiver</h3>
                        <Typography paragraph variant="body1">THE PARTIES WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST THE OTHER PARTY AS A
                            REPRESENTATIVE OR MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION, EXCEPT WHERE SUCH WAIVER
                            IS PROHIBITED BY LAW OR DEEMED BY A COURT OF LAW TO BE AGAINST PUBLIC POLICY. TO THE
                            EXTENT EITHER PARTY IS PERMITTED BY LAW OR COURT OF LAW TO PROCEED WITH A CLASS OR
                            REPRESENTATIVE ACTION AGAINST THE OTHER, THE PARTIES AGREE THAT: (I) THE PREVAILING
                            PARTY SHALL NOT BE ENTITLED TO RECOVER ATTORNEYS' FEES OR COSTS ASSOCIATED WITH PURSUING
                            THE CLASS OR REPRESENTATIVE ACTION (NOT WITHSTANDING ANY OTHER PROVISION IN THIS TERMS
                            OF USE); AND (II) THE PARTY WHO INITIATES OR PARTICIPATES AS A MEMBER OF THE CLASS WILL
                            NOT SUBMIT A CLAIM OR OTHERWISE PARTICIPATE IN ANY RECOVERY SECURED THROUGH THE CLASS OR
                            REPRESENTATIVE ACTION.</Typography>
                    </li>

                    <li>
                        <h3>Miscellaneous</h3>
                        <ul>
                            <li>This Terms of Use and the Privacy Statement constitutes the entire agreement between
                                the Parties and supersedes and extinguishes all previous drafts, agreements,
                                arrangements and understandings between them, whether written or oral, relating to
                                this subject matter.
                            </li>
                            <li>These Terms of Use do not, and shall not be construed to, create any partnership,
                                joint venture, employer-employee, agency or franchisor-franchisee relationship
                                between you and Company. You may not assign, transfer or sublicense any or all of
                                your rights or obligations under these Terms of Use without our express prior
                                written consent.
                            </li>
                            <li>Any heading, caption or section title contained herein is inserted only as a matter
                                of convenience, and in no way defines or explains any section or provision hereof.
                            </li>
                            <li>To the extent that any or all terms of these Terms of Use are inconsistent with any
                                agreement, written or oral, you may have previously or contemporaneously entered
                                into and/or signed with Company with respect to the subject matter herein, you
                                acknowledge and agree that the terms of these Terms of Use shall supersede such
                                other agreement and prevail.
                            </li>
                            <li>Notices to you may be made via posting to the Website, by e-mail, or by regular
                                mail, in Company's discretion (in the latter two situations, via the most recent
                                address that we have on file).
                            </li>
                            <li>The Website may also provide notices of changes to these Terms of Use or other
                                matters by displaying such notices or by providing links to such notices.
                            </li>
                            <li>Without limitation, you agree that a printed version of these Terms of Use and of
                                any notice given in electronic form shall be admissible in judicial or
                                administrative proceedings based upon or relating to these Terms of Use to the same
                                extent and subject to the same conditions as other business documents and records
                                originally generated and maintained in printed form.
                            </li>
                        </ul>
                    </li>

                </ol>
                </Typography>         
                <section className="_mfa_consent">
                    <Box p={1}>
                        <Typography variant={"h6"}>Client Communication Consent Form</Typography>
                        <Typography variant={"body2"}>
                            You may give permission to your provider to communicate with you by email,
                            phone, and text message (also known as SMS). This form provides information
                            about the risks of these forms of communication, guidelines for email/phone/text
                            communication, and how we use email/phone/text communication. It also will be
                            used to document your consent for communication with you by email, phone, and
                            text message.
                        </Typography>
                        <ol>
                            <li>
                                <Typography variant={"subtitle1"}>Risks of using email/texting:</Typography>
                                <div>
                                    The transmission of client information by email, phone and/or texting has a
                                    number of risks that clients should consider prior to the use of email, phone
                                    and/
                                    or texting. These include, but are not limited to, the following risks:
                                    <Typography paragraph variant="body1">A. Emails, phone calls/voicemails, and text messages can be circulated,
                                        forwarded,</Typography>
                                </div>
                                <Box p={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant={"subtitle2"}>I consent to receiving phone calls and
                                                voice mail messages</Typography>
                                            <RadioGroup row name={"agreePhoneConsent"}
                                                        value={values.agreePhoneConsent}
                                                        onChange={handleChange}>
                                                <FormControlLabel value={"true"} control={<Radio/>} label="Accept"/>
                                                <FormControlLabel value={"false"} control={<Radio/>}
                                                                  label="Decline"/>
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"subtitle2"}>I consent to receiving SMS text
                                                messages</Typography>
                                            <RadioGroup row name={"agreeSmsConsent"}
                                                        value={values.agreeSmsConsent}
                                                        onChange={handleChange}>
                                                <FormControlLabel value={"true"} control={<Radio/>} label="Accept"/>
                                                <FormControlLabel value={"false"} control={<Radio/>}
                                                                  label="Decline"/>
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"subtitle2"}>I consent to receiving email
                                                messages</Typography>
                                            <RadioGroup row name={"agreeEmailConsent"}
                                                        value={values.agreeEmailConsent}
                                                        onChange={handleChange}>
                                                <FormControlLabel value={"true"} control={<Radio/>} label="Accept"/>
                                                <FormControlLabel value={"false"} control={<Radio/>}
                                                                  label="Decline"/>
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </li>
                        </ol>

                    </Box>
                </section>


                <Typography variant={"caption"}>Last Updated: 1/15/2021</Typography>

                <Box marginY={2}>
                    <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                        <Button onClick={onDecline} color={"inherit"}>Decline</Button>
                        <Button color={"primary"} variant={"contained"}
                                disabled={
                                    values.agreeSmsConsent.toLowerCase() === "false"
                                    || values.agreePhoneConsent.toLowerCase() === "false"
                                    || values.agreeEmailConsent.toLowerCase() === "false"
                                }
                                onClick={onAccept}>
                            {isAgreed ? "Accepted" : "I agree"}
                        </Button>
                    </Stack>
                </Box>
            </TermsOfServiceWrapper>
        </Modal>
    );
}

TermsOfServiceModal.propTypes = {
    isOpen: PropTypes.bool,
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    isAgreed: PropTypes.bool,
};

export default TermsOfServiceModal;