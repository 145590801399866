import React from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import * as yup from 'yup';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { FLOW_ID_QP } from '../../../../constants/queryParams';
import { useDispatch } from 'react-redux';
import { VALID_PASSWORD_REGEX } from '../../../../constants/validators';
import { API_URN_PATIENT, API_URN_RESET_PASSWORD } from '../../../../constants/apiURNs';
import { URN_AUTH, URN_LOGIN } from '../../../../constants/clientURNs';
import PasswordInput from '../../../components/PasswordInput';
import { Formik } from 'formik';
import { idpRequestSuccess_saga } from '../../../../redux/actions/authActions';
import { postOrPutErrorExtractor } from '../../../../utils/httpErrorHelpers';
import { toast } from 'react-toastify';

function ResetPasswordDialog({title}) {
    const params = new URLSearchParams(useLocation().search);
    const flowId = params.get(FLOW_ID_QP);

    const history = useHistory();
    const dispatchAction = useDispatch();

    return (
        <Dialog open={true} scroll={"paper"} fullWidth maxWidth={"xs"}>
            <Formik
                enableReinitialize //update formik's initial value with flowId change from URL
                initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    flowId: flowId
                }}
                validationSchema={yup.object().shape({
                    currentPassword: yup.string().trim().required("Please provide a your current password"),
                    newPassword: yup.string().required("Required").matches(VALID_PASSWORD_REGEX, "Password must be 8 characters or longer, containing: one capital letter, one lower case letter, one number, one special character “~!@#$%^&*()-_=+[]{}|;:,.<>/?”. The password cannot have more than 2 repeated characters.").typeError("Required"),
                })}
                onSubmit={(values, formikHelpers) => {
                    axios({
                        method: "POST",
                        baseURL: process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_RESET_PASSWORD,
                        withCredentials: true,
                        data: values
                    }).then(({data}) => {
                        toast.success("Password reset successful, please proceed to login.", {
                            position: "top-center",
                            autoClose: 8000
                        });
                        dispatchAction(idpRequestSuccess_saga(data, history));
                    }).catch((error) => {
                        formikHelpers.setSubmitting(false);
                        const errorMessage = postOrPutErrorExtractor(error, formikHelpers);
                        const isPingError = error?.response?.data?.code === 7012
                        if (isPingError) { //Error because of PingOne
                            if (errorMessage.toLowerCase().includes("invalid value for st")
                                || errorMessage.toLowerCase().includes("requested resource was not found")
                                || errorMessage.toLowerCase().includes("session expired")
                            ) {
                                //The PingOne error can occurs if the flow-id is invalid or expired.
                                //In that case, there should be a fallback plan to get the new flow-id.
                                //Open the session expired Dialog and that will take care of creating a new flow-id.
                                toast("Login Session expired")
                                history.push(URN_AUTH + URN_LOGIN)
                            }
                            //user login with the different username and older cookies cannot be used.
                            if (errorMessage.toLowerCase().includes("username must identify current session user")) {
                                toast("Login Session expired")
                                history.push(URN_AUTH + URN_LOGIN)
                            }
                        }
                        formikHelpers.setStatus({errorMessage})
                    })
                }}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      errors, status, setStatus,
                      touched
                  }) => (
                    <>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                        <DialogContent>
                            <Typography color={"textSecondary"} sx={{marginBottom: 2}}>
                                Please create a new Password
                            </Typography>
                            <Box component={"form"} autoComplete="off" maxWidth={400}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <PasswordInput
                                            name={"currentPassword"} required label={"Current Password"}
                                            autoComplete="off"
                                            value={values.currentPassword} size={"medium"}
                                            onChange={handleChange}
                                            error={touched.currentPassword && errors.currentPassword !== undefined}
                                            helperText={touched.currentPassword ? errors.currentPassword : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInput
                                            name={"newPassword"} required label={"New Password"}
                                            autoComplete="new-password"
                                            value={values.newPassword} size={"medium"}
                                            onChange={handleChange}
                                            error={touched.newPassword && errors.newPassword !== undefined}
                                            helperText={touched.newPassword ? errors.newPassword : ""}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            {status?.errorMessage &&
                            <Box marginY={2}>
                                <Alert severity={"error"} onClose={() => setStatus({errorMessage: null})}>
                                    {status?.errorMessage}
                                </Alert>
                            </Box>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={"row"} spacing={2}>
                                <Button variant={"outlined"} onClick={() => history.replace(URN_AUTH + URN_LOGIN)}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained" color={"primary"}
                                    onClick={handleSubmit} disabled={isSubmitting} type={"submit"}
                                    endIcon={isSubmitting ?
                                        <CircularProgress color={"inherit"} size={20}/> : undefined}
                                >
                                    Reset Password
                                </Button>
                            </Stack>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );
}

export default ResetPasswordDialog;