import React from 'react';
import ErrorCard from '../ui/components/ErrorCard';
import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * An higher order function to render three states of data.
 * 1. Loading
 * 2. Error
 * 3. Actual data
 * @param render the JSX to render if not loading and no error
 * @returns {function({isLoading: boolean, loadingText: string, error: Object, onReloadClick: function}): JSX.Element|*}
 */
export default function withLoaderAndErrorCard(render) {
    return ({ isLoading, loadingText, error, onReloadClick }) => {
        return (
            isLoading
                ?
                <Box minHeight={200} width={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'}
                     alignItems={'center'}>
                    <CircularProgress color={'primary'} size={30} />
                    {loadingText && <Typography color={'primary'}>{loadingText}</Typography>}
                </Box>
                : error
                    ? <Box display={'flex'} justifyContent={'center'}>
                        <ErrorCard title={error.title} description={error.description} status={error.status}
                                   onReloadClick={onReloadClick} />
                    </Box>
                    : render()
        );
    };
}

withLoaderAndErrorCard.propTypes = {
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
    error: PropTypes.object,
    onReloadClick: PropTypes.func,
};