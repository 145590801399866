import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Card, CardContent, Collapse, styled, Typography, useTheme} from "@mui/material";
import {TransitionGroup} from "react-transition-group";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const ConditionCardsWrapper = styled(TransitionGroup)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: .5rem;

  ._cardWrapper {
    width: 100%;
    max-width: 920px;
  }
`;

function MyConditions({data}) {
    const theme = useTheme();
    const [showItems, setShowItems] = useState(() => data?.length > 2 ? 2 : data?.length);

    return (
        <Box>
            <Box marginY={1}>
                <Typography variant={"h6"}>
                     Treatment Condition
                    {/*{data?.length ? `(${data?.length})` : ""}*/}
                </Typography>
            </Box>
            <Box>
                {data?.length > 0
                    ? <>
                        <ConditionCardsWrapper>
                            {data.sort((a, b) => a.name < b.name ? -1 : 1)
                                .slice(0, showItems)
                                .map((condition, i) => {
                                    const {name, description} = condition;
                                    return (
                                        <Collapse key={name + "-" + i} className={"_cardWrapper"}>
                                            <Card >
                                                <CardContent>
                                                    <Box marginY={1}>
                                                        <Typography color={"primary"} fontWeight={400}
                                                                    fontSize={" 1.2rem"}>
                                                            {description}
                                                        </Typography>
                                                        <Typography color={"textSecondary"}>{name}</Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Collapse>
                                    )
                                })
                            }
                        </ConditionCardsWrapper>
                        {data?.length > 2 &&
                        <Box textAlign={"center"}>
                            <Button color={"inherit"} className={"_showMore"}
                                    endIcon={showItems > 2 ? <ArrowUpIcon/> : <ArrowDownIcon/>}
                                    onClick={() => showItems === data?.length ? setShowItems(2) : setShowItems(data?.length)}>
                                {showItems > 2 ? "Show Less" : `Show All ${data.length} Conditions`}
                            </Button>
                        </Box>
                        }
                    </>
                    : <Box>
                        <Card>
                            <CardContent>
                                <Typography color={"error"}>
                                    There are currently no conditions assigned to you. If this is incorrect, please
                                    call Nufactor technical support at 1-800-323-6832 to update your profile with a team
                                    member.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </Box>
        </Box>
    );
}

MyConditions.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
    }))
};

export default MyConditions;