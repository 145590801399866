import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import ReactAvatarEditor from "react-avatar-editor"
import {toast} from "react-toastify";
import MainContentModal from "./MainContentModal";
import RotateRightIcon from '@mui/icons-material/RotateRightOutlined';
import SelectFileButton from "./SelectFileButton";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {uploadDisplayPicture} from "../../api/media";
import {postOrPutErrorExtractor} from "../../utils/httpErrorHelpers";
import {useSelector} from "react-redux";

const CanvasWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  canvas {
    width: 100% !important;
    height: 100% !important;
    max-height: 380px !important;
    max-width: 380px !important;
  }

  ._actions {
    width: 100%;
    max-width: 380px;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    ._editor {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ._zoom {
      width: 100%;
      max-width: 380px;
      margin-right: ${({theme}) => theme.spacing(2)};
      display: flex;
      justify-content: center;

      & input {
        width: 100%;
      }
    }
  }

  ${({theme}) => ({
    [theme.breakpoints.up("sm")]: {
      "& canvas": {
        width: "380px !important",
        height: "380px !important"
      },
      "& ._actions": {
        width: "380px"
      }
    }
  })}

`;

function AvatarEditor({onClose, isOpen, onSubmit, imageExisting}) {
    const editorRef = useRef();
    const [image, setImage] = useState(imageExisting);
    const [position, setPosition] = useState({x: 0.5, y: 0.5});
    const [scale, setScale] = useState(1);
    const [rotateDegree, setRotateDegree] = useState(0);
    const [isUploading, setUploading] = useState(false);

    const {currentPatientUsername} = useSelector(state => state?.auth);

    const handleImageSelect = (e) => {
        const image = e.target.files[0];
        if (image && image.size / 1024 / 1024 > 5) { //max 5MB
            toast.error("Maximum 5MB file allowed")
            return;
        }
        image && setImage(image)
    }

    const handleSubmit = () => {
        if (editorRef) {
            const canvas = editorRef?.current?.getImageScaledToCanvas();
            if (image) {
                if (canvas.toBlob) {
                    setUploading(true);
                    canvas.toBlob((blob) => {
                        let data = new FormData();
                        const file = new File([blob], "File name", {type: "image/png"});
                        data.append("file", file);
                        uploadDisplayPicture(data, currentPatientUsername)
                            .then(({data}) => {
                                setUploading(false);
                                onSubmit(data);
                            })
                            .catch((error) => {
                                setUploading(false);
                                if (error.response.status === 401) toast.error("You are not logged in. Please login and try again");
                                else toast.error(postOrPutErrorExtractor(error));
                            })
                    }, "image/jpg", 0)
                }
            } else {
                onSubmit(null);
            }
        } else {
            toast.error("We don't support Profile image update on this browser yet, please use Chrome, Safari or Firefox for this. Apologies.");
        }
    };

    return (
        <MainContentModal onClose={onClose} isOpen={isOpen}>
            <DialogContent>
                <Box textAlign={"center"} marginY={1}>
                    <Typography variant={"h6"} component={"h6"}>
                        {image ? "Edit" : "Add"} Your Profile Picture
                    </Typography>
                </Box>
                <CanvasWrapper>
                    <ReactAvatarEditor
                        ref={editorRef}
                        scale={scale}
                        position={position}
                        onPositionChange={(position) => setPosition(position)}
                        rotate={rotateDegree}
                        borderRadius={400}
                        image={image}
                        className={"_editor"}
                        onLoadFailure={() => toast.error("Failed to load image. Please try again.")}
                    />
                    <Box className={"_actions"} marginY={2}>
                        <div className={"_zoom"}>
                            <input type="range" name={"zoom"} min="1" max="2" defaultValue="1" step="0.01"
                                   onChange={(e) => setScale(parseFloat(e.target.value))}/>
                            <Typography>Zoom</Typography>
                        </div>
                        <Tooltip title={"Rotate Image"}>
                            <IconButton size={"small"} onClick={() => setRotateDegree(prevState => prevState + 90)}>
                                <RotateRightIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box display={"inline-flex"} width={"100%"} justifyContent={"center"}
                         alignItems={"center"} gap={2}>
                        <SelectFileButton accept={"image/png, image/jpeg"}
                                          content={image ? "Select Another Photo (5MB Maximum) " : "Select Your Photo (5MB Maximum)"}
                                          endIcon={<ImageOutlinedIcon/>}
                                          onSelect={handleImageSelect}/>
                        <Tooltip title={"Remove Image"}>
                            <IconButton onClick={() => setImage(null)} size="large">
                                <DeleteIcon color={"error"}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </CanvasWrapper>
            </DialogContent>
            <DialogActions>
                <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                    <Button onClick={onClose} variant={"outlined"}>
                        Cancel
                    </Button>
                    <Button variant={"contained"} color={"primary"} onClick={handleSubmit} disabled={isUploading}>
                        {isUploading ? <CircularProgress size={20} color={"inherit"}/> : "Save"}
                    </Button>
                </Stack>
            </DialogActions>
        </MainContentModal>
    );
}

AvatarEditor.propTypes = {
    /**
     * Existing image. Either as file or the image URL
     */
    imageExisting: PropTypes.any,
    onSubmit: PropTypes.func.isRequired
};

export default AvatarEditor;