import React from 'react';
import {Alert, Button, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import Input from "../../components/Input";
import {VALID_PASSWORD_REGEX} from "../../../constants/validators";
import * as yup from "yup";
import {Formik} from "formik";
import axios from "axios";
import {API_URN_CHANGE_PASSWORD, API_URN_PATIENT} from "../../../constants/apiURNs";
import {postOrPutErrorExtractor} from "../../../utils/httpErrorHelpers";
import PasswordInput from "../../components/PasswordInput";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {URN_PASSWORD_POLICY} from "../../../constants/clientURNs";

function ChangePasswordForm({onCancel, onSuccess}) {
    return (
        <Formik
            initialValues={{
                username: null,
                currentPassword: null,
                newPassword: null,
                confirmNewPassword: null
            }}
            validationSchema={yup.object().shape({
                username: yup.string().trim().required("Required").typeError("Required"),
                currentPassword: yup.string().trim().required("Required").typeError("Required"),
                newPassword: yup.string().required("Required").matches(VALID_PASSWORD_REGEX, "Password must be 8 characters or longer, containing: one capital letter, one lower case letter, one number, one special character “~!@#$%^&*()-_=+[]{}|;:,.<>/?”. The password cannot have more than 2 repeated characters.").typeError("Required"),
                confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), ""], 'Passwords must match').required("Required").typeError("Required")
            })}
            onSubmit={(values, formikHelpers) => {
                formikHelpers.setStatus({errorMessage: null});
                const baseUrl = process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_CHANGE_PASSWORD;
                axios.post(baseUrl, values)
                    .then(() => {
                        onSuccess();
                    })
                    .catch(error => {
                        const errorMessage = postOrPutErrorExtractor(error, formikHelpers);
                        formikHelpers.setStatus({errorMessage});
                        formikHelpers.setSubmitting(false);
                    })
            }}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  dirty,
                  errors, touched,
                  isSubmitting,
                  status,
                  setStatus
              }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input
                            name={"username"} label={"Username"} required
                            onChange={handleChange} onBlur={handleBlur}
                            value={values.username}
                            error={touched?.username && errors && errors.username !== undefined}
                            helperText={touched?.username && errors ? errors.username : undefined}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PasswordInput
                            name={"currentPassword"} label={"Existing Password"}
                            required value={values.currentPassword}
                            onChange={handleChange} onBlur={handleBlur} autoComplete={"current-password"}
                            error={touched?.currentPassword && errors && errors.currentPassword !== undefined}
                            helperText={touched?.currentPassword && errors ? errors.currentPassword : undefined}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PasswordInput
                            name={"newPassword"} label={"New Password"} autoComplete={"new-password"}
                            required value={values.newPassword}
                            onChange={handleChange} onBlur={handleBlur}
                            error={touched?.newPassword && errors && errors.newPassword !== undefined}
                            helperText={touched?.newPassword && errors ? errors.newPassword : undefined}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PasswordInput
                            name={"confirmNewPassword"} label={"Confirm New Password"} autoComplete={"new-password"}
                            required value={values.confirmNewPassword}
                            onChange={handleChange} onBlur={handleBlur}
                            error={touched?.confirmNewPassword && errors && errors.confirmNewPassword !== undefined}
                            helperText={touched?.confirmNewPassword && errors ? errors.confirmNewPassword : undefined}
                        />
                    </Grid>

                    {status?.errorMessage &&
                    <Grid item xs={12}>
                        <Alert severity={"error"} onClose={() => setStatus({errorMessage: null})}>
                            {status.errorMessage}
                        </Alert>
                    </Grid>
                    }

                    {(status?.errorMessage?.includes("password policy") || errors.newPassword) &&
                    <Grid item xs={12}>
                        <Typography fontSize={"small"}>
                            <Link to={URN_PASSWORD_POLICY} target={"_blank"}>Read Password Policy</Link>
                        </Typography>
                    </Grid>
                    }

                    <Grid item xs={12}>
                        <Stack direction={"row"} spacing={2}>
                            <Button onClick={onCancel} disabled={isSubmitting}>
                                Cancel
                            </Button>
                            <Button variant={"contained"} onClick={handleSubmit}
                                    endIcon={isSubmitting ? <CircularProgress size={20} color={"inherit"}/> : undefined}
                                    disabled={isSubmitting || !dirty}
                            >
                                Update Password
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}

ChangePasswordForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}
export default ChangePasswordForm;