import {toast} from "react-toastify";

const error4xx = new RegExp("4..");
const error5xx = new RegExp("5..");

const networkStats = navigator.onLine ? "ONLINE" : "OFFLINE"

/**
 * Extract content from the API errored response
 * @param error errors promise
 * @returns  error content string
 */
export function errorExtractor(error) {
    const {response} = error;
    let errorMessage;

    if (response) {
        const {status} = error.response;
        // if 4xx
        if (error4xx.test(status.toString())) {
            if (status === 401) {
                toast("Please login again", {type: "error"});
                window.location.reload();
            } else if (status === 403) {
                if (response.data && response.data.message)
                    errorMessage = response.data.message;
                else
                    errorMessage = "You are not authorized for this operation";
            } else if (status === 406)
                errorMessage = "Request not supported. Please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922."
            else {
                errorMessage = response.data.message;
            }
        }
        //if 5xx
        else if (error5xx.test(status.toString())) {
            errorMessage = "Something went wrong at server. Please try again";
        }
    } else {
        if (networkStats === "OFFLINE")
            errorMessage = "You are offline, please check your internet connection"

        else errorMessage = `Something went wrong. Please try again`;
    }

    return errorMessage;
}

export function postOrPutErrorExtractor(error, formikHelpers) {
    formikHelpers && formikHelpers.setSubmitting(false);
    let errorMessage = "";
    const {response} = error;
    if (response) {
        const {status, data} = response;
        try {
            if (error4xx.test(status.toString())) {
                if (status === 401) {
                    return "You need to login. Please refresh the page.";
                } else if (status === 403) {
                    errorMessage = "Access denied. You are not authorized for this operation"
                } else if (status === 404) {
                    if (data.code === 7004)
                        errorMessage = data.message;
                    else //means the API URL don't exist
                        errorMessage = "Something went wrong. It's us, please report the issue. Thanks"
                } else if (status === 405) {
                    errorMessage = "This operation is not supported at the moment. If this need our special attention, please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922."
                } else if (status === 406) {
                    errorMessage = "Something unexpected happened, please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922."
                } else {
                    if (data) {
                        if (data.code === 7001) {
                            if (data.failed) {
                                let errors = {};
                                const failedFields = data.failed;
                                failedFields.forEach((failedField) => {
                                    const fieldName = failedField.field;
                                    errors[fieldName] = failedField.message;
                                });
                                formikHelpers && formikHelpers.setErrors(errors);
                                return `You have some errors in the form, please review and fix. ${failedFields[0].field}: ${failedFields[0].message}`;
                            } else { //if validation error is sent as BadRequestException
                                errorMessage = data.message
                            }
                        } else if (data.code === 7011) {
                            errorMessage = "Something went wrong which require our special attention. Please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922"
                        } else {
                            errorMessage = data.message
                        }
                    } else {
                        errorMessage = "Something went wrong."
                    }
                }
            } else if (error5xx.test(status.toString())) {
                errorMessage = "Something went wrong at server. Please try after some time. If the issue still persist, please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922. Apologies for the inconvenience caused."
            }
        } catch (e) {
            if (networkStats === "OFFLINE")
                errorMessage = "You are offline, please check your internet connection"
            else errorMessage = "Something went wrong. Please try after some time. If the issue still persist, please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922"
        }
    }
    return errorMessage;
}


/**
 * Extract content from the API errored response and return the error as object of a shape.
 * @param error errors from API call
 * @returns {{status: number, title: string, description: string} pageError
 */
export function pageErrorExtractor(error) {
    let pageError = {
        status: null,
        title: null,
        description: null
    };

    try {
        const {response} = error;

        if (response) {
            const {status, data} = response;
            //if 4xx
            if (error4xx.test(status.toString())) {
                if (status === 404) {
                    pageError.status = 404;
                    pageError.title = data.message; //"Requested resource not found.";
                    pageError.description = "Requested resource not found. It has either been deleted or not available at the moment."
                } else if (status === 401) {
                    pageError.status = 401;
                    pageError.title = "Authentication Error";
                    pageError.description = "You are not authenticated for this request. Kindly login and try again."
                } else if (status === 403) {
                    pageError.status = 403;
                    pageError.title = "Authorization Error";
                    pageError.description = "You don't have required permissions to fulfill this request."
                } else if (status === 400) {
                    if (data) {
                        if (data.code === 7004) { //Details Not found
                            pageError.status = data.code;
                            pageError.title = "Details not found";
                            pageError.description = data.message;
                        } else {
                            pageError.status = data.code;
                            pageError.title = "Some error occurred";
                            pageError.description = data.message;
                        }
                    }
                } else {
                    pageError.status = response.status;
                    pageError.title = "Unknown Error";
                    pageError.description = "Something went wrong at browser. Please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922."
                }
            }
            //if 5xx
            else if (error5xx.test(status.toString())) {
                pageError.status = status;
                pageError.title = "Unknown Error";
                pageError.description = "Something went wrong at server. Please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922."
            }
            //other status codes
            else {
                pageError.status = status;
                pageError.title = "Unknown Error";
                pageError.description = "Something went wrong. Please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922."
            }
        } else {
            pageError.status = 0;
            pageError.title = "Unknown Error";
            pageError.description = "Something went wrong. Please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922."
        }
    } catch (e) {
        if (networkStats === "OFFLINE") {
            pageError.status = 0;
            pageError.title = "Lost internet connection";
            pageError.description = "You are offline, please check your internet connection"
        } else {
            pageError.status = 0;
            pageError.title = "Unknown Error";
            pageError.description = "Something went wrong. Kindly check your internet connection and refresh the page. If the issue still persist, Please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922."
        }
    }
    return pageError;
}