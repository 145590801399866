import {createGlobalStyle} from "styled-components";
import {createTheme} from "@mui/material";
import {buttonClasses} from "@mui/material/Button";
import {toggleButtonClasses} from "@mui/material/ToggleButton";

const defaultTheme = createTheme();

export const muiCustomTheme = createTheme({
    palette: {
        primary: {
            main: "#026DA9",
        },
        secondary: {
            main: "#72a94e",
            contrastText: "#FFF"
        },
        action: {
            disabledBackground: "", // don't set the disable background color
            disabled: "", // set the disable foreground color
        },
        background: {
            paper: "#FFF",
            default: "#F5F5F5"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    textTransform: "none",
                    [`&.${buttonClasses.disabled}`]: {
                        opacity: 0.7
                    },
                    // Fix ButtonGroup disabled styles.
                    [`&.${toggleButtonClasses.root}.${buttonClasses.disabled}`]: {
                        color: defaultTheme.palette.action.disabled,
                        borderColor: defaultTheme.palette.action.disabledBackground
                    }
                }
            }
        }
    },
    overrides: {
        MuiFormControl: {
            root: {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: 5
                },
                '& .MuiFormLabel-root': {
                    fontWeight: "bold",
                    fontSize: 14,
                    marginBottom: 5,
                },
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "bold",
                fontSize: 14
            }
        },
        MuiButton: {
            root: {
                textTransform: "none"
            }
        }
    },
    shadows: [
        "none",
        "rgba(149, 157, 165, 0.2) 0 1px 3px",
        "rgba(149, 157, 165, 0.2) 0 4px 12px",
        "rgba(149, 157, 165, 0.2) 0 6px 18px",
        "rgba(149, 157, 165, 0.2) 0 8px 24px",
        "rgba(149, 157, 165, 0.2) 0 10px 30px",
        "rgba(149, 157, 165, 0.2) 0 12px 36px",
        "rgba(149, 157, 165, 0.2) 0 14px 42px",
        "rgba(149, 157, 165, 0.2) 0 16px 48px",
        "rgba(149, 157, 165, 0.2) 0 18px 54px",
        "rgba(149, 157, 165, 0.2) 0 20px 60px",
        "rgba(149, 157, 165, 0.2) 0 22px 66px",
        "rgba(149, 157, 165, 0.2) 0 24px 72px",
        "rgba(149, 157, 165, 0.2) 0 26px 78px",
        "rgba(149, 157, 165, 0.2) 0 28px 84px",
        "rgba(149, 157, 165, 0.2) 0 30px 90px",
        "rgba(149, 157, 165, 0.2) 0 32px 96px",
        "rgba(149, 157, 165, 0.2) 0 34px 102px",
        "rgba(149, 157, 165, 0.2) 0 36px 108px",
        "rgba(149, 157, 165, 0.2) 0 38px 114px",
        "rgba(149, 157, 165, 0.2) 0 40px 120px",
        "rgba(149, 157, 165, 0.2) 0 42px 126px",
        "rgba(149, 157, 165, 0.2) 0 44px 132px",
        "rgba(149, 157, 165, 0.2) 0 46px 138px",
        "rgba(149, 157, 165, 0.2) 0 48px 144px",
        "rgba(149, 157, 165, 0.2) 0 50px 150px"
    ]
});

export const GlobalStyle = createGlobalStyle`
  html {
    --height-header: 60px;
  }

  //override default label size.
  .MuiInputLabel-root {
    font-size: 1rem !important;
  }

  textarea {
    border-radius: 5px;
  }

  ._capitalize {
    text-transform: capitalize;
  }

  ._readOnly {
    pointer-events: none;
  }

`;