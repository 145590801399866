export const VALID_NAME_REGEX = new RegExp(
    "^[a-zA-Z- ]+$",
    "i"
);

export const VALID_USERNAME_REGEX = new RegExp(
    "^\\w{3,}$",
    "i"
);

export const VALID_EMAIL_REGEX = new RegExp(
    "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-z0-9!#$%&'*+/=?^_`{|}~-]+(\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)+|\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])$",
    "i"
);

/**
 * This is taken from Ping. Can be fetched at '/signup/password-policy'
 */
export const VALID_PASSWORD_REGEX = new RegExp(
    "^(?:(?=(?:.*[~!@#\\$%\\^&\\*\\(\\)\\-_=\\+\\[\\]\\{\\}\\|;:,\\.<>/\\?]){1,})(?=(?:.*[0123456789]){1,})(?=(?:.*[ABCDEFGHIJKLMNOPQRSTUVWXYZ]){1,})(?=(?:.*[abcdefghijklmnopqrstuvwxyz]){1,}))(?!.*(.)\\1{2,}).{8,255}$",
    "g"
);
