import React, {useCallback, useEffect, useState} from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    capitalize,
    CircularProgress,
    debounce,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import {PageContentWrapper} from "../../styles/page";
import AvatarFromBytes from "../../components/AvatarFromBytes";
import {useDispatch, useSelector} from "react-redux";
import {AUTHENTICATED_USER_TYPE} from "../../../constants/portalUserType";
import {Redirect, useHistory} from "react-router-dom";
import {URN_HEALTH_SUMMARY, URN_UN_AUTHORIZED} from "../../../constants/clientURNs";
import {setCurrentPatientDetails} from "../../../redux/actions/authActions";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from "@mui/icons-material/ClearOutlined";
import {searchPatientUsingNameApi} from "../../../api/patientSearch";

function AdminHomePage() {
    const theme = useTheme();
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const {role} = useSelector(state => state?.auth);
    const dispatchAction = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatchAction(setCurrentPatientDetails(null, null));
    }, [])

    const handlePatientAccess = (patientUsername, name) => {
        dispatchAction(setCurrentPatientDetails(patientUsername, name));
        history.push(URN_HEALTH_SUMMARY)
    }

    const search = useCallback(
        debounce((inputValue) => {
            if (inputValue) {
                setIsLoading(true);
                searchPatientUsingNameApi(inputValue)
                    .then(({data}) => {
                        setIsLoading(false);
                        setSearchResults(data);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    })
            } else
                setSearchResults([]);
        }, 500),
        []
    )

    if (role !== "UNSET" && role !== AUTHENTICATED_USER_TYPE.ADMIN)
        return <Redirect to={URN_UN_AUTHORIZED}/>

    const handleInputChange = (e) => {
        setInputValue(e?.target?.value);
        search(e?.target?.value);
        setIsLoading(Boolean(e?.target?.value));
    }

    const handleInputClear = () => {
        setInputValue("");
        setSearchResults([]);
    }

    return (
        <PageContentWrapper>
            <Typography variant={"h5"}>Admin Home</Typography>
            <Paper elevation={0} sx={{maxWidth: 1000, paddingX: 2, paddingY: 1, minHeight: "80vh", marginY: 2}}>
                <Box marginY={2}>
                    <TextField
                        value={inputValue} variant={"outlined"} fullWidth
                        placeholder={"Search patients using below criteria"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading
                                        ? <CircularProgress color={"primary"} size={25}/>
                                        : <Tooltip title={"Clear"}>
                                            <IconButton onClick={handleInputClear}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box className={"_searchResults"}>
                    {searchResults?.length > 0
                        ? <Box>
                            {isLoading ? <Typography gutterBottom>Loading...</Typography> : <Typography gutterBottom>{searchResults?.length} Results</Typography>}
                            <List>
                                {Array.isArray(searchResults) &&
                                searchResults?.filter(patient => Boolean(patient?.username))
                                    ?.map((patient, i) => {
                                        const {
                                            firstname,
                                            lastname,
                                            displayPictureUrl,
                                            id,
                                            username,
                                            dob
                                        } = patient ?? {};
                                        return (
                                            <React.Fragment key={`patient-${id}-${i}`}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <AvatarFromBytes
                                                            avatarUrl={displayPictureUrl}
                                                            initials={`${capitalize(firstname?.slice(0, 1) ?? "")} ${capitalize(lastname?.slice(0, 1) ?? "")}`}
                                                            sx={{
                                                                backgroundColor: theme.palette.primary.main,
                                                                height: 50,
                                                                width: 50
                                                            }}/>
                                                    </ListItemAvatar>
                                                    <ListItemText>
                                                        <Box display={"flex"} flexDirection={"column"}>
                                                            <Typography fontWeight={500} fontSize={"large"}>
                                                                {capitalize(firstname)} {capitalize(lastname ?? "")}
                                                            </Typography>
                                                            <Box>
                                                                <Typography
                                                                    color={"textSecondary"}><strong>Username:&nbsp;</strong>{username}
                                                                </Typography>
                                                                <Typography
                                                                    color={"textSecondary"}><strong>DOB:&nbsp;</strong>{dob}
                                                                </Typography>
                                                            </Box>
                                                            <Box marginTop={2}>
                                                                <Stack direction={"row"} spacing={2}>
                                                                    <Button variant={"outlined"}
                                                                            endIcon={<ArrowForwardIcon
                                                                                color={"inherit"}/>}
                                                                            onClick={() => handlePatientAccess(username, `${capitalize(firstname)} ${capitalize(lastname ?? "")}`)}>
                                                                        Access Patient Details
                                                                    </Button>
                                                                </Stack>
                                                            </Box>
                                                        </Box>
                                                    </ListItemText>
                                                </ListItem>
                                                {i + 1 < searchResults?.length &&
                                                <Divider sx={{marginY: 2}} variant={"middle"}/>
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                            </List>
                        </Box>
                        : <Box>
                            {inputValue &&
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={100}>
                                {isLoading
                                    ? <CircularProgress color={"primary"} size={25}/>
                                    : <Typography>No Results</Typography>
                                }
                            </Box>
                            }
                            <Alert severity={"info"}>
                                <AlertTitle>Search patients using:</AlertTitle>
                                <Box component={"ol"} sx={{"& li": {marginY: 1, fontSize: "1rem"}}}>
                                    <li>First Name</li>
                                    <li>Last Name</li>
                                    <li>Full Name</li>
                                    <li>Username</li>
                                    <li>Email</li>
                                    <li>Phone number (either Primary, Home or Work)</li>
                                    <li>MRN</li>
                                    <li>Date of Birth (<strong>Must</strong> be in format YYYY-MM-DD, eg. 1950-08-20)</li>
                                </Box>
                            </Alert>
                        </Box>
                    }
                </Box>
            </Paper>
        </PageContentWrapper>
    );
}

export default AdminHomePage;