import { call, put, select, takeLatest } from 'redux-saga/effects';
import { INSURANCE } from '../actions/ActionTypes';
import { setCacheExpiryTime, setData, setError, setLoading } from '../actions/insuranceActions';
import { pageErrorExtractor } from '../../utils/httpErrorHelpers';
import { fetchInsurances } from '../../api/insurance';
import { add, isAfter } from 'date-fns';
import { AUTHENTICATED_USER_TYPE } from '../../constants/portalUserType';

function* getPatientInsurances() {
    const {currentPatientUsername, role} = yield select(state => state?.auth);
    const {error, cacheExpiryTime} = yield select(state => state?.insurance);
    const isCacheExpired = isAfter(new Date(), new Date(cacheExpiryTime));
    if (role === AUTHENTICATED_USER_TYPE.ADMIN || error || (cacheExpiryTime === null) || isCacheExpired) {
        try {
            yield put(setError(null));
            yield put(setLoading(true));
            const response = yield call(fetchInsurances, currentPatientUsername);
            const data = yield response.data;
            yield put(setData(data))
            yield put(setLoading(false));
            //Set cache expiry time. Cache is valid for 2 hours
            yield put(setCacheExpiryTime(add(new Date(), {hours: 2}).toString()))
        } catch (error) {
            if ((error?.response?.status === 404 || error?.response?.data?.code === 7004)) {
                yield put(setData({}));
            } else {
                yield put(setError(pageErrorExtractor(error)));
            }
            yield put(setLoading(false));
        }
    }
}

function* insuranceSaga() {
    yield takeLatest(INSURANCE.FETCH_ALL, getPatientInsurances);
}

export default insuranceSaga;