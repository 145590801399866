import React, {useState} from 'react';
import {Alert, Box, Button, CircularProgress, Divider, Typography} from "@mui/material";
import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";
import {URN_AUTH, URN_SIGNUP} from "../../../constants/clientURNs";
import axios from "axios";
import {useDispatch} from "react-redux";
import {idpRequestSuccess_saga} from "../../../redux/actions/authActions";
import {toast} from "react-toastify";
import {API_URN_LOGIN_REQUEST, API_URN_PATIENT} from "../../../constants/apiURNs";
import {errorExtractor} from "../../../utils/httpErrorHelpers";
import {SIGN_OFF_MESSAGE_QP} from "../../../constants/queryParams";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const SignOffWrapper = styled.div`
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiButton-root {
    width: 200px;
  }
`;


function SignOff() {
    const [isRequestingLogin, setRequestingLogin] = useState(false);

    const history = useHistory();
    const dispatchAction = useDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const signOffMessage = params.get(SIGN_OFF_MESSAGE_QP);

    let retryCount = 0;

    /**
     * Request IDP for flowId.
     */
    const handleLogin = () => {
        setRequestingLogin(true);
        axios({
            method: "GET",
            withCredentials: retryCount === 0, //on retry, send request without credentials
            baseURL: process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_LOGIN_REQUEST
        }).then(({data}) => {
            dispatchAction((idpRequestSuccess_saga(data, history)));
        }).catch(error => {
            ++retryCount;
            if (retryCount < 2) {
                handleLogin();
            } else {
                setRequestingLogin(false);
                toast.error(errorExtractor(error), {position: "top-center", autoClose: 3000});
                toast.info("Please try again", {position: "top-center", autoClose: 5000});
            }
        })
    }

    return (
        <>
            <SignOffWrapper>
                {signOffMessage &&
                <Box marginY={2}>
                    <Alert severity={"info"}>{signOffMessage}</Alert>
                </Box>
                }
                <Box marginBottom={2}>
                    <Typography variant={"h5"} component={"h5"} className={"_header"}>
                        Welcome to Nufactor Home Base
                    </Typography>
                </Box>
                <Button
                    color={"primary"} variant={"contained"}
                    endIcon={isRequestingLogin
                        ? <CircularProgress color={"inherit"} size={20}/>
                        : <ArrowForwardIcon color={"inherit"} fontSize={"small"}/>
                    }
                    onClick={handleLogin} disabled={isRequestingLogin}
                >
                    Login
                </Button>
                <Divider variant={"middle"} flexItem sx={{marginY: 2}}/>
                <Button
                    color={"primary"} variant={"outlined"}
                    onClick={() => history.push(URN_AUTH + URN_SIGNUP)}
                >
                    Create Account
                </Button>
            </SignOffWrapper>
        </>
    );
}

export default SignOff;