import { MESSAGE_CENTER, RESET } from '../actions/ActionTypes';

const defaultState = {
    isLoading: false,
    error: null, //object
    data: [],
    cacheExpiryTime: null,
    isLoadingMore: false, // Loading state for the new incoming messages which are not yet present on Message Center page
};

export const messageCenterReducer = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case MESSAGE_CENTER.SET_DATA: {
            const { data } = payload;
            return { ...state, data };
        }

        case MESSAGE_CENTER.SET_LOADING: {
            const { isLoading } = payload;
            return { ...state, isLoading };
        }

        case MESSAGE_CENTER.SET_LOADING_MORE: {
            const { isLoadingMore } = payload;
            return { ...state, isLoadingMore };
        }

        case MESSAGE_CENTER.SET_PAGE_ERROR: {
            const { error } = payload;
            return { ...state, error };
        }

        case MESSAGE_CENTER.SET_CACHE_EXPIRY_TIME: {
            const { cacheExpiryTime } = payload;
            return { ...state, cacheExpiryTime };
        }

        case MESSAGE_CENTER.SET_MESSAGES_READ: {
            const { messageIds } = payload;
            const updated = state?.data?.map(message => {
                if (messageIds.includes(message?.id))
                    return { ...message, isRead: messageIds.includes(message?.id) };
                return message;
            });
            return { ...state, data: updated };
        }

        case RESET: {
            return { ...defaultState };
        }

        default:
            return { ...state };
    }
};