import React, {useState} from 'react';
import styled from "styled-components";
import {FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";

const OrderDetailsWrapper = styled.div`
  margin: 0.5rem;
  padding: 0.5rem;
  position: relative;
  background-color: white;
  overflow: hidden;

  ._close {
    position: absolute;
    top: .5rem;
    right: .5rem;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  :focus {
    outline: none;
  }
`;

function Payment() {
    const [payment, setPayment] = useState("visa");

    return (
        <OrderDetailsWrapper>
            <Typography variant={"h6"} color={"textSecondary"}>Payment Method</Typography>
            <RadioGroup name="paymentMethod" defaultValue={"visa"} value={payment} onChange={e => setPayment(e.target.value)}>
                <FormControlLabel value="visa" control={<Radio/>} label="Visa"/>
                <FormControlLabel value="invoice" control={<Radio/>} label="Bill me through an invoice"/>
                <FormControlLabel value="paypal" control={<Radio/>} label="Use PayPal for this order"/>
            </RadioGroup>
        </OrderDetailsWrapper>

    );
}

export default Payment;