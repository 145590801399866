import {CARE_TEAM, RESET} from "../actions/ActionTypes";

const defaultState = {
    isLoading: false,
    error: null, //object
    data: {
        css: null,
        rph: null,
        cnc: null
    },
    cacheExpiryTime: null,
    pcdata: {
        emergencyContacts: [],
        nonEmergencyContacts: []
    }
}

export const careTeamReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case CARE_TEAM.SET_DATA: {
            const {data} = payload;
            return {...state, data}
        }
        case CARE_TEAM.SET_LOADING: {
            const {isLoading} = payload;
            return {...state, isLoading}
        }

        case CARE_TEAM.SET_PAGE_ERROR: {
            const {error} = payload;
            return {...state, error}
        }

        case CARE_TEAM.SET_CACHE_EXPIRY_TIME: {
            const {cacheExpiryTime} = payload;
            return {...state, cacheExpiryTime}
        }
        case CARE_TEAM.SET_PC_DATA: {
            const {pcdata} = payload;
            return {...state, pcdata}
        }

        case RESET: {
            return {...defaultState}
        }

        default:
            return {...state};
    }
}