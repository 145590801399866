import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
    URN_HEALTH_SUMMARY,
    URN_HEALTH_WELLNESS,
    URN_INSURANCE,
    URN_MY_CARE,
    URN_PRESCRIPTION,
} from '../../../constants/clientURNs';
import { Badge, Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, styled, Toolbar } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as HealthSnapshotIcon } from '../../../assets/icons/HealthSnapshotIcon.svg';
import { ReactComponent as CareTeamIcon } from '../../../assets/icons/CareTeamIcon.svg';
import { ReactComponent as HealthWellnessIcon } from '../../../assets/icons/HealthWellnessIcon.svg';
import { ReactComponent as InsuranceIcon } from '../../../assets/icons/InsuranceIcon.svg';
import { ReactComponent as PrescriptionsIcon } from '../../../assets/icons/PrescriptionsIcon.svg';
// import {ReactComponent as ResultsIcon} from "../../../assets/icons/ResultsIcon.svg";
import logo from '../../../assets/patient-portal-logo.png';

const SideBarNavsWrapper = styled(Box)`
  ._items {
    padding-right: ${({theme}) => theme.spacing(3)};

    ._item {
      color: rgba(0, 0, 0, .8);
      border-radius: 0 5px 5px 0;
      transition: all .3s linear;
      border-left: 5px solid transparent;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;

      .MuiListItemText-root {
        margin-top: 0;
        margin-bottom: 0;
      }

      .MuiBadge-root {
        .MuiBadge-badge {
          top: 0;
          height: 1rem;
          max-height: 1rem;
          min-width: 1rem;
          max-width: 1rem;
          right: -5px;
          font-size: 10px;
          font-weight: 500;
        }
      }

      &._active_navigation {
        border-left: 5px solid ${({theme}) => theme.palette.secondary.main};

        .MuiBadge-root {
          .MuiBadge-badge {
            right: -10px;
          }
        }
      }
    }
  }

  .MuiListItemIcon-root {
    min-width: 23px;
  }

  .MuiListItemText-root {
    margin-left: 1rem;
  }

  ${({theme}) => ({
    [theme.breakpoints.down("md")]: {
      display: "block"
    }
  })}
`;

function SideBarNavs({openDrawer, isChildOfDrawer}) {
    const [notifications, setNotifications] = useState({
        schedule: 0,
        insurance: 0,
        results: 0
    });

    //close drawer
    const closeDrawer = () => {
        openDrawer && openDrawer(false);
    }

    return (
        <SideBarNavsWrapper className={"SideBarNavs"}>
            <Toolbar>
                <Link to={"/"}>
                    <Box
                        component={"img"} src={logo} alt='Nufactor' onClick={closeDrawer}
                        sx={{height: "30px", display: isChildOfDrawer ? "block" : "none"}}
                    />
                </Link>
            </Toolbar>
            <List className="_items">
                <ListItemButton component={NavLink} to={URN_HEALTH_SUMMARY} className={"_item"}
                                activeClassName={"_active_navigation"}
                                onClick={closeDrawer} >
                    <ListItemIcon><HealthSnapshotIcon/></ListItemIcon>
                    {isChildOfDrawer && <ListItemText primary="Health Summary"/>}
                </ListItemButton>
                <Divider variant={"middle"}/>
                {/*<ListItemButton component={NavLink} to={URN_SCHEDULE} className={"_item"}*/}
                {/*          activeClassName={"_active_navigation"}*/}
                {/*          onClick={closeDrawer}>*/}
                {/*    <Badge badgeContent={notifications.schedule} color={"primary"} overlap="rectangular"*/}
                {/*           onClick={closeDrawer}>*/}
                {/*        <ListItemIcon><ScheduleIcon/></ListItemIcon>*/}
                {/*        {isChildOfDrawer && <ListItemText>Schedule</ListItemText>}*/}
                {/*    </Badge>*/}
                {/*</ListItemButton>*/}
                {/*<Divider variant={"middle"}/>*/}
                <ListItemButton component={NavLink} to={URN_MY_CARE} className={"_item"}
                                activeClassName={"_active_navigation"}
                                onClick={closeDrawer}>
                    <ListItemIcon><CareTeamIcon/></ListItemIcon>
                    {isChildOfDrawer && <ListItemText primary="My Care Team"/>}
                </ListItemButton>
                <Divider variant={"middle"}/>
                <ListItemButton component={NavLink} to={URN_PRESCRIPTION} className={"_item"}
                                activeClassName={"_active_navigation"}
                                onClick={closeDrawer}>
                    <ListItemIcon><PrescriptionsIcon/></ListItemIcon>
                    {isChildOfDrawer && <ListItemText primary="Prescriptions & Supplies"/>}
                </ListItemButton>
                <Divider variant={"middle"}/>
                <ListItemButton component={NavLink} to={URN_INSURANCE} className={"_item"}
                                activeClassName={"_active_navigation"}
                                onClick={closeDrawer}>
                    <Badge badgeContent={notifications.insurance} color={"primary"} overlap="rectangular">
                        <ListItemIcon><InsuranceIcon/></ListItemIcon>
                        {isChildOfDrawer && <ListItemText>Insurance</ListItemText>}
                    </Badge>
                </ListItemButton>
                <Divider variant={"middle"}/>
                {/*<ListItemButton component={NavLink} to={URN_RESULTS} className={"_item"}*/}
                {/*          activeClassName={"_active_navigation"}*/}
                {/*          onClick={closeDrawer}>*/}
                {/*    <Badge badgeContent={notifications.results} color={"primary"} overlap="rectangular">*/}
                {/*        <ListItemIcon><ResultsIcon/></ListItemIcon>*/}
                {/*        {isChildOfDrawer && <ListItemText>Results</ListItemText>}*/}
                {/*    </Badge>*/}
                {/*</ListItemButton>*/}
                {/*<Divider variant={"middle"}/>*/}
                <ListItemButton component={NavLink} to={URN_HEALTH_WELLNESS} className={"_item"}
                                activeClassName={"_active_navigation"}
                                onClick={closeDrawer}>
                    <ListItemIcon><HealthWellnessIcon/></ListItemIcon>
                    {isChildOfDrawer && <ListItemText>Health & Wellness</ListItemText>}
                </ListItemButton>
                {/*<Divider variant={"middle"}/>*/}
                {/*<ListItemButton component={NavLink} to={URN_EDUCATION_TRAINING} className={"_item"}*/}
                {/*          activeClassName={"_active_navigation"}*/}
                {/*          onClick={closeDrawer}>*/}
                {/*    <ListItemIcon><EducationTrainingIcon/></ListItemIcon>*/}
                {/*    {isChildOfDrawer && <ListItemText>Education & Training</ListItemText>}*/}
                {/*</ListItemButton>*/}
                {/*<Divider variant={"middle"}/>*/}
                {/*<ListItemButton component={NavLink} to={URN_INFUSION_JOURNAL} className={"_item"}
                                activeClassName={"_active_navigation"}
                                onClick={closeDrawer}>
                    <ListItemIcon><InfusionIcon/></ListItemIcon>
                    {isChildOfDrawer && <ListItemText>Infusion Journal</ListItemText>}
                </ListItemButton>*/}
            </List>
        </SideBarNavsWrapper>
    );
}

SideBarNavs.propTypes = {
    openDrawer: PropTypes.func,
    /**
     * If this component is a child of drawer
     */
    isChildOfDrawer: PropTypes.bool
}

export default SideBarNavs;