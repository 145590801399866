import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authReducer } from './authReducer';
import { patientReducer } from './patientReducer';
import { resultsReducer } from './resultsReducer';
import { siteSettingReducer } from './siteSettingReducer';
import { headerReducer } from './headerReducer';
import { insuranceReducer } from './insuranceReducer';
import { healthSummaryReducer } from './healthSummaryReducer';
import { careTeamReducer } from './careTeamReducer';
import { messageCenterReducer } from './messageCenterReducer';
import { prescriptionsReducer } from './prescriptionsReducer';
import { infusionJournalReducer } from './infusionJournalReducer';
import { personalContactsReducer } from './personalContactsReducer';
import { suppliesReducer } from './suppliesReducer';

const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        header: headerReducer,
        me: patientReducer,
        results: resultsReducer,
        siteSettings: siteSettingReducer,
        insurance: insuranceReducer,
        healthSummary: healthSummaryReducer,
        careTeam: careTeamReducer,
        messageCenter: messageCenterReducer,
        prescriptions: prescriptionsReducer,
        supplies: suppliesReducer,
        infusionJournal: infusionJournalReducer,
        personalContacts: personalContactsReducer,
    });

export default rootReducer;
