import { INSURANCE, RESET } from '../actions/ActionTypes';

const defaultState = {
    isLoading: false,
    error: null,
    data: [],
    cacheExpiryTime: null
}
export const insuranceReducer = (state = defaultState, action) => {
    const {type, payload} = action;

    switch (type) {
        case INSURANCE.SET_DATA: {
            const {data} = payload;
            return {...state, data}
        }
        case INSURANCE.SET_LOADING : {
            const {isLoading} = payload;
            return {...state, isLoading: isLoading}
        }

        case INSURANCE.SET_PAGE_ERROR: {
            const {error} = payload;
            return {...state, error}
        }

        case INSURANCE.SET_CACHE_EXPIRY_TIME: {
            const {cacheExpiryTime} = payload;
            return {...state, cacheExpiryTime}
        }

        case RESET: {
            return {...defaultState}
        }

        default:
            return {...state};
    }
}