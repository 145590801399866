import {useEffect} from 'react';

function useScrollToTop(shouldScroll) {
    useEffect(() => {
        if ((shouldScroll === undefined || shouldScroll)) {
            window.scroll({top: 0, left: 0, behavior: "auto"})
        }
    }, []);
}

export default useScrollToTop;