import {HEALTH_SUMMARY, RESET} from "../actions/ActionTypes";

const defaultState = {
    isLoading: true,
    error: null, //object
    cacheExpiryTime: null,
    data: {
        currentMedications: [
            {
                name: null,
                quantity: null,
                frequency: null,
                route: null,
                comments: null,
                startDate: null,
                stopDate: null //if null, that means the medication is active
            }
        ],
        currentConditions: [
            {
                name: null,
                description: null
            }
        ],
        currentAllergies: {
            allergies: [null],
            otherAllergy: [null]
        }
    },
}

export const healthSummaryReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case HEALTH_SUMMARY.SET_DATA: {
            const {data} = payload;
            return {...state, data}
        }
        case HEALTH_SUMMARY.SET_LOADING: {
            const {isLoading} = payload;
            return {...state, isLoading}
        }

        case HEALTH_SUMMARY.SET_PAGE_ERROR: {
            const {error} = payload;
            return {...state, error}
        }

        case HEALTH_SUMMARY.SET_CACHE_EXPIRY_TIME: {
            const {cacheExpiryTime} = payload;
            return {...state, cacheExpiryTime}
        }

        case RESET: {
            return {...defaultState}
        }

        default:
            return {...state};
    }
}