import React, { useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    URN_ADMIN_HOME,
    URN_HEALTH_SUMMARY,
    URN_LOGIN,
    URN_SIGN_OFF,
    URN_SIGNUP,
    URN_UN_AUTHORIZED,
} from '../../../constants/clientURNs';
import LoginForm from './login/LoginForm';
import SignupForm from './signup/SignupForm';
import SignOff from './SignOff';
import logo from '../../../assets/patient-portal-logo.png';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '../../../constants/localstorage';
import { differenceInHours } from 'date-fns';
import { AUTHENTICATED_USER_TYPE } from '../../../constants/portalUserType';
// import AppStoreImg from '../../../assets/app-store.png';
// import GooglePlayImg from '../../../assets/google-play.png';

const AuthPageWrapper = styled('div')`
  display: flex;
  flex: 1;
  background: ${({ theme }) => theme.palette.background.paper};
`;

const SectionOneWrapper = styled('section')`
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;

  ._logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      height: 50px;
      margin-bottom: 2rem;
    }
  }

  ._appStore {
    max-height: 40px;
    justify-content: center;
    align-items: center;

    a {
      margin: 0 1rem;
      transition: all .1s linear;

      img {
        height: 80px;
        transition: all .1s linear;

        :hover {
          transform: scale(1.1);
        }
      }
    }
  }
`;

const SectionTwoWrapper = styled('section')`
  display: none;
  flex: 1;
  background-image: ${({ bg_image }) => `url(${bg_image})`};
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  ${({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  })}
`;

export const AuthFormWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ._header {
    padding: ${({ theme }) => theme.spacing(1)};
  }

  ._form_wrapper {
    padding: ${({ theme }) => theme.spacing(2)};
    display: flex;
    justify-content: center;

    ._terms {
      .MuiFormControlLabel-label {
        font-size: 12px !important;
      }
    }
  }

  ._actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.palette.divider};
    margin-top: 1rem;
    padding: 1rem;
  }
`;

export default function AuthPage() {
    const match = useRouteMatch();
    const { id } = match.params;
    const isLogin = id === URN_LOGIN.substr(1);
    const isSignup = id === URN_SIGNUP.substr(1);
    const isSignOff = id === URN_SIGN_OFF.substr(1);

    const { isAuthenticated, role } = useSelector(state => state?.auth);

    /**
     * Below is the logic to rotate bg image every 24hrs.
     * If the previous image is older then 24hrs, replace it with another.
     * Total three images are there, patient-portal-home-01, patient-portal-home-02, patient-portal-home-03.
     * If the previous image was **-01, replaced it with **-02 and so on.
     */
    const [bgImage, setBgImage] = useLocalStorage(LOCAL_STORAGE_KEYS.AUTH_PAGE_IMAGE, window.localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_PAGE_IMAGE));
    useEffect(() => {
        if (!bgImage) {
            const bgImageNew = {
                img: 'patient-portal-home-01',
                time: new Date(), //time this image got set at
            };
            setBgImage(bgImageNew);
        } else {
            const { img, time } = bgImage ?? {};
            if (differenceInHours(new Date(), new Date(time)) > 24) {
                const bgImageNew = {
                    time: new Date(),
                };
                switch (img) {
                    case 'patient-portal-home-01':
                        bgImageNew.img = 'patient-portal-home-02';
                        break;
                    case 'patient-portal-home-02':
                        bgImageNew.img = 'patient-portal-home-03';
                        break;
                    case 'patient-portal-home-03':
                        bgImageNew.img = 'patient-portal-home-01';
                        break;
                    default:
                        bgImageNew.img = 'patient-portal-home-01';
                }
                setBgImage(bgImageNew);
            }
        }
    }, []);

    if (isAuthenticated) {
        switch (role) {
            case AUTHENTICATED_USER_TYPE.PATIENT:
                return <Redirect to={URN_HEALTH_SUMMARY} />;
            case AUTHENTICATED_USER_TYPE.ADMIN:
                return <Redirect to={URN_ADMIN_HOME} />;
            default:
                return <Redirect to={URN_UN_AUTHORIZED} />;
        }
    }

    //Based on the value stored in local storage, pick the bg image from assets folder
    let bgImageFromLocal;
    if (Boolean(bgImage)) {
        let tempImg = require(`../../../assets/${bgImage.img}.jpg`);
        if (typeof tempImg === 'string')
            bgImageFromLocal = tempImg;
        if (typeof tempImg === 'object')
            bgImageFromLocal = tempImg?.default;
    }

    return (
        <Box height={'100%'} display={'flex'} flexDirection={'column'}>
            <Box minHeight={20} maxHeight={20} bgcolor={'secondary.main'} />
            <AuthPageWrapper>
                <SectionOneWrapper component={'section'} className={'_sectionOne'}>
                    <Box flex={1}>
                        <AuthFormWrapper>
                            <Box className={'_logo'}>
                                <img src={logo} alt='Nufactor' />
                            </Box>
                            {isLogin && <LoginForm />}
                            {isSignup && <SignupForm />}
                            {isSignOff && <SignOff />}
                        </AuthFormWrapper>
                    </Box>
                    {/*<Box marginY={2}>*/}
                    {/*    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>*/}
                    {/*        <Stack direction={"row"} className={"_appStore"}>*/}
                    {/*            <Typography variant={"subtitle1"}>Download our App</Typography>*/}
                    {/*            <a href="https://www.apple.com/in/app-store/" target={"_blank"} rel={"noreferrer"}>*/}
                    {/*                <img src={AppStoreImg} alt="Get Android app"/>*/}
                    {/*            </a>*/}
                    {/*            <a href="https://play.google.com/store" target={"_blank"} rel={"noreferrer"}>*/}
                    {/*                <img src={GooglePlayImg} alt="Get Android app"/>*/}
                    {/*            </a>*/}
                    {/*        </Stack>*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                </SectionOneWrapper>
                <SectionTwoWrapper bg_image={bgImageFromLocal} />
            </AuthPageWrapper>
            <Box minHeight={100} maxHeight={100} sx={{ backgroundColor: '#636363' }} />
        </Box>
    );
}
