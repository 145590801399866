import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, CardHeader, Chip, Stack, Typography, useTheme} from "@mui/material";
import {formatDate} from "../../../utils/dates";

function InsuranceCard({data}) {
    const theme = useTheme();
    const {effectiveEndDate, effectiveStartDate, payerName, status, memberName, groupNumber, policyNumber,insuranceName} = data;
    const isActive = status && status.toLowerCase() === "active";

    return (
        <Card sx={{
            maxWidth: 600,
            wordBreak: "break-all",
            "& ._item":{
              display: "flex",
              flexDirection: "column"
            },
            "& ._title": {
                wordBreak: "keep-all",
                color: theme.palette.text.secondary,
                wordWrap: "normal"
            }
        }}>
            <CardHeader
                title={
                    <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                        <Typography variant={"h6"} color={"primary"} sx={{textTransform: "uppercase"}}>
                            {insuranceName}
                        </Typography>
                    </Box>
                }
            />
            <CardContent>
                <Stack spacing={2}>
                    <Box className={"_item"}>
                        <Typography className={"_title"}>Payer Type</Typography>
                        <Typography>{payerName}</Typography>
                    </Box>
                    <Box className={"_item"}>
                        <Typography className={"_title"}>Member Name</Typography>
                        <Typography>{memberName}</Typography>
                    </Box>
                    <Box className={"_item"}>
                        <Typography className={"_title"}>Group Number</Typography>
                        <Typography>{groupNumber}</Typography>
                    </Box>
                    <Box className={"_item"}>
                        <Typography className={"_title"}>Policy Number</Typography>
                        <Typography>{policyNumber}</Typography>
                    </Box>
                    <Box className={"_item"}>
                        <Typography className={"_title"}>Effective Start Date</Typography>
                        <Typography>{formatDate(effectiveStartDate)}</Typography>
                    </Box>
                    <Box className={"_item"}>
                        <Typography className={"_title"}>Effective End Date</Typography>
                        <Typography>{formatDate(effectiveEndDate)}</Typography>
                    </Box>
                    <Box className={"_item"}>
                        <Typography className={"_title"}>Status</Typography>
                        {status
                            ? <Chip label={status} color={isActive ? "success" : "error"} sx={{borderRadius: 1, maxWidth: 150}}/>
                            : <Typography color={"error"} sx={{wordBreak: "break-word"}}>
                                The insurance on file is not showing as active. Please call Nufactor technical support
                                at 1-800-323-6832
                            </Typography>
                        }
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}

InsuranceCard.propTypes = {
    data: PropTypes.shape({
        payerName: PropTypes.string,
        policyNumber: PropTypes.string,
        groupNumber: PropTypes.string,
        memberName: PropTypes.string,
        status: PropTypes.string,
        effectiveStartDate: PropTypes.string,
        effectiveEndDate: PropTypes.string
    }).isRequired
};

export default InsuranceCard;