import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, Avatar, Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { formatDateTime } from '../../../utils/dates';
import { useDispatch, useSelector } from 'react-redux';
import UnreadIcon from '@mui/icons-material/FiberManualRecord';
import { markMessagesRead } from '../../../redux/actions/messageCenterActions';
import DOMPurify from 'dompurify';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';

const MAX_CONTENT_LENGTH_TO_DISPLAY = 80;

const CARE_TEAM = Object.freeze({
    PHARMACIST: 'PHARMACIST',
    INTAKE_AUTHORIZATION: 'INTAKE_AUTHORIZATION',
    CLIENT_SERVICE_SPECIALIST: 'CLIENT_SERVICE_SPECIALIST',
    NURSE_COORDINATOR: 'NURSE_COORDINATOR',
});

function MessageCard({ messageId, team, messageSubject, content, createdAt, direction, isRead, careTeamMemberName }) {
    const theme = useTheme();
    const { avatarBytes } = useSelector(state => state?.header);
    const { firstname: loggedInUserFirstName, lastname: loggedInUserLastName } = useSelector(state => state?.auth);

    const dispatchAction = useDispatch();
    const [contentLengthToShow, setContentLengthToShow] = useState(MAX_CONTENT_LENGTH_TO_DISPLAY);
    let messageOwner;
    let avatarFallback;
    const isCardOwnerThePatient = direction === 'PATIENT_TO_CARE_TEAM';

    if (isCardOwnerThePatient) {
        messageOwner = 'You';
        avatarFallback = loggedInUserFirstName?.slice(0, 1);
        avatarFallback = avatarFallback?.concat(loggedInUserLastName?.slice(0, 1));
    } else {
        messageOwner = careTeamMemberName ?? team;
        switch (team) {
            case (CARE_TEAM.CLIENT_SERVICE_SPECIALIST):
                avatarFallback = 'CSS';
                break;
            case (CARE_TEAM.INTAKE_AUTHORIZATION):
                avatarFallback = 'IN';
                break;
            case (CARE_TEAM.NURSE_COORDINATOR):
                avatarFallback = 'NC';
                break;
            case (CARE_TEAM.PHARMACIST):
                avatarFallback = 'P';
                break;
            default:
                avatarFallback = messageOwner?.slice(0, 1);
        }
    }

    const handleReadMore = () => {
        setContentLengthToShow(content.length);
        markAsRead();
    };

    const markAsRead = () => {
        if (!isRead)
            dispatchAction(markMessagesRead([messageId]));
    };

    const handleReadLess = () => {
        setContentLengthToShow(MAX_CONTENT_LENGTH_TO_DISPLAY);
    };

    return (
        <Box
            display={'flex'} padding={2} position={'relative'} bgcolor={isRead ? 'transparent' : '#E8F9FD'}
            sx={{
                transition: 'background 200ms linear',
            }}
        >
            <Box marginRight={1}>
                <Avatar src={isCardOwnerThePatient ? avatarBytes : ''}
                        sx={{ backgroundColor: isCardOwnerThePatient ? theme.palette.primary.main : alpha(theme.palette.primary.main, .7) }}>
                    {avatarFallback?.toUpperCase()}
                </Avatar>
            </Box>
            <Box>
                <Typography fontSize={'1.1rem'} fontWeight={500}>{messageOwner}</Typography>
                <Typography fontWeight={500} variant={'subtitle1'} color={'textSecondary'}>{messageSubject}</Typography>
                <Box marginTop={1} display={'flex'} alignItems={'center'}>
                    <Typography color={'textSecondary'} fontWeight={400}>
                        <span
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content?.slice(0, contentLengthToShow)) }} />
                        <Box component={'span'} sx={{ cursor: 'pointer' }}>
                            {content?.length > contentLengthToShow
                                ? <Typography component={'span'} color={'primary'} onClick={handleReadMore}>
                                    &nbsp; ..see more
                                </Typography>
                                : contentLengthToShow === content?.length &&
                                <Typography component={'span'} color={'primary'} onClick={handleReadLess}>
                                    &nbsp; see less
                                </Typography>
                            }
                        </Box>
                    </Typography>
                    {content?.length <= MAX_CONTENT_LENGTH_TO_DISPLAY && !isRead &&
                    <Button
                        size={'small'} onClick={markAsRead} endIcon={<MarkChatReadIcon fontSize={'small'} />}
                        sx={{ marginLeft: 2 }}
                    >
                        Mark as read
                    </Button>
                    }
                </Box>
            </Box>
            <Box position={'absolute'} top={4} right={'1rem'} display={'flex'} alignItems={'center'}>
                <Typography variant={'caption'} color={'textSecondary'}>{formatDateTime(createdAt)}</Typography>
                {!isRead &&
                <Box ml={2}>
                    <Tooltip title={'Unread Message'}>
                        <Box display={'flex'} alignItems={'center'}>
                            <UnreadIcon color={'secondary'} sx={{ fontSize: 15 }} />
                        </Box>
                    </Tooltip>
                </Box>
                }
            </Box>
        </Box>
    );
}

MessageCard.propTypes = {
    messageId: PropTypes.number.isRequired,
    team: PropTypes.string,
    messageSubject: PropTypes.string,
    content: PropTypes.string,
    createdAt: PropTypes.string,
    direction: PropTypes.string,
};

export default MessageCard;