import React, {useEffect, useState} from "react";
import {PageContentWrapper} from "../../styles/page";
import {Alert, Box, Button, Card, CardContent, styled,} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useScrollToTop from "../../../hooks/useScrollToTop";
import {useDispatch, useSelector} from "react-redux";
import InfoJournalTileBoard from "./InfoJournalTileBoard";
import InfusionJournalModal from "./InfusionJournalModal";
import {INFUSION_JOURNAL} from "../../../redux/actions/ActionTypes";
import withLoaderAndErrorCard from "../../../hoc/withLoaderAndErrorCard";

const NewJournalWrapper = styled("div")`
  margin-bottom: 1rem;

  .MuiButton-root {
    background-color: white;
  }
`;
const JournalWrapper = styled("div")`
  height: 100%;
  width: 100%;
  position: relative;

  .MuiAppBar-root {
    margin-top: -1rem;
    background-color: ${({theme}) => theme.palette.background.paper};
    box-shadow: none;
    position: sticky;
    top: ${({theme}) => theme.mixins.toolbar.minHeight}px;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  ._cardsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1250px;
    padding-bottom: 1px._cardWrapper {
      width: 100%;
      padding-bottom: 1px
    }.MuiCardContent-root {
      padding-bottom: 1px
    } ${({theme}) => ({
      [theme.breakpoints.up("sm")]: {
        gap: "1.5rem",
        "& ._cardWrapper": {
          minWidth: 380,
          maxWidth: 380,
        },
      },
      [theme.breakpoints.up("md")]: {
        gap: "0.5rem",
      },
    })}
  }
`;


function InfusionJournal() {
    useScrollToTop();
    const [isNew, setNew] = React.useState(false);
    const {currentPatientUsername} = useSelector(state => state?.auth);
    const dispatchAction = useDispatch();

    const dataForNewJournal = {
        id: 0,
        type: "",
        entryDate: "",
        treatmentDescription: "",
        medicationName: "",
        medicationDose: "",
        sideEffects: [],
        vialName: "",
        strength: "",
        lotNumber: "",
        painScale: "",
        sideEffectsTime: "",
        injectionSite: [],
        missingSupply: null,
        defectiveSupply: null
    };

    const [infoJournalData, setinfoJournalData] = useState([
        {
            id: 1,
            type: "side effect",
            entryDate: "2021-12-18",
            treatmentDescription: "",
            medicationName: "",
            medicationDose: "",
            sideEffects: ["Agitation", "Anaphylaxis-related"],
            vialName: "",
            strength: "",
            lotNumber: "",
            painScale: "1",
            sideEffectsTime: "During infusion",
            injectionSite: ["Erythema"],
            missingSupply: null,
            defectiveSupply: null
        },
        {
            id: 2,
            type: "side effect",
            entryDate: "2021-12-18",
            treatmentDescription: "",
            medicationName: "",
            medicationDose: "",
            sideEffects: ["Agitation", "Anaphylaxis-related,Agitation"],
            vialName: "",
            strength: "",
            lotNumber: "",
            painScale: "1",
            sideEffectsTime: "During infusion",
            injectionSite: ["Erythema"],
            missingSupply: null,
            defectiveSupply: null
        },
        {
            id: 3,
            type: "other",
            entryDate: "2021-12-20",
            treatmentDescription: "",
            medicationName: "Med1",
            medicationDose: "2",
            sideEffects: [],
            vialName: "V1",
            strength: "2",
            lotNumber: "2",
            painScale: "",
            sideEffectsTime: "",
            injectionSite: [],
            missingSupply: null,
            defectiveSupply: null
        },
        {
            id: 4,
            type: "side effect",
            entryDate: "2021-12-20",
            treatmentDescription: "",
            medicationName: "",
            medicationDose: "",
            sideEffects: ["Agitation", "Anaphylaxis-related"],
            vialName: "",
            strength: "",
            lotNumber: "",
            painScale: "1",
            sideEffectsTime: "During infusion",
            injectionSite: ["Erythema"],
            missingSupply: null,
            defectiveSupply: null
        },
    ]);
    //Mock Data
    //const values = infoJournalData;
    const fetchJournals = () => {
        dispatchAction({type: INFUSION_JOURNAL.FETCH_ALL});
    };

    useEffect(() => {
        currentPatientUsername && fetchJournals();
    }, [currentPatientUsername]);

    const {dbData} = useSelector((state) => state.infusionJournal);
    // const infoJournalDataKeys = Object.keys(dbData);
    /* Below code will test Mock data with reducers*/
    /*if (dbData.length > 0) {
      setInfusionJournalData(dbData);
    } else {
      setInfusionJournalData(infoJournalData);
    }*/

    const contentJsx = () => (
        <>
            <NewJournalWrapper>
                <Button
                    color={"primary"}
                    variant={"outlined"}
                    startIcon={<AddIcon/>}
                    onClick={() => setNew(true)}
                >
                    New Journal
                </Button>
            </NewJournalWrapper>
            <JournalWrapper>
                <Box marginY={3}>
                    <Box>
                        <Box className={"_cardsWrapper"}>
                            {(dbData?.length < 1 ||
                                dbData.filter((key) => dbData[key] !== null)
                                    .length === 0) && (
                                <Box display={"flex"} justifyContent={"center"}>
                                    <Card>
                                        <CardContent>
                                            <Alert severity={"warning"}>No Journals Found.</Alert>
                                        </CardContent>
                                    </Card>
                                </Box>
                            )}
                            {dbData.map((journal, i) => {
                                const {
                                    id,
                                    type,
                                    entryDate,
                                    treatmentDescription,
                                    medicationName,
                                    medicationDose,
                                    vialName,
                                    strength,
                                    lotNumber,
                                    painScale,
                                    sideEffectsTime,
                                    sideEffects,
                                    injectionSite,
                                    missingSupply,
                                    defectiveSupply
                                } = journal;

                                return (
                                    <Box key={type + i} className={"_cardWrapper"}>
                                        <InfoJournalTileBoard
                                            id={id}
                                            type={type}
                                            entryDate={entryDate}
                                            treatmentDescription={treatmentDescription}
                                            medicationName={medicationName}
                                            medicationDose={medicationDose}
                                            vialName={vialName}
                                            strength={strength}
                                            lotNumber={lotNumber}
                                            painScale={painScale}
                                            sideEffectsTime={sideEffectsTime}
                                            sideEffects={sideEffects}
                                            injectionSite={injectionSite}
                                            missingSupply={missingSupply}
                                            defectiveSupply={defectiveSupply}

                                        />
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </JournalWrapper>
        </>
    )

    return (
        <PageContentWrapper>
            {currentPatientUsername
                ? withLoaderAndErrorCard(contentJsx)({
                    error: false, //Todo
                    isLoading: false, //Todo
                    onReloadClick: fetchJournals
                })
                : null
            }

            {isNew && (
                <InfusionJournalModal
                    isOpen={true}
                    onClose={() => {
                        setNew(false);
                    }}
                    data={dataForNewJournal}
                    action={"NEW"}
                />
            )}
        </PageContentWrapper>
    );
}

InfusionJournal.propTypes = {};

export default InfusionJournal;
