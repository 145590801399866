import React, {useEffect, useRef, useState} from 'react';
import {Alert, Box, Button, CircularProgress, Dialog, DialogTitle, Grid, Typography} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Input from "../../../components/Input";
import {Formik} from "formik";
import AnimateHeight from "react-animate-height";
import PropTypes from "prop-types";
import axios from "axios";
import {errorExtractor, postOrPutErrorExtractor} from "../../../../utils/httpErrorHelpers";
import {API_URN_FORGOT_PASSWORD, API_URN_PATIENT, API_URN_RECOVER_PASSWORD} from "../../../../constants/apiURNs";
import {toast} from "react-toastify";
import * as yup from "yup";
import PasswordInput from "../../../components/PasswordInput";
import {Link} from "react-router-dom";
import {VALID_PASSWORD_REGEX} from "../../../../constants/validators";
import {URN_PASSWORD_POLICY} from "../../../../constants/clientURNs";

function ForgotPasswordDialog({isOpen, onClose}) {
    //Step one is sending only the username. If username is valid, OTP will be sent to MFA device
    //Step two to proceed with the OTP, username and new password
    const [isStepTwo, setStepTwo] = useState(false);
    const [resendInCounter, setResendInCounter] = useState(45);
    const [isSendingCode, setSendingCode] = useState(false);

    let resendCounterRef = useRef(null);

    useEffect(() => {
        return () => {
            clearInterval(resendCounterRef.current)
        }
    }, [])

    const startCounter = () => {
        resendCounterRef.current = setInterval(() => {
            setResendInCounter(prevState => prevState === 0 ? prevState : prevState - 1);
        }, 1000)
    }

    /**
     * This is the first step where username is send to backend
     */
    const sendRecoveryCode = (username, setStatus, setFieldValue) => {
        setStatus({errorMessage: null});
        setSendingCode(true);
        const params = new URLSearchParams();
        params.append("username", username);
        const baseUrl = process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_FORGOT_PASSWORD;

        axios.post(baseUrl, params, {headers: {"Content-Type": "application/x-www-form-urlencoded"}})
            .then(({data}) => {
                const {pingUserId} = data;
                setFieldValue("pingUserId", pingUserId);
                setStepTwo(true);
                startCounter();
                setSendingCode(false);
                setStatus({errorMessage: null});
                setResendInCounter(60);
            })
            .catch((error) => {
                setStatus({errorMessage: errorExtractor(error)});
                setSendingCode(false);
            })
    }

    /**
     * This is the second step where secure code and new password is shared with username
     */
    const recoverPassword = (values, formikHelpers) => {
        const {username, recoveryCode, newPassword, pingUserId} = values;
        const params = new URLSearchParams();
        params.append("username", username);
        params.append("recoveryCode", recoveryCode);
        params.append("newPassword", newPassword);
        params.append("pingUserId", pingUserId);
        const baseUrl = process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_RECOVER_PASSWORD;

        axios.post(baseUrl, params, {headers: {"Content-Type": "application/x-www-form-urlencoded"}})
            .then(() => {
                toast("Password reset successfully", {position: "top-center", type: "success"});
                onClose();
            })
            .catch((error) => {
                formikHelpers.setStatus({errorMessage: postOrPutErrorExtractor(error, formikHelpers)});
                formikHelpers.setSubmitting(false);
            })
    }

    return (
        <Dialog open={isOpen} scroll={"paper"} fullWidth maxWidth={"xs"}>
            <Formik
                initialValues={{
                    username: "",
                    recoveryCode: "",
                    newPassword: ""
                }}
                validationSchema={yup.object().shape({
                    username: yup.string().trim().required("Required").typeError("Required"),
                    pingUserId: isStepTwo ? yup.string().trim().required("Required").typeError("Required") : undefined,
                    recoveryCode: isStepTwo ? yup.string().trim().required("Required").typeError("Required") : undefined,
                    newPassword: isStepTwo ? yup.string().required("Required").matches(VALID_PASSWORD_REGEX, "Password must be 8 characters or longer, containing: one capital letter, one lower case letter, one number, one special character “~!@#$%^&*()-_=+[]{}|;:,.<>/?”. The password cannot have more than 2 repeated characters.").typeError("Invalid Password") : undefined,
                })}
                onSubmit={(values, formikHelpers) => {
                    formikHelpers.setStatus({errorMessage: null});
                    recoverPassword(values, formikHelpers)
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      dirty,
                      errors, touched,
                      isSubmitting,
                      status,
                      setStatus, setFieldValue
                  }) => (
                    <>
                        <DialogTitle sx={{marginBottom: 1}}>
                            Please enter the details below
                        </DialogTitle>
                        <DialogContent>
                            <form autoComplete={"off"}>
                                <Box marginBottom={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Input
                                                name={"username"} required
                                                label={"Username"} readOnly={isStepTwo}
                                                value={values.username ?? ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors && errors.username !== undefined}
                                                helperText={errors ? errors.username : undefined}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <AnimateHeight height={isStepTwo ? "auto" : 0} style={{minWidth: "100%"}}>
                                    <Grid container spacing={2} marginBottom={2}>
                                        <Grid item xs={12}>
                                            <Box marginBottom={2}>
                                                <Alert severity={"info"} variant={"standard"}>
                                                    A recovery code has been sent to your registered email address. Code
                                                    is valid for 5 minutes
                                                </Alert>
                                            </Box>
                                            <Input
                                                name={"recoveryCode"} required
                                                label={"Recovery Code"}
                                                value={values.recoveryCode}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(errors.recoveryCode && touched.recoveryCode)}
                                                helperText={touched.recoveryCode && errors?.recoveryCode}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PasswordInput
                                                name={"newPassword"} id={"newPassword"} required
                                                label={"New Password"} autoComplete={"new-password"}
                                                value={values.newPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.newPassword && errors.newPassword)}
                                                helperText={touched.newPassword && errors?.newPassword}
                                                InputProps={{
                                                    autoComplete: "new-password"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </AnimateHeight>

                                {status?.errorMessage &&
                                <Box marginY={2}>
                                    <Alert severity={"error"} onClose={() => setStatus({errorMessage: null})}>
                                        {status.errorMessage}
                                    </Alert>
                                </Box>
                                }

                                {(status?.errorMessage?.includes("password policy") || errors.newPassword) &&
                                <Box marginY={1}>
                                    <Typography fontSize={"small"}>
                                        <Link to={URN_PASSWORD_POLICY} target={"_blank"}>Read Password Policy</Link>
                                    </Typography>
                                </Box>
                                }
                            </form>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={onClose} variant={"outlined"}>
                                Cancel
                            </Button>
                            {isStepTwo &&
                            <Button variant={"contained"} color={"primary"}
                                    disabled={isSubmitting || resendInCounter > 0 || isSendingCode}
                                    onClick={() => sendRecoveryCode(values.username, setStatus, setFieldValue)}
                                    endIcon={isSendingCode ?
                                        <CircularProgress color={"inherit"} size={20}/>
                                        : undefined
                                    }
                            >
                                Resend Code {resendInCounter > 0 && `(${resendInCounter})`}
                            </Button>
                            }
                            {isStepTwo
                                ? <Button onClick={handleSubmit} variant="contained" color="primary"
                                          disabled={!dirty || isSubmitting || Object.keys(errors).length > 0}>
                                    {isSubmitting ?
                                        <CircularProgress color={"inherit"} size={22}/> : "Submit"
                                    }
                                </Button>
                                :
                                <Button onClick={() => sendRecoveryCode(values.username, setStatus, setFieldValue)}
                                        variant="contained"
                                        color="primary"
                                        disabled={!dirty || isSendingCode || Object.keys(errors).length > 0}>
                                    {isSendingCode ?
                                        <CircularProgress color={"inherit"} size={22}/> : "Submit"
                                    }
                                </Button>
                            }
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );
}

ForgotPasswordDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired
}

export default ForgotPasswordDialog;

