import { call, put, select, takeLatest, takeEvery } from "redux-saga/effects";
import { INFUSION_JOURNAL } from "../actions/ActionTypes";
import {
  setInfusionJournalData,
  setError,
} from "../actions/infusionJournalActions";
import { pageErrorExtractor } from "../../utils/httpErrorHelpers";
import { fetchJournals } from "../../api/infusionJournal";

function* handleFetchAllInfusionJournals() {
  try {
    yield put(setError(null));
    const response = yield call(fetchJournals);
    const dbData = yield response.data;
    yield put(setInfusionJournalData(dbData));
  } catch (error) {
    yield put(setError(pageErrorExtractor(error)));
  }
}


function* infusionJournalSaga() {
  yield takeLatest(INFUSION_JOURNAL.FETCH_ALL, handleFetchAllInfusionJournals);
}

export default infusionJournalSaga;
