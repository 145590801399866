import React, {useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {PageContentWrapper} from "../../styles/page";
import styled from "styled-components";
import {useHistory, useLocation, useParams} from "react-router-dom";
import OrderNew from './OrderNew';
import {URN_MY_ORDERS} from "../../../constants/clientURNs";
import AddIcon from '@mui/icons-material/Add';
import {toast} from "react-toastify";
import useScrollToTop from "../../../hooks/useScrollToTop";

const NewOrderWrapper = styled.div`
  margin-bottom: 1rem;

  .MuiButton-root {
    background-color: white;
  }
`;

const OrdersWrapper = styled.div`
  width: 100%;
`;

function getSteps() {
    return ['In Process', 'Shipped', 'In Transit', 'Delivered'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Your order is in process';
        case 1:
            return 'Your order is shipped';
        case 2:
            return `Your order is on it's way`;
        case 3:
            return 'Order delivered'
        default:
            return 'Unknown step';
    }
}

/**
 *
 * @param {string} status
 */
const getStatusChipColor = (status) => {
    if (status.toLowerCase() === "delivered")
        return "primary";
    else
        return "secondary";
}

export default function MyOrders() {
    useScrollToTop();
    const [orders, setOrders] = useState([
        {
            id: 88045,
            createdOn: "Jan 25, 2020",
            pharmacy: "Nufactor",
            items: ["Gamunex-C", "two", "three"],
            cost: "$130.00",
            status: "In Process",
        },
        {
            id: 88044,
            createdOn: "Jan 10, 2020",
            pharmacy: "Nufactor",
            items: ["Acubutol Warfarin", "two", "three"],
            cost: "$100.00",
            status: "Shipped",
        },
        {
            id: 88043,
            createdOn: "Jan 02, 2020",
            pharmacy: "Nufactor",
            items: ["Gamunex-D", "two", "three"],
            cost: "$111.00",
            status: "Delivered"
        },
    ]);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const {id} = params;
    const isNewOrder = id === "new";

    const steps = getSteps();

    const isStepCompleted = (stepIndex) => {
        if (selectedOrder) {
            return steps.findIndex(v => v.toLowerCase() === selectedOrder.status.toLowerCase()) >= stepIndex;
        }
        return false;
    }

    const addOrder_state = (order) => {
        setOrders(prevState => prevState.concat(order));
        history.push(URN_MY_ORDERS);
        toast("Order placed", {type: "success"});
    }

    return (
        <PageContentWrapper>
            <NewOrderWrapper>
                <Button
                    color={"primary"} variant={"outlined"} startIcon={<AddIcon/>}
                    onClick={() => history.push(location.pathname + "/new")}
                >
                    New Order
                </Button>
            </NewOrderWrapper>
            <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                    <Card>
                        <CardContent>
                            <Typography variant={"subtitle1"}
                                        color={"textSecondary"}>{orders.length} Order(s)</Typography>
                            <OrdersWrapper>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Order ID</TableCell>
                                                <TableCell>Created on</TableCell>
                                                <TableCell>Pharmacy</TableCell>
                                                <TableCell>Items</TableCell>
                                                <TableCell align={"center"}>Cost</TableCell>
                                                <TableCell align={"center"}>Status</TableCell>
                                                <TableCell>{""}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orders.map((order) => (
                                                <TableRow key={order.id}
                                                          onClick={() => setSelectedOrder(order)}
                                                          selected={selectedOrder && selectedOrder.id === order.id}>
                                                    <TableCell>{order.id}</TableCell>
                                                    <TableCell>{order.createdOn}</TableCell>
                                                    <TableCell>{order.pharmacy}</TableCell>
                                                    <TableCell>{order.items[0]}</TableCell>
                                                    <TableCell align={"center"}>{order.cost}</TableCell>
                                                    <TableCell align={"center"}>
                                                        <Chip label={order.status} style={{width: "100px"}}
                                                              color={getStatusChipColor(order.status)}
                                                              size={"small"}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button onClick={() => setSelectedOrder(order)}
                                                                variant={"outlined"} color={"primary"}
                                                                size={"small"}>View</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </OrdersWrapper>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={3}>
                    {selectedOrder &&
                    <Card>
                        <CardContent>
                            <Typography variant={"subtitle1"} color={"textSecondary"}>Order
                                #{selectedOrder.id}</Typography>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                Tracking no.<a href="https://www.fedex.com/en-in/tracking.html" target={"_blank"}
                                               rel={"noreferrer"}>122664748849959</a>
                            </Typography>
                            <Stepper orientation="vertical"
                                     activeStep={steps.findIndex(v => v.toLowerCase() === selectedOrder.status.toLowerCase())}>
                                {steps.map((label, index) => (
                                    <Step key={label} completed={isStepCompleted(index)}>
                                        <StepLabel>{label}</StepLabel>
                                        <StepContent>
                                            <Typography color={"textSecondary"}
                                                        style={{fontSize: ".85rem"}}>{getStepContent(index)}</Typography>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </CardContent>
                    </Card>
                    }
                </Grid>
            </Grid>

            {isNewOrder && <OrderNew isOpen={true} onClose={() => history.goBack()} handleSubmit={addOrder_state}/>}

        </PageContentWrapper>
    );
}