import { INFUSION_JOURNAL } from "../actions/ActionTypes";

const defaultState = {
    dbData: [],
};

export const infusionJournalReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INFUSION_JOURNAL.SET_DATA: {
     
      const { dbData } = payload;
      return { ...state, dbData };
    }
    case INFUSION_JOURNAL.SET_LOADING: {
      const { isLoading } = payload;
      return { ...state, isLoading };
    }

    case INFUSION_JOURNAL.SET_PAGE_ERROR: {
      const { error } = payload;
      return { ...state, error };
    }

    case INFUSION_JOURNAL.SET_CACHE_EXPIRY_TIME: {
      const { cacheExpiryTime } = payload;
      return { ...state, cacheExpiryTime };
    }
  
    default:
      return { ...state };
  }
}
