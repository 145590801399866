import { MESSAGE_CENTER } from './ActionTypes';

export const setLoading = (isLoading) => ({
    type: MESSAGE_CENTER.SET_LOADING,
    payload: {
        isLoading,
    },
});

export const setLoadingMore = (isLoadingMore) => ({
    type: MESSAGE_CENTER.SET_LOADING_MORE,
    payload: {
        isLoadingMore,
    },
});

/**
 * @param {object} error
 */
export const setError = (error) => ({
    type: MESSAGE_CENTER.SET_PAGE_ERROR,
    payload: {
        error,
    },
});

export const setMessageCenterMessages = (data) => ({
    type: MESSAGE_CENTER.SET_DATA,
    payload: {
        data,
    },
});

export const setCacheExpiryTime = (cacheExpiryTime) => ({
    type: MESSAGE_CENTER.SET_CACHE_EXPIRY_TIME,
    payload: {
        cacheExpiryTime,
    },
});

/**
 *
 * @param messageIds array of message Ids
 */
export const markMessagesRead = (messageIds) => ({
    type: MESSAGE_CENTER.MARK_MESSAGES_READ,
    payload: {
        messageIds,
    },
});

export const markMessagesReadToStore = (messageIds) => ({
    type: MESSAGE_CENTER.SET_MESSAGES_READ,
    payload: {
        messageIds,
    },
});