import React, {useEffect, useRef, useState} from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import {idpRequestSuccess_saga} from "../../../../redux/actions/authActions";
import {URN_AUTH, URN_LOGIN, URN_SIGN_OFF, URN_SIGNUP} from "../../../../constants/clientURNs";
import Input from "../../../components/Input";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import ForgotUsernameDialog from "./ForgotUsernameDialog";
import axios from "axios";
import {API_URN_LOGIN, API_URN_LOGIN_REQUEST, API_URN_PATIENT} from "../../../../constants/apiURNs";
import {postOrPutErrorExtractor} from "../../../../utils/httpErrorHelpers";
import {
    FLOW_ID_QP,
    FLOW_ID_TIMEOUT_QP,
    MFA_QP,
    PASSWORD_ONLY_QP,
    PASSWORD_STATUS_QP
} from "../../../../constants/queryParams";
import MfaSteps from "./MfaSteps";
import PasswordInput from "../../../components/PasswordInput";
import ResetPasswordDialog from "./ResetPasswordDialog";
import CachedIcon from '@mui/icons-material/CachedOutlined';
import {STATUS} from "../../../../constants/idpConstants";

function LoginForm() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const passwordOnlyUsername = params.get(PASSWORD_ONLY_QP);
    const flowId = params.get(FLOW_ID_QP);
    const flowIdTimeout = params.get(FLOW_ID_TIMEOUT_QP);
    const isMfaScreens = params.get(MFA_QP); //?mfa=xx
    const isMustChangePassword = params.get(PASSWORD_STATUS_QP) === STATUS.MUST_CHANGE_PASSWORD;
    const isPasswordExpired = params.get(PASSWORD_STATUS_QP) === STATUS.PASSWORD_EXPIRED;

    const [isForgotPassDialogOpen, openForgotPassDialog] = useState(false);
    const [isForgotUsernameDialogOpen, openForgotUsernameDialog] = useState(false);
    const [isGettingFlowId, setGettingFlowId] = useState(false);
    const [isSessionExpired, setSessionExpired] = useState(false);
    const [isDiffSessionError, setDiffSessionError] = useState(false);

    const history = useHistory();
    const dispatchAction = useDispatch();

    const {state} = useLocation();
    const {mfaDevices} = state ?? {}; //sent from authSaga

    let sessionExpirationTimer = useRef(null);
    useEffect(() => {
        if (flowIdTimeout) {
            sessionExpirationTimer.current = setTimeout(() => {
                setSessionExpired(true);
            }, Number.parseInt(flowIdTimeout) * 1000)
        }

    }, [flowIdTimeout])

    useEffect(() => {
        if (isForgotPassDialogOpen || isForgotUsernameDialogOpen || isMfaScreens)
            clearTimeout(sessionExpirationTimer.current)
    }, [isForgotPassDialogOpen, isForgotUsernameDialogOpen, isMfaScreens])

    useEffect(() => {
        return () => {
            // setTimeout cleanup
            clearTimeout(sessionExpirationTimer.current)
        }
    }, [sessionExpirationTimer])

    let retryCount = 0;

    /**
     * Request IDP for flowId.
     */
    const handleLogin = () => {
        if (!isGettingFlowId) {
            setGettingFlowId(true);
            console.log("login with withCredentials (cookies) :: ", (retryCount === 0 && params.get("fresh") !== "true"));
            axios({
                method: "GET",
                withCredentials: (retryCount === 0 && params.get("fresh") !== "true"), //on retry, send request without credentials
                baseURL: process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_LOGIN_REQUEST
            }).then(({data}) => {
                dispatchAction((idpRequestSuccess_saga(data, history)));
                setGettingFlowId(false);
            }).catch(() => {
                ++retryCount;
                if (retryCount < 2) {
                    handleLogin();
                } else {
                    history.push(URN_AUTH + URN_SIGN_OFF);
                }
            })
        }
    }

    if (!flowId) {
        handleLogin();
    }

    const handleForgotPasswordClose = () => {
        openForgotPassDialog(false);
        history.replace(URN_AUTH + URN_LOGIN);
    }

    const handleForgotUsernameClose = () => {
        openForgotUsernameDialog(false);
        history.replace(URN_AUTH + URN_LOGIN);
    }

    return (
        <>
            <Typography variant={"h5"} component={"h5"} className={"_header"}>
                Welcome to Nufactor
            </Typography>

            <div className={"_form_wrapper"}>
                <Formik
                    enableReinitialize //update formik's initial value with flowId change from URL
                    initialValues={{
                        username: passwordOnlyUsername ?? "",
                        password: "",
                        flowId
                    }}
                    validationSchema={yup.object().shape({
                        username: yup.string().trim().required("Please provide a valid username"),
                        password: yup.string().trim().required("Please provide a valid password"),
                    })}
                    onSubmit={(values, formikHelpers) => {
                        formikHelpers.setStatus({errorMessage: null})
                        axios({
                            method: "POST",
                            baseURL: process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_LOGIN,
                            withCredentials: true,
                            data: values
                        }).then(({data}) => {
                            formikHelpers.setSubmitting(false);
                            dispatchAction(idpRequestSuccess_saga(data, history));
                        }).catch((error) => {
                            formikHelpers.setSubmitting(false);
                            const errorMessage = postOrPutErrorExtractor(error, formikHelpers);
                            const isPingError = error?.response?.data?.code === 7012
                            if (isPingError) { //Error because of PingOne
                                if (errorMessage.toLowerCase().includes("invalid value for st")
                                    || errorMessage.toLowerCase().includes("requested resource was not found")
                                    || errorMessage.toLowerCase().includes("session expired")
                                    || errorMessage.includes("Session token cookie value does not match the value in the current session")
                                ) {
                                    //The PingOne error can occurs if the flow-id is invalid or expired.
                                    //In that case, there should be a fallback plan to get the new flow-id.
                                    //Open the session expired Dialog and that will take care of creating a new flow-id.
                                    setSessionExpired(true);
                                }
                                //user login with the different username and older cookies cannot be used.
                                if (errorMessage.toLowerCase().includes("username must identify current session user")) {
                                    setDiffSessionError(true);
                                }
                            }
                            formikHelpers.setStatus({errorMessage})
                        })
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          errors, status, setStatus,
                          touched, setFieldValue
                      }) => (
                        flowId
                        ? <Box component={"form"} autoComplete="off" maxWidth={400}>
                            <Grid container spacing={2}>
                                {status?.errorMessage &&
                                 <Grid item xs={12}>
                                     <Alert severity={"error"} onClose={() => setStatus({errorMessage: null})}>
                                         {status?.errorMessage}
                                     </Alert>
                                 </Grid>
                                }
                                <Grid item xs={12}>
                                    {passwordOnlyUsername
                                     ? <Box
                                         bgcolor={"background.default"}
                                         sx={{
                                             "& .MuiInputBase-root": {
                                                 border: "none !important",
                                                 pointerEvents: "none",
                                             }
                                         }}>
                                         <Input value={passwordOnlyUsername} label={"Username"}/>
                                     </Box>
                                     : <Input
                                         name={"username"} required
                                         label="Username" autoFocus size={"medium"}
                                         onChange={(e) => setFieldValue("username", e.target.value.toLowerCase())}
                                         value={values.username}
                                         error={touched.username && errors.username !== undefined}
                                         helperText={touched.username ? errors.username : ""}
                                     />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordInput
                                        name={"password"} required label={"Password"}
                                        autoComplete="current-password"
                                        value={values.password} size={"medium"}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position={"start"}>
                                                    <LockOutlinedIcon color={"primary"} fontSize={"small"}/>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={handleChange}
                                        error={touched.password && errors.password !== undefined}
                                        helperText={touched.password ? errors.password : ""}
                                    />
                                </Grid>
                            </Grid>
                            <Box marginY={4} textAlign={"left"}>
                                <Button
                                    variant="contained" color={"primary"}
                                    onClick={handleSubmit} disabled={isSubmitting} type={"submit"}
                                    endIcon={isSubmitting ?
                                             <CircularProgress color={"inherit"} size={20}/> : undefined}
                                    sx={{'&:hover' : {
                                            backgroundColor: 'secondary.main',

                                        }}}>
                                    Login
                                </Button>
                            </Box>
                        </Box>
                        : <Box minHeight={200} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <CircularProgress color={"primary"} size={30}/>
                        </Box>
                    )}
                </Formik>
            </div>
            {passwordOnlyUsername &&
             <Box>
                 <Button component={Link} to={URN_AUTH + URN_LOGIN + "?fresh=true"} endIcon={<CachedIcon/>} sx={{'&:hover' : {
                         backgroundColor: 'transparent',
                         color: 'secondary.main',
                     }}} >
                     Login Using Different Account
                 </Button>
             </Box>
            }
            <div className={"_actions"}>
                <Box display={"flex"}>
                    <Button onClick={() => openForgotUsernameDialog(true)} sx={{'&:hover' : {
                        backgroundColor: 'transparent',
                        color: 'secondary.main',
                    }}}>Forgot Username?</Button>
                    <Divider orientation={"vertical"} flexItem sx={{marginX: .5}}/>
                    <Button onClick={() => openForgotPassDialog(true)} sx={{'&:hover' : {
                            backgroundColor: 'transparent',
                            color: 'secondary.main',
                        }}}>Forgot Password?</Button>
                </Box>
                <Box display={"inline-flex"} alignItems={"center"} marginTop={.5}>
                    <Typography color={"textSecondary"}>Existing Nufactor Patient?</Typography>
                    <Button component={Link} to={URN_AUTH + URN_SIGNUP} sx={{'&:hover' : {
                            backgroundColor: 'transparent',
                            color: 'secondary.main',
                        }}}>Create Account</Button>
                </Box>
            </div>

            {isMfaScreens &&
             <MfaSteps mfaDevices={mfaDevices}/>
            }

            {isForgotPassDialogOpen &&
             <ForgotPasswordDialog isOpen={true} onClose={handleForgotPasswordClose}/>
            }

            {isForgotUsernameDialogOpen &&
             <ForgotUsernameDialog isOpen={true} onClose={handleForgotUsernameClose}/>
            }

            {(isMustChangePassword || isPasswordExpired) &&
             <ResetPasswordDialog
                 title={isPasswordExpired ? "Password expired" : "Password Reset Required"}
             />
            }

            <Dialog open={isSessionExpired}>
                <DialogContent>
                    <Alert severity={"warning"}>
                        <AlertTitle>Login Session expired</AlertTitle>
                        <p>Please create a new login session for secure login</p>
                    </Alert>
                    <Box marginY={2} textAlign={"center"}>
                        <Button variant={"outlined"}
                                sx={{textTransform: "none"}}
                                onClick={() => {
                                    history.replace(URN_AUTH + URN_LOGIN);
                                    setSessionExpired(false);
                                }}
                        >
                            Create New Login Session
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={isDiffSessionError}>
                <DialogContent>
                    <Alert severity={"warning"}>
                        <AlertTitle>Login Session expired</AlertTitle>
                        <p>Please create a new login session for secure login</p>
                    </Alert>
                    <Box marginY={2} textAlign={"center"}>
                        <Button variant={"outlined"}
                                sx={{textTransform: "none"}}
                                onClick={() => {
                                    history.replace(URN_AUTH + URN_LOGIN + "?fresh=true");
                                    setDiffSessionError(false);
                                }}
                        >
                            Create New Login Session
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

        </>
    );
}

export default LoginForm;