import axios from 'axios';
import { API_CARE_TEAM } from '../constants/apiURNs';

export function fetchCareTeamMembers(username) {
    return axios.get(API_CARE_TEAM + '/' + username);
}

export function sendMessageToCareTeam(data, userName) {
    return axios.post(API_CARE_TEAM + '/' + userName + '/send-message-care-team', data);
}

export function fetchMessageCenterMessages(username) {
    return axios.get('/messages/' + username);
}

export function fetchPersonalContacts(username) {
    return axios.get(API_CARE_TEAM + '/' + username + '/personal-contacts');
}

export function fetchCareTeamMessageUnreadCount(username) {
    return axios.get('/messages/' + username + '/count-unread');
}

/**
 *
 * @param username authenticated patient's username
 * @param messageIds
 */
export function markMessagesReadApi(username, messageIds) {
    return axios.put('/messages/' + username + '/read-message', messageIds);
}