import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, CircularProgress, Typography} from "@mui/material";
import styled from "styled-components";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Icon from "@mdi/react";
import {mdiCloseCircle} from "@mdi/js";
import ProductDetails from './ProductDetails';
import OrderDetails from './OrderDetails';
import Payment from './Payment';
import ConfirmOrder from './ConfirmOrder';
import MainContentModal from "../../components/MainContentModal";
import {useLocation} from "react-router-dom";

const OrderNewWrapper = styled.div`
  height: 100%;
  position: relative;

  ._close {
    position: absolute;
    top: .5rem;
    right: .5rem;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  ._actions {
    position: fixed;
    bottom: 2rem;

  }

  ._title {
    margin: .5rem 0 1rem 0;
  }

  ._content {
    width: 100%;
    max-height: 80vh;
    overflow: scroll;

    ._player {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 1rem;
    }

    ._description {
      height: 300px;

      h6 {
        margin: 0;
      }

      p {
        margin: 0;
      }

      > * {
        margin-bottom: 1rem;
      }
    }
  }
`;

function getSteps() {
    return ['Product Details', 'Order Details', 'Payment', 'Confirmation'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return (<ProductDetails/>);
        case 1:
            return (<OrderDetails/>);
        case 2:
            return (<Payment/>);
        case 3:
            return (<ConfirmOrder/>);
        default:
            return 'Unknown stepIndex';
    }
}

function OrderNew({isOpen, onClose, handleSubmit}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isRefill = params.get("refill") && params.get("refill").toLowerCase() === "true";
    const [isOrdering, setIsOrdering] = useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            let step = prevActiveStep === 3 ? 3 : prevActiveStep + 1;
            if (prevActiveStep === 3) {
                setIsOrdering(true)
                setTimeout(() => {
                    setIsOrdering(false);
                    handleSubmit({
                        id: Math.floor(Math.random() * 100000),
                        createdOn: new Date().toISOString(),
                        pharmacy: "Nufactor",
                        items: ["Gamunex-C", "two", "three"],
                        cost: "$130.00",
                        status: "In Process",
                    });
                }, 2000);
            }
            return step;
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function handleClose() {
        setActiveStep(0);
        onClose();
    }

    return (
        <MainContentModal
            isOpen={isOpen} onClose={handleClose}>
            <OrderNewWrapper>
                <div className={"_close"}>
                    <button onClick={handleClose}><Icon path={mdiCloseCircle} size={1.2}/></button>
                </div>
                <h2 className={"_title"}>{isRefill ? "Refill Order" : "New Order"}</h2>
                <div className="_content">
                    <Box width={"100%"}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography sx={{marginY: 1}}>All steps completed</Typography>
                                    <Button onClick={handleReset}>Reset</Button>
                                </div>
                            ) : (
                                <Box marginY={1}>
                                    <div>{getStepContent(activeStep)}</div>
                                </Box>
                            )}
                        </div>
                    </Box>
                </div>
                <div className={"_actions"}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack} color={"primary"} variant={"outlined"} style={{marginRight: "1rem"}}
                    >
                        Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext}
                            className={isOrdering ? "_disabled" : ""}
                            endIcon={isOrdering ? <CircularProgress color={"secondary"} size={18}/> : ""}>
                        {activeStep === steps.length - 1 ? 'Place Order' : 'Next'}
                    </Button>
                </div>
            </OrderNewWrapper>
        </MainContentModal>
    );
}

OrderNew.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default OrderNew;