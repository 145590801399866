import {applyMiddleware, compose, createStore} from "redux";
import {createBrowserHistory} from "history";
import {routerMiddleware} from "connected-react-router";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import createSagaMiddleware from "redux-saga";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        rootReducer(history),
        composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
    );
    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureStore;
