import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Slide,
    styled,
    Typography, useMediaQuery
} from "@mui/material";
import PhoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/MailOutline';
import clsx from "clsx";
import OtpVerification from "./OtpVerification";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {API_URN_LOGIN_REQUEST, API_URN_PATIENT, API_URN_SEND_OTP} from "../../../../constants/apiURNs";
import {idpRequestSuccess_saga} from "../../../../redux/actions/authActions";
import {toast} from "react-toastify";
import {errorExtractor} from "../../../../utils/httpErrorHelpers";
import {useDispatch} from "react-redux";
import {FLOW_ID_QP, MFA_DEVICE_SELECTION_QP_VAL, MFA_OTP_QP_VAL, MFA_QP} from "../../../../constants/queryParams";
import {URN_AUTH, URN_SIGN_OFF} from "../../../../constants/clientURNs";
import {useTheme} from "@mui/styles";

const DialogContentWrapper = styled(DialogContent)`
  min-width: 350px;
  min-height: 300px;

  .deviceRoot {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    width: 100%;
    padding: ${({theme}) => theme.spacing(2)};
    cursor: pointer;
    border-radius: 5px;
    transition: background .2s linear;

    :hover {
      background: ${({theme}) => theme.palette.action.hover}
    }
  }

  .selectedDevice {
    background: ${({theme}) => theme.palette.primary.main};
    color: ${({theme}) => theme.palette.background.paper};

    :hover {
      background: ${({theme}) => theme.palette.primary.dark};
    }
  }
`;

function MfaSteps({mfaDevices}) {
    const theme = useTheme();
    const isFullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [isLoginInWithDiffAccount, setLoginInWithDiffAccount] = useState(false);
    const [isSendingOtp, setSendingOtp] = useState(false);

    const history = useHistory();
    const dispatchAction = useDispatch();
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const mfaStep = params.get(MFA_QP);
    const isDeviceSelectionStep = mfaStep === MFA_DEVICE_SELECTION_QP_VAL;
    const isOtpStep = mfaStep === MFA_OTP_QP_VAL;
    const flowId = params.get(FLOW_ID_QP);

    useEffect(() => {
        if (mfaDevices?.length === 1)
            setSelectedDeviceId(mfaDevices[0]?.id)
    }, [])

    /**
     * Request IDP for flowId. This would be without the ping ST cookie
     */
    const handleLoginWithoutCredentials = () => {
        setLoginInWithDiffAccount(true);
        axios({
            method: "GET",
            withCredentials: false,
            baseURL: process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_LOGIN_REQUEST
        }).then(({data}) => {
            dispatchAction((idpRequestSuccess_saga(data, history)));
        }).catch(error => {
            setLoginInWithDiffAccount(false);
            toast.error(errorExtractor(error));
        })
    }

    const sendOtp = (successCallback) => {
        if (selectedDeviceId) {
            setSendingOtp(true);
            const data = {
                deviceId: selectedDeviceId,
                flowId
            }
            axios.post(process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_SEND_OTP, data, {
                withCredentials: true,
            }).then(() => {
                setSendingOtp(false);
                history.replace({
                    pathname: history.location.pathname,
                    search: "?" + MFA_QP + "=" + MFA_OTP_QP_VAL + "&" + FLOW_ID_QP + "=" + flowId,
                })
                successCallback && successCallback();
            }).catch(error => {
                setSendingOtp(false);
                let errorMessage = errorExtractor(error);
                toast.error(errorMessage, {position: "top-center", autoClose: 4000})
            })
        } else {
            toast.info("Please select an MFA device")
        }
    }

    return (
        <Dialog open={true} fullScreen={isFullScreenDialog}>
            <DialogTitle>
                {isOtpStep && <Box>Please verify the security code</Box>}
                {isDeviceSelectionStep && <Box>Select Device to Send Security Code to</Box>}
            </DialogTitle>
            <DialogContentWrapper>
                {isDeviceSelectionStep &&
                <Slide in={isDeviceSelectionStep} direction={"left"}>
                    <Box>
                        <Grid container spacing={1}>
                            {!mfaDevices &&
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                                <Alert severity={"warning"}>No MFA device found</Alert>
                            </Box>
                            }
                            {mfaDevices?.filter(device => device?.status?.toUpperCase() === "ACTIVE")
                                .map(device => {
                                    const {id, type, email, phone} = device;
                                    const isSelectedCurrentId = id === selectedDeviceId;
                                    const classNames = clsx("deviceRoot", {"selectedDevice": isSelectedCurrentId});
                                    return (
                                        <Grid item key={id} xs={12}
                                              onClick={() => isSelectedCurrentId ? setSelectedDeviceId(null) : setSelectedDeviceId(id)}>
                                            <Box className={classNames}>
                                                <Box marginRight={2}>
                                                    {type?.toUpperCase() === "SMS" && <PhoneIcon/>}
                                                    {type?.toUpperCase() === "EMAIL" && <EmailIcon/>}
                                                </Box>
                                                <Box>
                                                    <Typography variant={"subtitle1"}>
                                                        Send security code as <u>{type}</u> at <strong>{phone || email}</strong>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Slide>
                }

                {isOtpStep &&
                <Slide in={isOtpStep} direction={"left"}>
                    <div>
                        <OtpVerification handleResendOtp={sendOtp} isResendingOtp={isSendingOtp}/>
                    </div>
                </Slide>
                }

            </DialogContentWrapper>
            <DialogActions>
                {isDeviceSelectionStep &&
                <Box display={"flex"}>
                    <Box marginRight={1}>
                        <Button onClick={() => history.replace(URN_AUTH + URN_SIGN_OFF)}>Cancel</Button>
                    </Box>
                    <Box>
                        <Button color={"primary"} variant={"contained"} disabled={!selectedDeviceId || isSendingOtp}
                                onClick={() => sendOtp()}
                                endIcon={isSendingOtp ? <CircularProgress color={"inherit"} size={20}/> : undefined}>
                            Send Security Code
                        </Button>
                    </Box>
                </Box>
                }
            </DialogActions>
            <Divider/>
            <Box display={"flex"} justifyContent={"space-around"} paddingY={1}>
                <div>
                    <Button size={"small"} variant={"outlined"} color={"primary"} style={{textTransform: "none"}}
                            onClick={handleLoginWithoutCredentials}>
                        Retry Login
                    </Button>
                </div>
            </Box>

            <Backdrop open={isLoginInWithDiffAccount}>
                <CircularProgress color={"secondary"}/>
            </Backdrop>
        </Dialog>
    );
}

MfaSteps.propTypes = {
    mfaDevices: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        status: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string
    }))
};

export default MfaSteps;