import React, {useState} from 'react';
import {Button, Divider, Typography} from "@mui/material";
import styled from "styled-components";
import EditIcon from '@mui/icons-material/Edit';
import AddressChange from "./AddressChange";

const OrderDetailsWrapper = styled.div`
  //   overflow: auto;
  margin: 0.5rem;
  padding: 0.5rem;
  position: relative;
  background-color: white;
  overflow: hidden;

  ._close {
    position: absolute;
    top: .5rem;
    right: .5rem;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  ._title {
    margin: .5rem 0 1rem 0;
  }

  ._content {
    width: 100%;
    padding: 0.5rem;
    // max-height: 80vh;
    overflow: hidden;

    ._player {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 1rem;

      ._image {
        border-radius: 10px;
      }
    }

    ._description {
      height: 300px;

      h6 {
        margin: 0;
      }

      ._section {
        margin: 1rem 0;
      }

      p {
        margin: 0;
      }

      > * {
        margin-bottom: 1rem;
      }
    }
  }

  :focus {
    outline: none;
  }
`;


const OrdersWrapper = styled.div`
  width: 100%;

  .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color: blue; //Todo
  }
`;

function OrderDetails() {
    const [isEdit, setEdit] = useState(false);
    const address = {
        street: "231 Greenland Ave",
        city: "San Francisco",
        state: "California",
        zip: "12203",
    }

    return (

        <OrderDetailsWrapper>
            <div className="_content">
                <div className="_description">
                    <OrdersWrapper>
                        <div className="_section">
                            <div><strong className={"_greyed"} style={{textDecoration: "underline", fontSize: "1.1rem"}}>Gamunex-C</strong></div>
                            <div><strong className={"_greyed"}>Expiry: </strong>23 Dec 2023</div>
                            <div><strong className={"_greyed"}>Quantity: </strong>3</div>
                        </div>
                        <Divider/>
                        <div className="_section">
                            <Typography variant={"h6"}>Shipping Address:</Typography>
                            <div>Ryan Florance,</div>
                            <div>{address.street}</div>
                            <div>{address.city}</div>
                            <div>{address.state}</div>
                            <div>{address.zip}</div>
                            <div>
                                <Button
                                    color={"primary"} startIcon={<EditIcon/>} className={"_textCapitalize"}
                                        size={"small"} onClick={()=> setEdit(true)}>
                                    Change Address
                                </Button>
                            </div>
                        </div>
                    </OrdersWrapper>
                </div>
            </div>
            {isEdit &&
            <AddressChange
                isOpen={isEdit} onSubmit={() => setEdit(false)} data={address} onCancel={() => setEdit(false)}/>
            }

        </OrderDetailsWrapper>
    );
}

export default OrderDetails;