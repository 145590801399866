import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PRESCRIPTIONS } from '../actions/ActionTypes';
import { fetchAllPrescriptions } from '../../api/prescriptionsSupplies';
import { pageErrorExtractor } from '../../utils/httpErrorHelpers';
import { add, isAfter } from 'date-fns';
import { setCacheExpiryTime, setData, setError, setLoading } from '../actions/prescriptionsActions';
import { AUTHENTICATED_USER_TYPE } from '../../constants/portalUserType';

function* handleFetchAllPrescriptions() {
    const { currentPatientUsername, role } = yield select(state => state?.auth);
    const { error, cacheExpiryTime } = yield select((state) => state?.prescriptions);
    const isCacheExpired = isAfter(new Date(), new Date(cacheExpiryTime));

    if (role === AUTHENTICATED_USER_TYPE.ADMIN || error || cacheExpiryTime === null || isCacheExpired) {
        try {
            yield put(setError(null));
            yield put(setLoading(true));
            const prescriptionsResponse = yield call(fetchAllPrescriptions, currentPatientUsername);//get All prescriptions
            const prescriptionData = prescriptionsResponse.data;
            yield put(setData(prescriptionData));
            yield put(setLoading(false));
            //Set cache expiry time. Cache is valid for 2 hours
            yield put(setCacheExpiryTime(add(new Date(), { hours: 2 }).toString()));
        } catch (error) {
            yield put(setError(pageErrorExtractor(error)));
            yield put(setLoading(false));
        }
    }

}

function* prescriptionsSaga() {
    yield takeLatest(PRESCRIPTIONS.FETCH_ALL_PRESCRIPTIONS, handleFetchAllPrescriptions);
}

export default prescriptionsSaga;