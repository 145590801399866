import React from 'react';
import styled from "styled-components";
import {Typography} from "@mui/material";

const FooterWrapper = styled.div`
  min-height: 40px;
  background: #323232;
  color: #f7f7f7;
  font-size: .8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  @media (max-width: 680px) {
    padding: 1rem;
    min-height: auto;
  }
`;


export default function Footer() {
    return (
        <FooterWrapper>
            <Typography fontSize={"small"}>
                Powered by Nufactor Digital. For inquiries, please call Nufactor Technical Support at 1.800.323.6832
                or Fax to 1.855.553.6922
            </Typography>
        </FooterWrapper>
    );

}