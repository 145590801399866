import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    Stack,
    styled,
    Typography,
    useMediaQuery,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/PhoneIphone';
import Input from '../../components/Input';
import axios from 'axios';
import { API_URN_MFA_DEVICES, API_URN_PATIENT } from '../../../constants/apiURNs';
import { errorExtractor } from '../../../utils/httpErrorHelpers';
import { toast } from 'react-toastify';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/styles';
import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { formatPhoneNumber } from '../../../utils/numberFormator';

const DeviceSelectionButton = styled(IconButton)`
  border-radius: 0;
  height: 150px;
  width: 150px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  margin: ${({ theme }) => theme.spacing(1)};

  .MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 100px;
    }
  }
`;

const MFA_DEVICE = Object.freeze({
    EMAIL: 'EMAIL',
    SMS: 'SMS',
});

function NewMfaDevice({ isOpen, onClose, onSuccess, existingMfaDevices }) {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

    //form data
    const [mfaDeviceType, setMfaDeviceType] = useState(null);
    const [mfaDeviceValue, setMfaDeviceValue] = useState(null);
    const [markAsPrimary, setMarkAsPrimary] = useState(null);

    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const existingEmailDevicesLength = existingMfaDevices?.filter(device => device?.type?.toUpperCase() === MFA_DEVICE.EMAIL)?.length;
    const existingPhoneDevicesLength = existingMfaDevices?.filter(device => device?.type?.toUpperCase() === MFA_DEVICE.SMS)?.length;

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            deviceType: mfaDeviceType,
            deviceValue: mfaDeviceType === MFA_DEVICE.SMS ? '+1' + mfaDeviceValue : mfaDeviceValue,
            markAsPrimary,
        };
        if (mfaDeviceValue && mfaDeviceType) {
            setSubmitting(true);
            const baseUrl = process.env.REACT_APP_API_IDP_ROOT_URL + API_URN_PATIENT + API_URN_MFA_DEVICES;
            axios.post(baseUrl, data)
                .then(() => {
                    setSubmitting(false);
                    toast.success('Device added successfully');
                    onSuccess();
                })
                .catch(error => {
                    setError(errorExtractor(error));
                    setSubmitting(false);
                });
        }
    };

    const handleInputChange = (event) => {
        if (mfaDeviceType === MFA_DEVICE.SMS)
            setMfaDeviceValue(formatPhoneNumber(event.target.value));
        else
            setMfaDeviceValue(event.target.value);
    };

    const handleMarkAsPrimary = (event) => {
        setMarkAsPrimary(event?.target?.checked);
    };

    const handleBack = () => {
        setError(null);
        setMfaDeviceValue(null);
        setMfaDeviceType(null);
    };

    return (
        <Dialog open={isOpen} fullScreen={isSmallScreen} maxWidth={'xs'}>
            <DialogTitle>{mfaDeviceType ? 'Provide Details' : 'Select an MFA Device'}</DialogTitle>
            <DialogContent sx={{ minHeight: 250 }}>
                {mfaDeviceType
                    ? <Slide in={Boolean(mfaDeviceType)} direction={'left'}>
                        <Box component={'form'} marginY={2}>
                            {error &&
                            <Box marginY={2}>
                                <Alert severity={'error'} onClose={() => setError(null)}>{error}</Alert>
                            </Box>
                            }
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {mfaDeviceType === MFA_DEVICE.SMS &&
                                    <Input
                                        name={'mfaDeviceValue'} label={'Enter Phone Number'} required
                                        onChange={handleInputChange} value={mfaDeviceValue ?? ''}
                                        InputProps={{
                                            startAdornment: <InputAdornment position='start'>+1</InputAdornment>,
                                        }}
                                    />
                                    }
                                    {mfaDeviceType === MFA_DEVICE.EMAIL &&
                                    <Input
                                        name={'mfaDeviceValue'} label={'Enter Email Address'} required
                                        onChange={handleInputChange} value={mfaDeviceValue ?? ''}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxWithLabel
                                        name={'markAsPrimary'}
                                        checked={markAsPrimary || (existingEmailDevicesLength === 0 && mfaDeviceType === MFA_DEVICE.EMAIL) || (existingPhoneDevicesLength === 0 && mfaDeviceType === MFA_DEVICE.SMS)}
                                        label={`Mark as primary ${mfaDeviceType === MFA_DEVICE.SMS ? 'Phone' : 'Email'}?`}
                                        onChange={handleMarkAsPrimary}
                                    />
                                    <Box display={'flex'} alignItems={'flex-start'} marginLeft={2}>
                                        <Box component={'i'} marginRight={1}>
                                            <InfoIcon color={'info'} fontSize={'small'} />
                                        </Box>
                                        <Typography color={'primary'} variant={'body2'}>
                                            Primary {mfaDeviceType === MFA_DEVICE.SMS ? 'phone' : 'email'} is used for
                                            sending password reset token and other communications from Nufactor.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} marginTop={4}>
                                    <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
                                        <Button onClick={handleBack} disabled={isSubmitting}>
                                            Back
                                        </Button>
                                        <Button variant={'contained'} type={'submit'} onClick={handleSubmit}
                                                disabled={isSubmitting || (!mfaDeviceValue)}
                                                endIcon={isSubmitting ? <CircularProgress size={20} color={'inherit'} />
                                                    : undefined}
                                        >
                                            Save Device
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Slide>
                    : <Box>
                        <Box marginY={2} display={'flex'} flexDirection={isSmallScreen ? 'column' : 'row'}
                             justifyContent={'center'} alignItems={'center'}>
                            <DeviceSelectionButton onClick={() => setMfaDeviceType(MFA_DEVICE.EMAIL)}>
                                <Box display={'flex'} flexDirection={'column'}>
                                    <EmailIcon color={'primary'} />
                                    <Typography>EMAIL</Typography>
                                </Box>
                            </DeviceSelectionButton>
                            <DeviceSelectionButton onClick={() => setMfaDeviceType(MFA_DEVICE.SMS)}>
                                <Box display={'flex'} flexDirection={'column'}>
                                    <PhoneIcon color={'primary'} />
                                    <Typography>SMS</Typography>
                                </Box>
                            </DeviceSelectionButton>
                        </Box>
                        <Box textAlign={'right'}>
                            <Button onClick={onClose}>Close</Button>
                        </Box>
                    </Box>
                }
            </DialogContent>
        </Dialog>
    );
}

NewMfaDevice.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

export default NewMfaDevice;