import {styled} from "@mui/material";

export const CircularProgressWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  min-height: ${({height}) => height ? `${height}px` : "80vh"};
  width: 100%;
  justify-content: center;
  align-items: center;
  color: var(--color-site-blue);
`;