import axios                                                                from "axios";
import {
    API_URN_ALLERGIES ,
    API_URN_CONDITIONS ,
    API_URN_HEALTH_SUMMARY ,
    API_URN_MEDICATIONS
} from "../constants/apiURNs";

export function fetchHealthSummary_() {
    return axios.get(API_URN_HEALTH_SUMMARY)
}

export function fetchHealthSummary(username) {
    return axios.get(API_URN_HEALTH_SUMMARY + "/" + username)
}export function fetchAllergies(username) {
    return axios.get(API_URN_ALLERGIES + "/" + username)
}export function fetchMedications(username) {
    return axios.get(API_URN_MEDICATIONS + "/" + username)
}export function fetchConditions(username) {
    return axios.get(API_URN_CONDITIONS + "/" + username)
}