import React from 'react';
import PropTypes from 'prop-types';
import { TextField, useTheme } from '@mui/material';
import { formFieldStyleHelper } from './utils/formFieldStyleHelper';

function Input(props) {
    const theme = useTheme();

    const {
        fullWidth, placeholder, id, label, name,
        onChange, required, value, type, InputProps,
        autoFocus, error, onBlur, helperText,
        disabled, readOnly, size, autoComplete, className
    } = props;

    return (
        <TextField
            name={name}
            label={label}
            id={id}
            type={type}
            required={required}
            value={value ?? ""}
            onChange={onChange}
            variant={"filled"}
            size={size}
            fullWidth={fullWidth}
            color={"secondary"}
            inputProps={{
                autoComplete: autoComplete,
            }}
            InputProps={{...InputProps, disableUnderline: true}}
            placeholder={placeholder}
            autoFocus={autoFocus}
            error={error}
            helperText={helperText}
            onBlur={onBlur}
            disabled={disabled}
            className={readOnly ? "_readOnly" : className}
            aria-readonly={readOnly}
            sx={formFieldStyleHelper(error, theme)}
        />
    );
}

Input.defaultProps = {
    required: false,
    value: "",
    fullWidth: true,
    type: "text",
    size: "small"
}
Input.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    InputProps: PropTypes.object,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    error: PropTypes.bool,
    onBlur: PropTypes.func,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium"]),
    className: PropTypes.string
};

export default Input;