import React from 'react';
import {Typography} from "@mui/material";
import styled from "styled-components";
import OrderImg from "../../../assets/order-confirm.png";

const OrderDetailsWrapper = styled.div`
  //   overflow: auto;
  margin: 0.5rem;
  padding: 0.5rem;
  position: relative;
  background-color: white;
  overflow: hidden;

  ._close {
    position: absolute;
    top: .5rem;
    right: .5rem;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  ._title {
    margin: .5rem 0 1rem 0;
  }

  ._content {
    width: 100%;
    padding: 0.5rem;
    // max-height: 80vh;
    overflow: hidden;

    ._player {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 1rem;

      ._image {
        border-radius: 10px;
      }
    }

    ._description {
      height: 300px;

      h6 {
        margin: 0;
      }

      ._section {
        margin-top: 10px;
        text-align: center;
      }

      ._link {
        color: #199BD2;
      }

      p {
        margin: 0;
      }

      > * {
        margin-bottom: 1rem;
      }
    }
  }

  :focus {
    outline: none;
  }
`;


const OrdersWrapper = styled.div`
  width: 100%;

  .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color: blue; //Todo
  }
`;

function ConfirmOrder() {
    return (

        <OrderDetailsWrapper>
            <div className="_content">
                <div className="_description">
                    <OrdersWrapper>
                        <div className="_section">
                            <img src={OrderImg} alt=""/>
                            <Typography variant={"h6"}>Order Confirmed</Typography>
                        </div>
                    </OrdersWrapper>
                </div>
            </div>
        </OrderDetailsWrapper>

    );
}

export default ConfirmOrder;