import React from 'react';
import {Redirect} from "react-router-dom";
import {
    URN_ADMIN_HOME,
    URN_HEALTH_SUMMARY,
    URN_SUPER_ADMIN_HOME,
    URN_UN_AUTHORIZED
} from "../../../constants/clientURNs";
import {AUTHENTICATED_USER_TYPE} from "../../../constants/portalUserType";
import {useSelector} from "react-redux";

function Home() {
    const {role} = useSelector(state => state?.auth);

    if (role !== "UNSET") {
        switch (role) {
            case AUTHENTICATED_USER_TYPE.PATIENT:
                return <Redirect to={URN_HEALTH_SUMMARY}/>
            case AUTHENTICATED_USER_TYPE.ADMIN:
                return <Redirect to={URN_ADMIN_HOME}/>
            case AUTHENTICATED_USER_TYPE.SUPER_ADMIN:
                return <Redirect to={URN_SUPER_ADMIN_HOME}/>
            default:
                return <Redirect to={URN_UN_AUTHORIZED}/>
        }
    } else {
        return <div/>
    }
}

export default Home;